import { Component, OnInit } from '@angular/core';
import { AppConfigService } from '../services/app-config.service';

@Component({
  selector: 'app-change-env',
  templateUrl: './change-env.component.html',
  styleUrls: ['./change-env.component.scss']
})
export class ChangeEnvComponent implements OnInit {
  envs: any[];

  constructor(
    private appConfigService: AppConfigService
  ) {
  }

  ngOnInit() {
    this.envs = this.appConfigService.envs.filter(x => x.name != this.appConfigService.name);
  }
}
