import { Injectable } from '@angular/core';
import { UserType } from '../models/userType';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SelectedUserTypeService {
  userTypeSub = new BehaviorSubject<UserType>(undefined);
  userTypeCurrent = this.userTypeSub.asObservable()

  constructor() { }

  setRole(role: UserType) {
    this.userTypeSub.next(role);
  }

  unsetMachine() {
    this.userTypeSub.next(undefined);
  }
}