import { Pipe, PipeTransform } from '@angular/core';
import { MACHINESTATEVOICE } from '../models/mock-machineState';

@Pipe({
  name: 'machineState'
})
export class MachineStatePipe implements PipeTransform {

  transform(value: number): any {

    var elem = MACHINESTATEVOICE.find((element) => {
      return element.id == value;
    });
    return elem.id + " - " + elem.label;
  }
}