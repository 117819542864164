import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Model } from '../models/model';

@Injectable({
  providedIn: 'root'
})
export class SelectedModelService {
  modelSub = new BehaviorSubject<Model>(undefined);
  modelCurrent = this.modelSub.asObservable()

  constructor() { }

  setModel(model: Model) {
    this.modelSub.next(model);
  }

  unsetModel() {
    this.modelSub.next(undefined);
  }
}