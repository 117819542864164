import { Component } from '@angular/core';
import { GenericListComponent } from '../generic-list/generic-list.component';
import { Param } from '../models/param';
import { ParameterCrudService } from '../services/parameter-crud.service';
import { AlertService } from '../services/alert.service';

@Component({
  selector: 'app-parameter-list',
  templateUrl: './parameter-list.component.html',
  styleUrls: ['./parameter-list.component.scss']
})
export class ParameterListComponent extends GenericListComponent<Param, number>{
  prop: string;
  displayedColumns: string[] = ['id_param', 'name', 'type', 'address', 'idnode', 'update', 'sdo_segmented', 'applymask', 'min', 'max','all_models'];

  constructor(
    protected crudService: ParameterCrudService,
    protected alertService: AlertService
  ) {
    super(alertService, crudService)
  }

  ngOnInit() {
    super.ngOnInit();
    this.prop = 'id_param';
  }
}
