import { Injectable } from '@angular/core';
import { GenericCrudService } from '../services/generic-crud.service'
import { Machine } from '../models/machine';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MachineCrudService extends GenericCrudService<Machine, number>{

  constructor(http: HttpClient,
    appConfigService: AppConfigService) {
    super(http, appConfigService, "machines");
    this.load();
  }

  getSimStatus(serialNumber: string): Observable<Response> {
    console.log(this.apiName);
    return this.http.get<Response>(`${this.endpoint}/${this.apiName}/${serialNumber}/simStatus`);
  }
}