import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hourCounter'
})
export class HourCounterPipe implements PipeTransform {

  transform(value: number): any {
    var a = Math.floor(value / 3600); //hours
    var x = value % 3600;
    var b = Math.floor(x / 60); //minutes
    var c = value % 60; //seconds
    return a + ":" + b.toString().padStart(2, '0') + ":" + c.toString().padStart(2, '0');
  }
}