import { Component, OnInit } from '@angular/core';
import { Activity } from '../models/activity';
import { GenericListComponent } from '../generic-list/generic-list.component';
import { AlertService } from '../services/alert.service';
import { ActivityParamDialogComponent } from '../activity-param-list-dialog/activity-param-list-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-activity-list',
  templateUrl: './activity-list.component.html'
})
export class ActivityListComponent extends GenericListComponent<Activity, number> {
  prop: "id_activity";
  displayedColumns: string[] = ['id_activity', 'id_job', 'lat', 'lng', 'alt', 'date_start', 'date_end', 'start_params', 'stop_params'];

  constructor(
    protected alertService: AlertService,
    public dialog: MatDialog

  ) {
    super(alertService, null);
    this.prop = 'id_activity';
  }

  more(id: string) {
    const dialogRef = this.dialog.open(
      ActivityParamDialogComponent, {
      width: '800px',
      data: {
        id: id,
      }
    });

    dialogRef.afterClosed().subscribe(result => {});

  }
}

