import { Component, HostListener } from '@angular/core';
import { SelectedMachineService } from '../services/selected-machine.service';
import { Machine } from '../models/machine';
import { Location } from '@angular/common';
import { ModelCrudService } from '../services/model-crud.service';
import { UserCrudService } from '../services/user-crud.service';
import { User } from '../models/user';
import { MachineTestingComponent } from '../machine-testing/machine-testing.component';
import * as signalR from '@aspnet/signalr';
import { ConnectionSignalRService } from '../services/connection-signal-r.service';
import { signalRSubscription, signalRInvocation } from '../models/signalRTopic';
import { AlertService } from '../services/alert.service';
import { MachineCrudService } from '../services/machine-crud.service';
import { interval, Subscription } from 'rxjs';
@Component({
  selector: 'app-machine-test',
  templateUrl: './machine-test.component.html'
})

export class MachineTestComponent extends MachineTestingComponent {
  @HostListener('window:beforeunload', ['$event.target'])
  beforeUnload(event) {
    if (this.connection != null) {
      this.connection.invoke(signalRInvocation.stopAll).then(() => this.connection.stop());
    }
  }
  @HostListener('window:keydown.escape', ['$event'])
  onEscape(e) {
    e.preventDefault();
    this.showDebug = !this.showDebug;
  }

  pingSub: Subscription;
  showDebug = false;
  listUsers: User[];
  listRelatedUsers: User[];
  connection: signalR.HubConnection;

  constructor(
    public selectedMachine: SelectedMachineService,
    public connectionSignalR: ConnectionSignalRService,
    protected location: Location,
    public machineService: MachineCrudService,
    public modelService: ModelCrudService,
    protected userService: UserCrudService,
    public alertService: AlertService
  ) {
    super(selectedMachine, modelService, alertService, connectionSignalR)
  }

  ngOnInit() {
    super.ngOnInit();
    this.userService.listObservable.subscribe(
      (data: any[]) => {
        this.listUsers = data;
      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )
    this.initializeConnection();
  }

  initializeConnection() {
    this.connection = new signalR.HubConnectionBuilder().configureLogging(signalR.LogLevel.Debug)
      .withUrl("/diastecaHub",
        {
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets
        })
      .build();

    this.connection.start().then(() => {
      this.connection.invoke(signalRInvocation.checkConnection, this.selectedMachine.machineSub.value.serial_number).then(() => console.log('test'));
      this.connectionSignalR.setConnection(this.connection);
      this.pingSub = interval(10000).subscribe(x => {
        console.log(this.connection.state);
        if (this.connection.state == signalR.HubConnectionState.Connected) {
          this.connection.invoke(signalRInvocation.ping);
        }
        else {
          this.initializeConnection();
          console.log('reinitilize connection');
        }
      });
    })
      .catch(function (err) {
        return console.error(err.toString());
      });

    this.connection.on(signalRSubscription.connectionStatus, (status: string) => {
      //this.selectedMachine.machineSub.value.isonline = status;
      this.machine.isonline = Number(status);

    });

    this.connection.on(signalRSubscription.sendConnectionStatus, (status: string) => {
      //  this.selectedMachine.machineSub.value.isonline = status;
      this.machine.isonline = Number(status);
    });

    this.connection.on(signalRSubscription.sendAggFirmwareIot4G, (status: string) => {
      //  this.selectedMachine.machineSub.value.isonline = status;
      this.alertService.info(status, null);
    });
  }

  ngOnDestroy() {
    if (this.connection != null) {
      this.connection.invoke(signalRInvocation.stopAll).then(() => this.connection.stop());
      this.pingSub.unsubscribe();
    }
  }

  back() {
    this.enabledMessage = false;
    this.machineService.load();
    this.location.back();
  }

  getColore(isonline: number) {
    return Machine.getColor(isonline);
  }

  getStatusIcon(isonline: number) {
    return Machine.statusIcon(isonline);
  }
}