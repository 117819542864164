import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GenericSettingsDialogComponent } from '../generic-settings-dialog/generic-settings-dialog.component';
import { Model } from '../models/model';
import { ModelLevel } from '../models/modelLevel';
import { UserType } from '../models/userType';
import { AlertService } from '../services/alert.service';
import { ModelCrudService } from '../services/model-crud.service';
import { ModelLevelCrudService } from '../services/model-level-crud.service';
import { UserTypeCrudService } from '../services/user-type-crud.service';

@Component({
  selector: 'app-model-level-settings-dialog',
  templateUrl: './model-level-settings-dialog.component.html',
  styleUrls: ['./model-level-settings-dialog.component.scss']
})
export class ModelLevelSettingsDialogComponent extends GenericSettingsDialogComponent<ModelLevel, number> {
  listModel: Model[];
  listUserType: UserType[];
  idModel: number = null;
  idUserType: number = null;

  constructor(
    protected modelLevelService: ModelLevelCrudService,
    protected userTypeService: UserTypeCrudService,
    protected modelService: ModelCrudService,
    protected alertService: AlertService,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected fb: FormBuilder,
    private ref: ChangeDetectorRef
  ) {
    super(modelLevelService, alertService, dialogRef, data, fb)
  }

  ngOnInit() {
    super.ngOnInit();
    this.title = this.isNew ? "Create new Model By Level" : "Modify Model By Level";
    this.idModel = this.data.idModel;
    this.idUserType = this.data.idUserType;

    this.form = this.fb.group({
      mod_modelCtrl: [this.entity ? this.entity.id_model : this.idModel ? this.idModel : '', Validators.required],
      mod_userTypeCtrl: [this.entity ? this.entity.id_usertype : this.idUserType ? this.idUserType : '', Validators.required],
      mod_xmlCtrl: ['', Validators.required]
    });

    this.userTypeService.listObservable.subscribe(
      (data: any[]) => {
        this.listUserType = data;
      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )

    this.modelService.listObservable.subscribe(
      (data: any[]) => {
        this.listModel = data;

      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )
  }

  update(entity: ModelLevel) {
    entity.id_model = this.form.get('mod_modelCtrl').value;
    entity.id_usertype = this.form.get('mod_userTypeCtrl').value;
    entity.id = entity.id_modelbylevel;
    entity.xml = this.form.get('mod_xmlCtrl').value;

    this.modelLevelService.update(this.entity)
      .subscribe(
        (data) => {
          this.alertService.info("Model by Level has been successfully updated");
          this.dialogRef.close(true);
        },
        error => {
          console.log(error)
          this.alertService.error(error.error);
        }
      );
  }

  add(): void {
    var modelByLevel: ModelLevel = {
      id: undefined,
      id_modelbylevel: undefined,
      id_model: this.form.get('mod_modelCtrl').value,
      id_usertype: this.form.get('mod_userTypeCtrl').value,
      xml: this.form.get('mod_xmlCtrl').value,
    };
    this.modelLevelService.create(modelByLevel)
      .subscribe(
        (data) => {
          this.alertService.info("Model by Level has been successfully added");
          this.dialogRef.close(true);
        },
        error => {
          console.log(error)
          this.alertService.error(error.error);
        }
      );
  }

  delete(): void {
    this.modelLevelService.delete(this.entity.id_modelbylevel)
      .subscribe(x => {
        this.alertService.info('Model by Level has been successfully deleted!');
        this.dialogRef.close(true);
      },
        error => {
          console.log(error)
          this.alertService.error(error.error);
        });
  }

  onFileChange(event) {
    let reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsText(file);

      reader.onload = () => {
        this.form.patchValue({
          mod_xmlCtrl: reader.result
        });

        this.ref.markForCheck();
      };
    }
  }
}

