import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Label } from '../models/label';
import { FormBuilder, Validators, AbstractControl, FormGroup } from '@angular/forms';
import { AlertService } from '../services/alert.service';

import { LabelCrudService } from '../services/label-crud.service';
import { GenericSettingsDialogComponent } from '../generic-settings-dialog/generic-settings-dialog.component';

@Component({
  selector: 'app-label-settings-dialog',
  templateUrl: './label-settings-dialog.component.html',
  styleUrls: ['./label-settings-dialog.component.scss']
})

export class LabelSettingsDialogComponent extends GenericSettingsDialogComponent<Label, string> {

  constructor(
    protected labelService: LabelCrudService,
    protected alertService: AlertService,
    public dialogRef: MatDialogRef<LabelSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected fb: FormBuilder,
  ) {
    super(labelService, alertService, dialogRef, data, fb);
  }

  ngOnInit() {
    super.ngOnInit();
    this.title = this.isNew ? "Create new label" : "Modify label";
    this.form = this.fb.group({
      label_keyCtrl: [this.isNew ? '' : this.data.element.label_key, Validators.compose([Validators.required, (c) => this.newLabelValidator(c, this.entityList, this.entity)])],
      label_enCtrl: this.isNew ? '' : this.data.element.label_en,
      label_itCtrl: this.isNew ? '' : this.data.element.label_it
    });
  }

  update(label: Label) {
    label.label_key = this.form.get('label_keyCtrl').value;
    label.label_en = this.form.get('label_enCtrl').value;
    label.label_it = this.form.get('label_itCtrl').value;
    label.date_upd = null;
    label.id = this.form.get('label_keyCtrl').value;

    this.labelService.update(label)
      .subscribe(
        (data) => {
          this.alertService.info("Label has been successfully updated");
          this.dialogRef.close(true);
        },
        error => {
          console.log(error)
          this.alertService.error(error.error);
        }
      );
  }

  add() {
    var label: Label = {
      label_key: this.form.get('label_keyCtrl').value,
      label_en: this.form.get('label_enCtrl').value,
      label_it: this.form.get('label_itCtrl').value,
      date_upd: null,
      id: this.form.get('label_keyCtrl').value
    };

    this.labelService.create(label)
      .subscribe(
        (data) => {
          this.alertService.info("Label has been successfully added");
          this.dialogRef.close(true);
        },
        error => {
          console.log(error)
          this.alertService.error(error.error);
        }
      );
  }

  delete() {
    this.labelService.delete(this.entity.label_key)
      .subscribe(x => {
        this.alertService.info('Label has been successfully deleted!');
        this.dialogRef.close(true);
      },
        error => {
          console.log(error)
          this.alertService.error(error.error);
        });
  }

  newLabelValidator(c: AbstractControl, listLabel: Label[], label: Label): { [s: string]: boolean } {
    var currentIdx = -2;
    if (listLabel.length > 0) {
      var index = listLabel.map(function (d) { return d.label_key.toUpperCase(); }).indexOf(c.value.toUpperCase());
      if (label) {
        currentIdx = listLabel.map(function (d) { return d.label_key.toUpperCase(); }).indexOf(label.label_key.toUpperCase());
      }
      if (index >= 0 && index != currentIdx) {
        return { labelJustExists: index >= 0 };
      }
    }
  }
}
