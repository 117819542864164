import { Component, OnInit } from '@angular/core';
import { SelectedCommandService } from '../services/selected-command.service';
import { Location } from '@angular/common';
import { Command } from '../models/command';
import { first } from 'rxjs/operators';
import { COMMANDTYPELIST } from '../models/mock-string-list';
import { IEntityListService } from '../services/ientity-list.service';
import { CommandModelService } from '../services/command-model.service';
import { Model } from '../models/model';

@Component({
  selector: 'app-command-more',
  templateUrl: './command-more.component.html',
  providers: [
    { provide: IEntityListService, useClass: CommandModelService }
  ]
})
export class CommandMoreComponent implements OnInit {
  commandTypeList = COMMANDTYPELIST;
  public command: Command;

  constructor(
    protected selectedCommand: SelectedCommandService,
    protected location: Location,
    public commandModelService: IEntityListService<Model>
  ) { }

  ngOnInit() {
    this.selectedCommand.commandCurrent.pipe(first()).subscribe(
      command => {
        this.command = command;
      }
    );
  }

  back() {
    this.location.back();
  }
}
