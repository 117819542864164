import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';
import { MENU } from '../models/mock-menu'
import { MenuVoice } from '../models/menu-voice';
import { AppConfigService } from '../services/app-config.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  menu = MENU;
  selected: MenuVoice;
  envs: any[];

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private appConfigService: AppConfigService
  ) {
  }

  ngOnInit() {
    this.authenticationService.currentUser.
      subscribe((user) => {
        if (user != null) {
          //this.selected = MenuVoice[0];
        }
      });
    this.envs = this.appConfigService.envs.filter(x => x.name != this.appConfigService.name);
  }

  logout() {
    this.authenticationService.logout();

    this.router.navigate(['/login']);
  }

  onSelect(voice: MenuVoice) {
    this.selected = voice;
    this.router.navigate([voice.link]);
    console.log(this.router.url);
  }
}