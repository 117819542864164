import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import * as signalR from '@aspnet/signalr';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MachineTestingComponent } from '../machine-testing/machine-testing.component';
import { Param } from '../models/param';
import { RespTableNode } from '../models/respGetData';
import { signalRInvocation, signalRSubscription } from '../models/signalRTopic';
import { AlertService } from '../services/alert.service';
import { ConnectionSignalRService } from '../services/connection-signal-r.service';
import { ModelCrudService } from '../services/model-crud.service';
import { ModelParamService } from '../services/model-param.service';
import { SelectedMachineService } from '../services/selected-machine.service';

@Component({
  selector: 'app-machine-idnode',
  templateUrl: './machine-idnode.component.html',
  styleUrls: ['./machine-idnode.component.scss']
})
export class MachineIdnodeComponent extends MachineTestingComponent {
  @ViewChild(MatExpansionPanel, { static: true }) paramPanel: MatExpansionPanel;

  first = true;
  listNode: any[] = [];
  filteredList: any[] = [];
  listNodeToSend: any[] = [];
  listResp: RespTableNode[] = [];

  constructor(
    public selectedMachine: SelectedMachineService,
    public modelService: ModelCrudService,
    public modelParamService: ModelParamService,
    public connectionSignalR: ConnectionSignalRService,
    public alertService: AlertService,
    private cd: ChangeDetectorRef

  ) {
    super(selectedMachine, modelService, alertService, connectionSignalR);
  }

  ngOnInit() {
    super.ngOnInit();
    environment.IDNODELIST.forEach(x => {
      this.filteredList.push(x);
      this.listNode.push(x);
    });
    this.modelParamService.getEntityList(this.selectedMachine.machineSub.value.id_model).
      pipe(switchMap((x: Param[]) => from(x))).
      subscribe(
        (data: Param) => {
          if (this.listNodeToSend.findIndex(x => x.value == data.idnode) < 0) {
            var elem = environment.IDNODELIST.find(x => x.value == data.idnode);
            this.dblclickMove(elem, 'listNodeToSend', 'filteredList')
          }
        },
        error => console.log('error'),
        () => {

        }
      )
  }

  dblclickMove(item: any, ...targets: string[]) {
    this[targets[0]] = [
      ...this[targets[1]].splice(this[targets[1]].findIndex((x: any) => (x.value == item.value)), 1),
      ...this[targets[0]]
    ];
  }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  updateTable() {
    this.subscribe();
    if (this.connectionSignalR.connectionSub.value.state == signalR.HubConnectionState.Connected) {
      var dataToSend = [];
      this.listNodeToSend.forEach(element => {
        dataToSend.push(
          element.valComp
        )
      });

      this.connectionSignalR.connectionSub.value.invoke(signalRInvocation.setIdNodeTable, this.selectedMachine.machineSub.value.serial_number, dataToSend).then(
        () => {
          console.log("setIdNodeTable");

        }).catch((reason: any) => {
          if (reason != null) {
            console.log(reason.message);
            this.alertService.error(reason.message.split("Exception: ")[1]);
          }
        });
    }
  }

  getTable() {
    this.subscribe();

    if (this.connectionSignalR.connectionSub.value.state == signalR.HubConnectionState.Connected) {
      var dataToSend = "";

      this.connectionSignalR.connectionSub.value.invoke(signalRInvocation.getIdNodeTable, this.selectedMachine.machineSub.value.serial_number).then(
        () => {
          console.log("getIdNodeTable");
        }).catch((reason: any) => {
          if (reason != null) {
            console.log(reason.message);
            this.alertService.error(reason.message.split("Exception: ")[1]);
          }
        });
    }
  }

  subscribe() {
    if (this.first) {
      this.interceptDisconnection();
      this.first = false;
      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendPubGetIdNodeTable, (resp: string) => {
        //  this.selectedMachine.machineSub.value.isonline = status;
        var r = new RespTableNode();
        r.date = new Date();
        let node = JSON.parse(resp);
        r.node = [];
        node.forEach(element => {
          r.node.push(this.listNode.find(x => x.valComp === element));
        });

        this.listResp.push(r);
        this.paramPanel.close();
        //this.cd.markForCheck();
        //this.cd.detectChanges();
      });

      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendGenericError, (resp: string) => {
        //  this.selectedMachine.machineSub.value.isonline = status;
        this.alertService.error(resp);
      });

      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendPubSetIdNodeTable, (resp: string) => {
        //  this.selectedMachine.machineSub.value.isonline = status;
        var r = new RespTableNode();
        r.date = new Date();
        if (resp === "\0") {
          r.code = "0"
        } else {
          r.code = resp;
        }
        this.listResp.push(r);
        this.paramPanel.close();
        //this.cd.markForCheck();
        //this.cd.detectChanges();
      });
    }
  }

  clean() {
    this.listResp = [];
  }
}
