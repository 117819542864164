import { Pipe, PipeTransform } from '@angular/core';
import { CounterEdge } from '../models/digitalEventCounter';

@Pipe({
  name: 'counterEdgeIcon'
})
export class CounterEdgeIconPipe implements PipeTransform {

  transform(value: CounterEdge): any {
    switch (value) {
      case CounterEdge.Disabled:
        return "remove";
      case CounterEdge.Fall:
        return "south";
      case CounterEdge.Rise:
        return "north";
      case CounterEdge.RiseFall:
        return "import_export";
    }
  }
}