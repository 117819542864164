import { Pipe, PipeTransform } from '@angular/core';
import { CounterEdge } from '../models/digitalEventCounter';
import { Machine } from '../models/machine';

@Pipe({
  name: 'batteryLevel'
})
export class BatteryLevelPipe implements PipeTransform {

  transform(value: Machine): any {
    if(value.battery_warning_soil == null || value.battery_alarm_soil == null){
      return '#000000';
    }
    else if(value.battery_level < value.battery_alarm_soil){
      return '#d32f2f';
    }
    else if (value.battery_level < value.battery_warning_soil){
      return '#ffe100';
    }
    else {
      return '#4caf50';
    }
  }
}
