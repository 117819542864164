import { Injectable } from '@angular/core';
import { IEntityListService } from './ientity-list.service';
import { User } from '../models/user';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class MachineToUserService extends IEntityListService<User>{
  protected apiName: string = "usersToMachines";

  constructor(protected http: HttpClient,
    appConfigService: AppConfigService) {
    super(http, appConfigService);
  }

  getEntityList(idobj: number): Observable<User[]> {
    return this.http.get<User[]>(`${this.endpoint}/${this.apiName}/machineid=${idobj}/users`);
  }

  postEntityList(idobj: number, listRelatedEntity: User[]): Observable<any> {
    return this.http.post<any>(`${this.endpoint}/${this.apiName}/machineid=${idobj}`, listRelatedEntity.map(x=>x.id_user));
  }
}