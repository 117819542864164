import { Component, Inject } from '@angular/core';
import { ActivityParam } from '../models/activityParam';
import { ActivityParamCrudService } from '../services/activity-param-crud.service';
import { AlertService } from '../services/alert.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ParameterCrudService } from '../services/parameter-crud.service';
import { ActivitySingleRecCrudService } from '../services/activity-single-rec-crud.service';

@Component({
  selector: 'app-activity-param-list-dialog',
  templateUrl: './activity-param-list-dialog.component.html',
})
export class ActivityParamDialogComponent {

  dataSource: ActivityParam[];
  title: string;
  displayedColumns: string[] = ['id_act_param','param_name','byte_val','val','param_type'];
  mapIdParameter = new Map();
  date: Date;
  type: string;
  hours: Number;

  constructor(
    protected activityParamService: ActivityParamCrudService,
    protected paramService:ParameterCrudService,
    protected actSingleRecService:ActivitySingleRecCrudService,
    protected alertService: AlertService,
    public dialogRef: MatDialogRef<ActivityParamDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    this.activityParamService.getListByActSingleRecId(this.data.id).subscribe(
      (data) => {
        for(var i=0;i<data.length;i++){
          if(!this.mapIdParameter.get(data[i].id_param)){
            this.paramService.getParameter(data[i].id_param).subscribe(
              (data) => {
                this.mapIdParameter.set(data.id_param, data);
              },
              error => {
                console.log(error)
                this.alertService.error(error.error);
              }
            )
          }
        }
        this.dataSource = data;
      },
      error => {
        console.log(error)
        this.alertService.error(error.error);
      }
    );

    this.actSingleRecService.getActSingleRec(this.data.id).subscribe(
      (data) => {
        this.date = data.date;
        this.hours = data.hours;
        this.type = data.type ==  0?'Start':data.type ==  1?'Periodic':'End';
      },
      error => {
        console.log(error)
        this.alertService.error(error.error);
      }
    )

    this.title ="Activity Params";
  }
}
