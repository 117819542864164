export class stringList {
  order: number;
  label: string;
  value: string;
}

export const LANGLIST: stringList[] = [
  { order: 10, value: '', label: "" },
  { order: 20, value: 'en', label: "English" },
  { order: 30, value: 'it', label: "Italiano" }
];

export const COMMANDTYPELIST: stringList[] = [
  { order: 10, value: 'S', label: "SAVE" },
  { order: 20, value: 'W', label: "WRITE" }
];