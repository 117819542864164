import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';
import { GenericCrudService } from './generic-crud.service';
import { Observable } from 'rxjs';
import { IOSingleReport } from '../models/ioreport';

@Injectable({
  providedIn: 'root'
})
export class IOSingleRecCrudService extends GenericCrudService<IOSingleReport, number>{

  constructor(http: HttpClient,
    appConfigService: AppConfigService) {
    super(http, appConfigService, "reports");
    this.load();
  }

  getIOSingleRec(idobj: number): Observable<IOSingleReport> {
    return this.http.get<IOSingleReport>(`${this.endpoint}/${this.apiName}/id=${idobj}`);
  }
}
