import { Component, } from '@angular/core';
import { Label } from '../models/label';
import { MatDialog } from '@angular/material/dialog';
import { AlertService } from '../services/alert.service';
import { LabelSettingsDialogComponent } from '../label-settings-dialog/label-settings-dialog.component';
import { GenericCrudComponent } from '../generic-crud/generic-crud.component'
import { LabelCrudService } from '../services/label-crud.service';
import { GenericSettingsDialogComponent } from '../generic-settings-dialog/generic-settings-dialog.component';

@Component({
  selector: 'app-label-settings',
  templateUrl: './label-settings.component.html',
  styleUrls: ['./label-settings.component.scss']
})

export class LabelSettingsComponent extends GenericCrudComponent<Label, string> {
  displayedColumns: string[] = ['select', 'label_key', 'label_en', 'label_it', 'date_upd',];

  constructor(
    protected crudService: LabelCrudService,
    protected alertService: AlertService,
    public dialog: MatDialog
  ) {
    super(crudService, alertService, dialog)
  }

  more(row: Label): void {
    throw new Error("Method not implemented.");
  }

  showAddDialog() {
    this.showEditDialog(LabelSettingsDialogComponent, null);
  }

  edit(row: Label) {
    this.showEditDialog(LabelSettingsDialogComponent, row);
  }
}

