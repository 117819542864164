import { Component, AfterViewInit } from '@angular/core';
import { AuthenticationService } from './services/authentication.service';
import { UserLogged } from './models/user-logged';
import { slideInAnimation } from './animations';
import { RouterOutlet } from '@angular/router';
import { AppConfigService } from './services/app-config.service';
import { FirmwareToMachineService } from './services/firmware-to-machine.service';
import { MatDialog } from '@angular/material/dialog';
import { FirmwareIotInstallationDialogComponent } from './firmware-iot-installation-dialog/firmware-iot-installation-dialog.component';
import { FirmwareIotInstallationOldDialogComponent } from './firmware-iot-installation-old-dialog/firmware-iot-installation-old-dialog.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    slideInAnimation
    // animation triggers go here
  ]
})
export class AppComponent implements AfterViewInit {
  ngAfterViewInit(): void {
  }
  currentUser: UserLogged;
  visibleBar = false;
  title = this.appConfigService.title;
  version = this.appConfigService.version;
  logo = this.appConfigService.miniLogo;
  doc =this.appConfigService.documentation;
  numFailed = 0;
  numRunning = 0;
  numSuccess = 0;
  numWarning = 0;
  other = 0;
  total = 0;
  numFailedOld = 0;
  numRunningOld = 0;
  numSuccessOld = 0;
  numWarningOld = 0;
  otherOld = 0;
  totalOld = 0;

  constructor(
    private authenticationService: AuthenticationService,
    private appConfigService: AppConfigService,
    private firmwareToMachineService: FirmwareToMachineService,
    public dialog: MatDialog

  ) {
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
      this.visibleBar = (this.currentUser !== null);
      if(this.visibleBar){
        var date = new Date();
        var lastWeek = new Date(date.getTime() - 7*24*60*60000);
        this.firmwareToMachineService.getFirmwareIotListDate(lastWeek).subscribe(x=>{
          this.numFailed = x.filter(x=>x.status==-1).length;
          this.numRunning = x.filter(x=>x.status==1).length;
          this.numSuccess = x.filter(x=>x.status==2).length;
          this.numWarning = x.filter(x=>x.status==0 && x.date_start_download!=null).length;
          this.other = x.filter(x=>x.status==0 && x.date_start_download==null).length;
          this.total = this.numFailed  + this.numRunning +this.numSuccess + this.numWarning +this.other;
        });
        this.firmwareToMachineService.getFirmwareIotListDateOld(lastWeek).subscribe(x=>{
          this.numFailedOld = x.filter(x=>x.status==-1).length;
          this.numRunningOld = x.filter(x=>x.status==1).length;
          this.numSuccessOld = x.filter(x=>x.status==2).length;
          this.numWarningOld = x.filter(x=>x.status==0 && x.date_start_download!=null).length;
          this.otherOld = x.filter(x=>x.status==0 && x.date_start_download==null).length;
          this.totalOld = this.numFailedOld  + this.numRunningOld +this.numSuccessOld + this.numWarningOld +this.otherOld;
         });
      }
    });
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  openLastWeek() {
    const dialogRef = this.dialog.open(FirmwareIotInstallationDialogComponent, {
      width: '80%'

    });
    dialogRef.afterClosed().subscribe(result => {});
  }

  openOld() {
    const dialogRef = this.dialog.open(FirmwareIotInstallationOldDialogComponent, {
      width: '80%'
    });

    dialogRef.afterClosed().subscribe(result => {});
  }
}
