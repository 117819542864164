import { Component } from '@angular/core';
import { Machine } from '../models/machine';
import { AlertService } from '../services/alert.service';
import { GenericListComponent } from '../generic-list/generic-list.component';
import { FirmwareIotInstallation } from '../models/firmwareIotInstallation';
import { MachineToFirmwareService } from '../services/machine-to-firmware.service';
import { FirmwareIot } from '../models/firmwareIot';
import { FirmwareIotCrudService } from '../services/firmware-iot-crud.service';
import { FirmwareCrudService } from '../services/firmware-crud.service';
import { FirmwareToMachineService } from '../services/firmware-to-machine.service';

@Component({
  selector: 'app-firmware-iot-installation-list',
  templateUrl: './firmware-iot-installation-list.component.html',
  styleUrls: ['./firmware-iot-installation-list.component.scss']
})
export class FirmwareIotInstallationListComponent extends GenericListComponent<FirmwareIotInstallation, number> {
  prop: 'id_fw_installation'
  listFirmware: FirmwareIot[];
  displayedColumns: string[] = ['fw_name', 'date_insert', 'date_start_download', 'date_end_download', 'status', 'total_attempts', 'action'];
  mapFirmwareId = new Map();
  constructor(
    protected firmwareIotService: FirmwareIotCrudService,
    protected firmwareService: FirmwareCrudService,
    protected machineToFirmwareService: MachineToFirmwareService,
    protected alertService: AlertService
  ) {
    super(alertService, null)
  }

  ngOnInit() {
    this.firmwareIotService.read().subscribe(
      (data: any[]) => {
        this.listFirmware = data;
        data.forEach( x => {
          this.mapFirmwareId.set(x.id_fw, x);
        });
      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )
    this.prop = 'id_fw_installation';
    super.ngOnInit();
  }

  getOnlineColor(isonline: number) {
    return Machine.getColor(isonline);
  }

  getStatusIcon(isonline: number) {
    return Machine.statusIcon(isonline);
  }

  cancelUpdate(row: FirmwareIotInstallation){
    this.machineToFirmwareService.delete(row.id_fw_installation).subscribe(
      (data: any[]) => {
        this.ngOnInit();
      },
      error => console.log('error'),
      () => console.log('completeLoad')
    );

  }

  setCustomDataAccessor(){
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'fw_name':
        return this.mapFirmwareId.get(item.id_fw)?.name
        default:
        return item[property];
      }
    };
  }
}
