import { Component, OnInit, ViewChild } from '@angular/core';
import { Command } from '../models/command';
import { StartCommand } from '../models/startCommand';
import { AlertService } from '../services/alert.service';
import { CommandCrudService } from '../services/command-crud.service';
import { StartCommandCrudService } from '../services/start-command-crud.service';
import { MatDialog } from '@angular/material/dialog';
import { MachineTestingComponent } from '../machine-testing/machine-testing.component';
import { SelectedMachineService } from '../services/selected-machine.service';
import { ModelCrudService } from '../services/model-crud.service';
import { ConnectionSignalRService } from '../services/connection-signal-r.service';
import { StartCommandDialogComponent } from '../start-command-dialog/start-command-dialog.component';
import { ModelCommandService } from '../services/model-command.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-machine-start-command',
  templateUrl: './machine-start-command.component.html',
  styleUrls: ['./machine-start-command.component.scss']
})
export class MachineStartCommandComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  listCommands: Command[];
  fil: string = '';
  listStartCommands: StartCommand[];
  dataSource: MatTableDataSource<StartCommand>;
  displayedColumns: string[] = ['editing', 'id_cmd', 'can_value', 'status', 'date_insert', 'date_applied'];

  constructor(
    public selectedMachine: SelectedMachineService,
    public modelService: ModelCrudService,
    public alertService: AlertService,
    public connectionSignalR: ConnectionSignalRService,
    protected modelCommandService: ModelCommandService,
    public startCommandService: StartCommandCrudService,
    public dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
   
    this.modelCommandService.getEntityList(this.selectedMachine.machineSub.value.id_model).subscribe(
      (data: any[]) => {
        this.listCommands = data;
        this.reload();

      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )

    this.reload();
  }

  reload() {
    this.startCommandService.getStartCommandsForMachine(this.selectedMachine.machineSub.value.id_machine).subscribe(
      (data: any[]) => {
        this.listStartCommands = data;
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.applyFilter(this.fil);
      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
    }
  }

  delete(row: StartCommand): void {
    this.startCommandService.delete(row.id_start_command).subscribe(
      (data: any[]) => {
        this.alertService.info("Start command has been successfully deleted");
        this.reload();
      },
      error => console.log('error'),
      () => console.log('completeLoad')
    );
  }

  showAddDialog(): void {
    const dialogRef = this.dialog.open(StartCommandDialogComponent, {
      width: 400 + 'px',
      data: {
        listCommands: this.listCommands,
        listStartCommands: this.listStartCommands
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.reload();
    });
  }

  onChange($event) {
    this.applyFilter($event);
    this.setCustomDataAccessor();
    this.setFilterPredicate();
  }

  setCustomDataAccessor(){
  }

  setFilterPredicate(){
  }
}