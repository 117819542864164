import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MaterialModule } from './material-module';
import { SidenavComponent } from './sidenav/sidenav.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppConfigService} from './services/app-config.service';
import { AlertComponent } from './alert/alert.component';
import { LabelSettingsComponent } from './label-settings/label-settings.component';
import { ParameterSettingsComponent } from './parameter-settings/parameter-settings.component';
import { CommandSettingsComponent } from './command-settings/command-settings.component';
import { ModelSettingsComponent } from './model-settings/model-settings.component'
import { AuthInterceptorService } from './services/auth-interceptor.service';
import { LabelSettingsDialogComponent } from './label-settings-dialog/label-settings-dialog.component';
import { SpeedDialFabComponent } from './speed-dial-fab/speed-dial-fab.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { EditButtonsComponent } from './edit-buttons/edit-buttons.component';
import { ModelSettingsDialogComponent } from './model-settings-dialog/model-settings-dialog.component';
import { LoaderComponent } from './loader/loader.component';
import { LoaderService } from './services/loader.service';
import { LoaderInterceptor } from './services/loader-interceptor.service';
import { StringListPipe } from './pipes/string-list.pipe';
import { RolePipe } from './pipes/role.pipe';
import { UserPipe } from './pipes/user.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { MachineSettingsComponent } from './machine-settings/machine-settings.component';
import { ModelPipe } from './pipes/model.pipe';
import { MachineSettingsDialogComponent } from './machine-settings-dialog/machine-settings-dialog.component';
import { UserSettingsDialogComponent } from './user-settings-dialog/user-settings-dialog.component';
import { UserTypeSettingsComponent } from './user-type-settings/user-type-settings.component';
import { UserTypeSettingsDialogComponent } from './user-type-settings-dialog/user-type-settings-dialog.component';
import { MachineMoreComponent } from './machine-more/machine-more.component';
import { MachineTelemetryComponent } from './machine-telemetry/machine-telemetry.component';
import { MachineParametersComponent } from './machine-parameters/machine-parameters.component';
import { MachineCommandsComponent } from './machine-commands/machine-commands.component';
import { MachineFirmwareComponent } from './machine-firmware/machine-firmware.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserTypeMoreComponent } from './user-type-more/user-type-more.component';
import { UserMoreComponent } from './user-more/user-more.component';
import { MachineListComponent } from './machine-list/machine-list.component';
import { ParameterMoreComponent } from './parameter-more/parameter-more.component';
import { CommandMoreComponent } from './command-more/command-more.component';
import { ParameterSettingsDialogComponent } from './parameter-settings-dialog/parameter-settings-dialog.component';
import { CommandSettingsDialogComponent } from './command-settings-dialog/command-settings-dialog.component';
import { ModelMoreComponent } from './model-more/model-more.component';
import { ModelListComponent } from './model-list/model-list.component';
import { ParameterListComponent } from './parameter-list/parameter-list.component';
import { TelemetryGroupSettingsComponent } from './telemetry-group-settings/telemetry-group-settings.component';
import { TelemetryGroupListComponent } from './telemetry-group-list/telemetry-group-list.component';
import { TelemetryGroupMoreComponent } from './telemetry-group-more/telemetry-group-more.component';
import { TelemetryGroupSettingsDialogComponent } from './telemetry-group-settings-dialog/telemetry-group-settings-dialog.component';
import { CommandListComponent } from './command-list/command-list.component';
import { CommandParamSettingsComponent } from './command-param-settings/command-param-settings.component';
import { MachineTestComponent } from './machine-test/machine-test.component';
import { ParamPipe } from './pipes/param.pipe';
import { MachineLogerrorComponent } from './machine-logerror/machine-logerror.component';
import { HexStringPipe } from "./pipes/hexstring.pipe";
import { CommandParamSettingsDialogComponent } from './command-param-settings-dialog/command-param-settings-dialog.component';
import { FirmwareIotSettingsComponent } from './firmware-iot-settings/firmware-iot-settings.component';
import { FirmwareIotSettingsDialogComponent } from './firmware-iot-settings-dialog/firmware-iot-settings-dialog.component';
import { ParameterListUpdateComponent } from './parameter-list/parameter-list-update.component';
import { FirmwareSettingsComponent } from './firmware-settings/firmware-settings.component';
import { FirmwareSettingsDialogComponent } from './firmware-settings-dialog/firmware-settings-dialog.component';
import { MachineFirmwareIotComponent } from './machine-firmware-iot/machine-firmware-iot.component';
import { MachineStatePipe } from './pipes/machine-state.pipe';
import { TelemetryGroupPipe } from './pipes/telemetry-group.pipe';
import { EventListComponent } from './event-list/event-list.component';
import { ActivityListComponent } from './activity-list/activity-list.component';
import { GeolocationListComponent } from './geolocation-list/geolocation-list.component';
import { ModelLevelSettingsComponent } from './model-level-settings/model-level-settings.component';
import { ModelLevelSettingsDialogComponent } from './model-level-settings-dialog/model-level-settings-dialog.component';
import { MachineIoComponent } from './machine-io/machine-io.component';
import { MachineDigitalInputComponent } from './machine-digital-input/machine-digital-input.component';
import { MachineAnalogInputComponent } from './machine-analog-input/machine-analog-input.component';
import { MachineEventCounterComponent } from './machine-event-counter/machine-event-counter.component';
import { MachineHourCounterComponent } from './machine-hour-counter/machine-hour-counter.component';
import { CounterEdgeTooltipPipe } from './pipes/counter-edge-tooltip.pipe';
import { CounterEdgeIconPipe } from './pipes/counter-edge-icon.pipe';
import { DigitalInputTypeTooltipPipe } from './pipes/digital-input-type-tooltip.pipe';
import { DigitalInputTypeIconPipe } from './pipes/digital-input-type-icon.pipe';
import { HourCounterPipe } from './pipes/hour-counter.pipe';
import { MachineDigitalOutputComponent } from './machine-digital-output/machine-digital-output.component';
import { FirmwareMoreComponent } from './firmware-more/firmware-more.component';
import { MachineIdnodeComponent } from './machine-idnode/machine-idnode.component';
import { MachineConnectionTypePipe } from './pipes/machine-connection-type.pipe';
import { MachineFirmwareIot4GComponent } from './machine-firmware-iot4-g/machine-firmware-iot4-g.component';
import { MachineFirmware4GComponent } from './machine-firmware4-g/machine-firmware4-g.component';
import { MachineEnvComponent } from './machine-env/machine-env.component';
import { ChangeEnvComponent } from './change-env/change-env.component';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { MachineDebugComponent } from './machine-debug/machine-debug.component';
import { SimInfoDialogComponent } from './sim-info-dialog/sim-info-dialog.component';
import { MachineReportComponent } from './machine-report/machine-report.component';
import { ActivityParamDialogComponent } from './activity-param-list-dialog/activity-param-list-dialog.component';
import { MachineGeneralSettingsComponent } from './machine-general-settings/machine-general-settings.component';
import { BatteryLevelPipe } from './pipes/battery-level.pipe';
import { FirmwareIotUpdateComponent } from './firmware-iot-udate/firmware-iot-update.component';
import { FirmwareIotInstallationListComponent } from './firmware-iot-installation-list/firmware-iot-installation-list.component';
import { MachineUpdateComponent } from './machine-list/machine-update.component';
import { UpdateBarComponent } from './update-bar/update-bar.component';
import { FirmwareIotInstallationDialogComponent } from './firmware-iot-installation-dialog/firmware-iot-installation-dialog.component';
import * as materialMomentAdapter from '@angular/material-moment-adapter';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { FirmwareIotInstallationOldDialogComponent } from './firmware-iot-installation-old-dialog/firmware-iot-installation-old-dialog.component';
import { FwInstallationStatus } from './pipes/fw-installation-status.pipe';
import { ReportListComponent } from './report-list/report-list.component';
import { MachineDigitalInputReadComponent } from './machine-digital-input-read/machine-digital-input-read.component';
import { ReportIoListDialogComponent } from './report-io-list-dialog/report-io-list-dialog.component';
import { ReportIoAnalogInputComponent } from './report-io-analog-input/report-io-analog-input.component';
import { ReportIoDigitalInputComponent } from './report-io-digital-input/report-io-digital-input.component';
import { ReportIoDigitalOutputComponent } from './report-io-digital-output/report-io-digital-output.component';
import { ReportIoEventCounterComponent } from './report-io-event-counter/report-io-event-counter.component';
import { ReportIoHourCounterComponent } from './report-io-hour-counter/report-io-hour-counter.component';
import { CustomCommandSettingsComponent } from './custom-command-settings/custom-command-settings.component';
import { CustomCommandSettingsDialogComponent } from './custom-command-settings-dialog/custom-command-settings-dialog.component';
import { NodeColorPipe } from './pipes/node-color.pipe';
import { MachineCustomCommandsComponent } from './machine-custom-commands/machine-custom-commands.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SidenavComponent,
    AlertComponent,
    LabelSettingsComponent,
    LabelSettingsDialogComponent,
    CommandSettingsComponent,
    CommandSettingsDialogComponent,
    CommandMoreComponent,
    CommandListComponent,
    ParameterSettingsComponent,
    ParameterMoreComponent,
    ParameterSettingsDialogComponent,
    ParameterListComponent,
    ParameterListUpdateComponent,
    ModelSettingsComponent,
    ModelSettingsDialogComponent,
    ModelMoreComponent,
    ModelListComponent,
    UserSettingsComponent,
    UserSettingsDialogComponent,
    UserMoreComponent,
    UserListComponent,
    TelemetryGroupSettingsComponent,
    TelemetryGroupListComponent,
    TelemetryGroupMoreComponent,
    TelemetryGroupSettingsDialogComponent,
    UserTypeSettingsComponent,
    UserTypeSettingsDialogComponent,
    UserTypeMoreComponent,
    MachineSettingsComponent,
    MachineSettingsDialogComponent,
    MachineListComponent,
    MachineMoreComponent,
    MachineTestComponent,
    MachineTelemetryComponent,
    SpeedDialFabComponent,
    EditButtonsComponent,
    LoaderComponent,
    StringListPipe,
    RolePipe,
    UserPipe,
    FilterPipe,
    ModelPipe,
    CommandParamSettingsComponent,
    MachineParametersComponent,
    ParamPipe,
    MachineCommandsComponent,
    MachineFirmwareComponent,
    MachineLogerrorComponent,
    HexStringPipe,
    CommandParamSettingsDialogComponent,
    FirmwareIotSettingsComponent,
    FirmwareIotSettingsDialogComponent,
    FirmwareSettingsComponent,
    FirmwareSettingsDialogComponent,
    MachineFirmwareIotComponent,
    MachineStatePipe,
    TelemetryGroupPipe,
    EventListComponent,
    ActivityListComponent,
    GeolocationListComponent,
    ModelLevelSettingsComponent,
    ModelLevelSettingsDialogComponent,
    MachineIoComponent,
    MachineDigitalInputComponent,
    MachineAnalogInputComponent,
    MachineEventCounterComponent,
    MachineHourCounterComponent,
    CounterEdgeTooltipPipe,
    CounterEdgeIconPipe,
    DigitalInputTypeTooltipPipe,
    DigitalInputTypeIconPipe,
    HourCounterPipe,
    MachineDigitalOutputComponent,
    FirmwareMoreComponent,
    MachineIdnodeComponent,
    MachineConnectionTypePipe,
    MachineFirmwareIot4GComponent,
    MachineFirmware4GComponent,
    MachineEnvComponent,
    ChangeEnvComponent,
    MessageDialogComponent,
    MachineDebugComponent,
    SimInfoDialogComponent,
    MachineReportComponent,
    ActivityParamDialogComponent,
    MachineGeneralSettingsComponent,
    BatteryLevelPipe,
    FirmwareIotUpdateComponent,
    FirmwareIotInstallationListComponent,
    MachineUpdateComponent,
    UpdateBarComponent,
    FirmwareIotInstallationDialogComponent,
    FirmwareIotInstallationOldDialogComponent,
    FwInstallationStatus,
    ReportListComponent,
    MachineDigitalInputReadComponent,
    ReportIoListDialogComponent,
    ReportIoAnalogInputComponent,
    ReportIoDigitalInputComponent,
    ReportIoDigitalOutputComponent,
    ReportIoEventCounterComponent,
    ReportIoHourCounterComponent,
    CustomCommandSettingsComponent,
    CustomCommandSettingsDialogComponent,
    NodeColorPipe,
    MachineCustomCommandsComponent
  ],
  entryComponents: [LabelSettingsDialogComponent,CommandSettingsDialogComponent,ParameterSettingsDialogComponent,ModelSettingsDialogComponent,UserSettingsDialogComponent,TelemetryGroupSettingsDialogComponent,UserTypeSettingsDialogComponent,MachineSettingsDialogComponent,CommandParamSettingsDialogComponent,FirmwareIotSettingsDialogComponent,FirmwareSettingsDialogComponent,ModelLevelSettingsDialogComponent,ChangeEnvComponent],

  imports: [
    BrowserModule,
    AppRoutingModule,
    MatNativeDateModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AppConfigService],
      useFactory: (appConfigService: AppConfigService) => {
        return () => {
          //Make sure to return a promise!
          return appConfigService.loadAppConfig();
        };
      }
    },
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: DateAdapter,
      useClass: materialMomentAdapter.MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
