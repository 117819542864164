import { Injectable } from '@angular/core';
import { GenericCrudService } from '../services/generic-crud.service'
import { UserType } from '../models/userType';
import { AppConfigService } from './app-config.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserTypeCrudService extends GenericCrudService<UserType, number>{

  constructor(http: HttpClient,
    appConfigService: AppConfigService) {
    super(http, appConfigService, "userTypes");
    this.load();
  }
}