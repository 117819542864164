import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Command } from '../models/command';

@Injectable({
  providedIn: 'root'
})
export class SelectedCommandService {
  commandSub = new BehaviorSubject<Command>(undefined);
  commandCurrent = this.commandSub.asObservable()

  constructor() { }

  setCommand(command: Command) {
    this.commandSub.next(command);
  }

  unsetCommand() {
    this.commandSub.next(undefined);
  }
}