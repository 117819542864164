import { Component } from '@angular/core';
import { LANGLIST } from '../models/mock-string-list';
import { UserType } from '../models/userType';
import { User } from '../models/user';
import { UserTypeCrudService } from '../services/user-type-crud.service';
import { UserCrudService } from '../services/user-crud.service';
import { AlertService } from '../services/alert.service';
import { GenericListComponent } from '../generic-list/generic-list.component';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent extends GenericListComponent<User, number> {
  prop: string;
  langList = LANGLIST;
  roleList: UserType[];
  displayedColumns: string[] = ['id_user', 'id_usertype', 'name', 'email', 'isactive', 'lang', 'id_maker', 'telephone'];

  constructor(
    protected userTypeService: UserTypeCrudService,
    protected userService: UserCrudService,
    protected alertService: AlertService
  ) {
    super(alertService, userService)
  }

  ngOnInit() {
    this.prop = 'id_user';
    this.userTypeService.listObservable.subscribe(
      (data: any[]) => {
        this.roleList = data;
      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )
    super.ngOnInit();
  }
}