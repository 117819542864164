import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ModelLevel } from '../models/modelLevel';
import { AppConfigService } from './app-config.service';
import { GenericCrudService } from './generic-crud.service';

@Injectable({
  providedIn: 'root'
})
export class ModelLevelCrudService extends GenericCrudService<ModelLevel, number>{

  constructor(http: HttpClient,
    appConfigService: AppConfigService) {
    super(http, appConfigService, "modelsbylevel");
    this.load()
  }

  download(id_modelbylevel: number): Observable<Response> {
    console.log(this.apiName);
    return this.http.get<Response>(`${this.endpoint}/${this.apiName}/${id_modelbylevel}/xml`
      , { responseType: 'blob' as 'json' },

    );
  }

  readByModel(id_model: number): Observable<ModelLevel[]> {
    console.log(this.apiName);
    return this.http.get<ModelLevel[]>(`${this.endpoint}/${this.apiName}/id_model=${id_model}`);
  }

  readByRole(id_usertype: number): Observable<ModelLevel[]> {
    console.log(this.apiName);
    return this.http.get<ModelLevel[]>(`${this.endpoint}/${this.apiName}/id_usertype=${id_usertype}`);
  }
}


