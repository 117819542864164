import { Component, Inject } from '@angular/core';
import { GenericSettingsDialogComponent } from '../generic-settings-dialog/generic-settings-dialog.component';
import { CommandParam } from '../models/commandParam';
import { CommandParamCrudService } from '../services/command-param-crud.service';
import { AlertService } from '../services/alert.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { HexStringPipe } from '../pipes/hexstring.pipe';
import { SelectedCommandService } from '../services/selected-command.service';
import { first } from 'rxjs/operators';
import { Command } from '../models/command';

@Component({
  selector: 'app-command-param-settings-dialog',
  templateUrl: './command-param-settings-dialog.component.html',
  providers: [HexStringPipe]
})
export class CommandParamSettingsDialogComponent extends GenericSettingsDialogComponent<CommandParam, number>  {
  private command: Command;

  constructor(
    protected commandParamService: CommandParamCrudService,
    protected alertService: AlertService,
    public dialogRef: MatDialogRef<CommandParamSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected fb: FormBuilder,
    protected selectedCommand: SelectedCommandService,
    private hexStringPipe: HexStringPipe
  ) {
    super(commandParamService, alertService, dialogRef, data, fb)
  }

  ngOnInit() {
    super.ngOnInit();
    this.selectedCommand.commandCurrent.pipe(first()).subscribe(
      command => {
        this.command = command;
      }
    );

    this.title = this.isNew ? "Create new command parameter" : "Modify command parameter";
    this.form = this.fb.group({
      commandParam_nameCtrl: this.isNew ? '' : this.entity.name,
      commandParam_order_byCtrl: this.isNew ? '' : this.entity.order_by,
      commandParam_bytelenCtrl: this.isNew ? '' : this.entity.bytelen,
      commandParam_fix_valCtrl: this.isNew ? '' : this.hexStringPipe.transform(this.entity.fix_val),
    });
  }

  update(commandParam: CommandParam): void {
    commandParam.name = this.form.get('commandParam_nameCtrl').value;
    commandParam.fix_val = this.hexStringPipe.untransform(this.form.get('commandParam_fix_valCtrl').value),
      commandParam.bytelen = this.form.get('commandParam_bytelenCtrl').value,
      commandParam.order_by = this.form.get('commandParam_order_byCtrl').value,
      commandParam.id = commandParam.id_cmdParam;
    this.commandParamService.update(this.entity)
      .subscribe(
        (data) => {
          this.alertService.info("Command parameter successfully updated");
          this.dialogRef.close(true);
        },
        error => {
          console.log(error)
          this.alertService.error(error.error);
        }
      );
  }

  add(): void {
    var commandParam: CommandParam = {
      id: undefined,
      id_cmdParam: undefined,
      id_cmd: this.command.id_cmd,
      name: this.form.get('commandParam_nameCtrl').value,
      fix_val: this.hexStringPipe.untransform(this.form.get('commandParam_fix_valCtrl').value),
      bytelen: this.form.get('commandParam_bytelenCtrl').value,
      order_by: this.form.get('commandParam_order_byCtrl').value,
      date_del: null,
      date_upd: null
    };
    this.commandParamService.create(commandParam)
      .subscribe(
        (data) => {
          this.alertService.info("Command parameter has been successfully added");
          this.dialogRef.close(true);
        },
        error => {
          console.log(error)
          this.alertService.error(error.error);
        }
      );
  }

  delete() {
    this.commandParamService.delete(this.entity.id_cmdParam)
      .subscribe(
        (data) => {
          this.alertService.info("Command parameter has been successfully deleted");
          this.dialogRef.close(true);
        },
        error => {
          console.log(error)
          this.alertService.error(error.error);
        }
      );
  }

  restore() {
    var param_temp = this.entity;
    param_temp.date_del = null;
    this.update(param_temp);
  }
}
