import { Component, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { MachineTestingComponent } from '../machine-testing/machine-testing.component';
import { ConnectionSignalRService } from '../services/connection-signal-r.service';
import { ModelCrudService } from '../services/model-crud.service';
import { SelectedMachineService } from '../services/selected-machine.service';
import * as signalR from '@aspnet/signalr';
import { signalRInvocation, signalRSubscription } from '../models/signalRTopic';
import { AlertService } from '../services/alert.service';
import { RespLogError } from '../models/respLogError';

@Component({
  selector: 'app-machine-logerror',
  templateUrl: './machine-logerror.component.html',
  styleUrls: ['./machine-logerror.component.scss']
})
export class MachineLogerrorComponent extends MachineTestingComponent {
  @Output() back: EventEmitter<any> = new EventEmitter();


  public enableReading = true;
  public valueBar = 0;
  public listResp: RespLogError[] = [];
  first = true;

  constructor(
    public selectedMachine: SelectedMachineService,
    public modelService: ModelCrudService,
    public connectionSignalR: ConnectionSignalRService,
    public alertService: AlertService,
    private cd: ChangeDetectorRef

  ) {
    super(selectedMachine, modelService, alertService, connectionSignalR);
  }

  clean() {
    this.listResp = [];
  }

  subscribe() {
    if (this.first) {
      this.interceptDisconnection();
      this.first = false;

      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendConnectionStatus, (status: string) => {

        console.log('cazz ' + status);

        if (status === "0") {
          this.back.emit();
        }
      });

      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendPubLogError, (resp: string, packNum: number, packCount: number) => {
        var r = new RespLogError();
        r.date = new Date();
        r.errors = JSON.parse(resp);
        this.listResp.push(r);

        if (packNum < packCount) {
          this.start(false);
          this.valueBar = packNum / packCount * 100;
        }
        else {
          this.valueBar = 0;
          this.enableReading = true;
        }
      });

      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendGenericError, (resp: string) => {
        this.alertService.error(resp);
      });
    }
  }

  start(start: boolean) {
    this.subscribe();
    this.enableReading = false;
    if (this.connectionSignalR.connectionSub.value.state == signalR.HubConnectionState.Connected) {
      this.connectionSignalR.connectionSub.value.invoke(signalRInvocation.getLogError, this.selectedMachine.machineSub.value.serial_number, start)
        .then(() => console.log('Read Log Error')).catch((reason: any) => {
          if (reason != null) {
            this.enableReading = true;
            console.log(reason.message);
            this.alertService.error(reason.message.split("Exception: ")[1]);
          }
        });
    }
  }
}
