import { Component, OnInit } from '@angular/core';
import { SelectedModelService } from '../services/selected-model.service';
import { Location } from '@angular/common';
import { ModelCommandService } from '../services/model-command.service';
import { first } from 'rxjs/operators';
import { Model } from '../models/model';
import { UserCrudService } from '../services/user-crud.service';
import { User } from '../models/user';
import { ModelParamService } from '../services/model-param.service';
import { ModelTelemetryService } from '../services/model-telemetry.service';

@Component({
  selector: 'app-model-more',
  templateUrl: './model-more.component.html'
})
export class ModelMoreComponent implements OnInit {
  public model: Model;
  public listUsers: User[];

  constructor(
    protected userService: UserCrudService,
    protected selectedModel: SelectedModelService,
    protected location: Location,
    public modelCommandsService: ModelCommandService,
    public modelParamService: ModelParamService,
    public modelTelGroupService: ModelTelemetryService
  ) { }

  ngOnInit() {
    this.userService.listObservable.subscribe(
      (data: any[]) => {
        this.listUsers = data;
      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )

    this.selectedModel.modelCurrent.pipe(first()).subscribe(
      model => {
        this.model = model;
      }
    );
  }

  back() {
    this.location.back();
  }
}