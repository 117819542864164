import { Component, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import * as signalR from '@aspnet/signalr';
import { MachineIoComponent } from '../machine-io/machine-io.component';
import { AnalogicInput } from '../models/analogicInput';
import { signalRInvocation, signalRSubscription } from '../models/signalRTopic';
import { AlertService } from '../services/alert.service';
import { ConnectionSignalRService } from '../services/connection-signal-r.service';
import { ModelCrudService } from '../services/model-crud.service';
import { SelectedMachineService } from '../services/selected-machine.service';

@Component({
  selector: 'report-io-analog-input',
  templateUrl: './report-io-analog-input.component.html',
  styleUrls: ['./report-io-analog-input.component.scss']
})
export class ReportIoAnalogInputComponent{
  @Input() dataSource: AnalogicInput[];
  displayedColumns: string[] = ['number', 'type','enabled', 'val', 'activeSoil', 'sCSoil', 'oCSoil'];

  constructor(
    public selectedMachine: SelectedMachineService,
    public modelService: ModelCrudService,
    public connectionSignalR: ConnectionSignalRService,
    public alertService: AlertService
  ) {
  }

  ngOnInit() {
  }

}
