import { Subject } from "rxjs";
import { FirmwareIotCrudService } from "../services/firmware-iot-crud.service";
import { Component, Inject, ViewChild } from "@angular/core";
import { AlertService } from "../services/alert.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FirmwareIotInstallation } from "../models/firmwareIotInstallation";
import { FirmwareToMachineService } from "../services/firmware-to-machine.service";
import { MachineCrudService } from "../services/machine-crud.service";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";

@Component({
  selector: "app-firmware-iot-installation-old-dialog",
  templateUrl: "./firmware-iot-installation-old-dialog.component.html",
  styleUrls: ['./firmware-iot-installation-old-dialog.component.scss']

})
export class FirmwareIotInstallationOldDialogComponent {
  dataSource: MatTableDataSource<FirmwareIotInstallation>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  title: string;
  displayedColumns: string[] = [
    "id_fw_installation",
    "status",
    "serial_number",
    "fw_name",
    "date_insert",
    "date_start_download",
    "date_end_download",
    "total_attempts",
  ];
  mapIdParameter = new Map();
  date: Date;
  numFailed: number;
  numRunning: number;
  numSuccess: number;
  numWarning: number;
  other: number;
  mapMachineId = new Map();
  mapFirmwareId = new Map();

  constructor(
    private firmwareIotInstallationService: FirmwareToMachineService,
    private firmwareIotService: FirmwareIotCrudService,
    private machineService: MachineCrudService,
    protected alertService: AlertService,
    public dialogRef: MatDialogRef<FirmwareIotInstallationOldDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    var now = new Date();
    var nowUTC = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
    nowUTC.setDate(nowUTC.getDate() - 7);
    this.date = nowUTC;
  }

  ngOnInit() {
    this.search(this.date);
    this.title = "List of pending and recently processed updates older than";
  }

  search(date: Date){
    this.firmwareIotInstallationService
    .getFirmwareIotListDateOld(date)
    .subscribe(
      (data) => {
        debugger;
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.numFailed = data.filter((x) => x.status == -1).length;
        this.numRunning = data.filter((x) => x.status == 1).length;
        this.numSuccess = data.filter((x) => x.status == 2).length;
        this.numWarning = data.filter(
          (x) => x.status == 0 && x.date_start_download != null
        ).length;
        this.other = data.filter(
          (x) => x.status == 0 && x.date_start_download == null
        ).length;

        this.firmwareIotService.read().subscribe(
          (data: any[]) => {
            data.forEach((x) => {
              this.mapFirmwareId.set(x.id_fw, x);
            });
          },
          (error) => console.log("error"),
          () => console.log("completeLoad")
        );
        this.machineService.read().subscribe(
          (data: any[]) => {
            data.forEach((x) => {
              this.mapMachineId.set(x.id_machine, x);
            });
          },
          (error) => console.log("error"),
          () => console.log("completeLoad")
        );
      },
      (error) => {
        console.log(error);
        this.alertService.error(error.error);
      }
    );

  }

  reload( event: MatDatepickerInputEvent<Date>) {
    this.search(event.value);
  }
}
