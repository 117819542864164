import { Injectable } from '@angular/core';
import { IEntityListService } from './ientity-list.service';
import { Observable } from 'rxjs';
import { User } from '../models/user';
import { AppConfigService } from './app-config.service';
import { HttpClient } from '@angular/common/http';
import { UserCrudService } from './user-crud.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserTypeUserService extends IEntityListService<User>{
  constructor(protected http: HttpClient,
    appConfigService: AppConfigService,
    protected userService: UserCrudService) {
    super(http, appConfigService);
  }
  
  postEntityList(idobj: number, listRelatedEntuty: User[]): Observable<any> {
    throw new Error("Method not implemented.");
  }

  getEntityList(idobj: number): Observable<User[]> {
    return this.userService.listObservable.pipe(map(users => users.filter(user => user.id_usertype === idobj)));
  }
}