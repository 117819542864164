import { Component, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { SelectedMachineService } from '../services/selected-machine.service';
import { ModelCrudService } from '../services/model-crud.service';
import { ConnectionSignalRService } from '../services/connection-signal-r.service';
import { MachineTestingComponent } from '../machine-testing/machine-testing.component';
import { switchMap } from 'rxjs/operators';
import { from } from 'rxjs';
import { RespGetData } from '../models/respGetData';
import { signalRInvocation, signalRSubscription } from '../models/signalRTopic';
import * as signalR from '@aspnet/signalr';
import { AlertService } from '../services/alert.service';
import { Firmware } from '../models/firmware';
import { FirmwareCrudService } from '../services/firmware-crud.service';

@Component({
  selector: 'app-machine-firmware4-g',
  templateUrl: './machine-firmware4-g.component.html',
  styleUrls: ['./machine-firmware4-g.component.scss']
})
export class MachineFirmware4GComponent extends MachineTestingComponent {
  @Output() back: EventEmitter<any> = new EventEmitter();

  public listFw: Firmware[] = [];
  public id_fw: number;
  public listResp: RespGetData[] = [];
  public updateOn = false;
  protected first = true;
  protected valueBar = 0;

  constructor(
    public selectedMachine: SelectedMachineService,
    public modelService: ModelCrudService,
    public connectionSignalR: ConnectionSignalRService,
    public fwService: FirmwareCrudService,
    public alertService: AlertService,
    private cd: ChangeDetectorRef
  ) {
    super(selectedMachine, modelService, alertService, connectionSignalR);
  }

  ngOnInit() {
    super.ngOnInit();
    this.fwService.read().
      pipe(switchMap((x: Firmware[]) => from(x))).
      subscribe(
        (data: Firmware) => {

          var fw = data;
          this.listFw.push(fw);
        },
        error => console.log('error'),
        () => console.log('completeLoad')
      )
  }

  clean() {
    this.listResp = [];
  }

  startDownload() {
    this.subscribe();
    if (this.connectionSignalR.connectionSub.value.state == signalR.HubConnectionState.Connected) {
      this.connectionSignalR.connectionSub.value.invoke(signalRInvocation.updateFirmwareBoard4G, this.selectedMachine.machineSub.value.serial_number, this.id_fw).then(
        () => {
          this.updateOn = false;
          this.alertService.info("Firware update is running. Please wait!")
        }).catch((reason: any) => {
          if (reason != null) {
            console.log(reason.message);
            this.alertService.error(reason.message.split("Exception: ")[1]);
          }
        });
    }
  }

  subscribe() {
    if (this.first) {
      this.interceptDisconnection();

      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendConnectionStatus, (status: string) => {
        //  this.selectedMachine.machineSub.value.isonline = status;
        if (status === "4") {
          this.updateOn = true;
        }
        else {
          this.updateOn = false;
        }
      });

      this.first = false;

      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendGenericError, (resp: string) => {
        //  this.selectedMachine.machineSub.value.isonline = status;
        this.alertService.error(resp);
      });

      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendAggFirmwareBoard4G, (status: string) => {
        //  this.selectedMachine.machineSub.value.isonline = status;
        this.alertService.info(status);
      });

      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendPubUpdateFileS19, (value: number) => {
        //  this.selectedMachine.machineSub.value.isonline = status;
        this.valueBar = value;
      });
    }
  }
}
