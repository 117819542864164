import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Activity } from '../models/activity';
import { AppConfigService } from './app-config.service';
import { IEntityListService } from './ientity-list.service';

@Injectable({
  providedIn: 'root'
})
export class MachineToActivityService extends IEntityListService<Activity>{
  protected apiName: string = "machines";

  constructor(protected http: HttpClient,
    appConfigService: AppConfigService) {
    super(http, appConfigService);
  }

  postEntityList(idobj: number, listRelatedEntuty: Activity[]): Observable<any> {
    throw new Error('Method not implemented.');
  }

  getEntityList(idobj: number): Observable<Activity[]> {
    return this.http.get<Activity[]>(`${this.endpoint}/${this.apiName}/id_machine=${idobj}/activities`);
  }
}
