import { Component } from '@angular/core';
import * as signalR from '@aspnet/signalr';
import { MachineIoComponent } from '../machine-io/machine-io.component';
import { DigitalHourCounter } from '../models/digitalHourCounter';
import { signalRSubscription, signalRInvocation } from '../models/signalRTopic';
import { AlertService } from '../services/alert.service';
import { ConnectionSignalRService } from '../services/connection-signal-r.service';
import { ModelCrudService } from '../services/model-crud.service';
import { SelectedMachineService } from '../services/selected-machine.service';

@Component({
  selector: 'app-machine-hour-counter',
  templateUrl: './machine-hour-counter.component.html',
  styleUrls: ['./machine-hour-counter.component.scss']
})
export class MachineHourCounterComponent extends MachineIoComponent<DigitalHourCounter>{
  displayedColumns: string[] = ['select', 'number', 'isHourCounter', 'activeState', 'counter']

  constructor(
    public selectedMachine: SelectedMachineService,
    public modelService: ModelCrudService,
    public connectionSignalR: ConnectionSignalRService,
    public alertService: AlertService
  ) {
    super(selectedMachine, modelService, connectionSignalR, alertService);
    this.signalRSendPubGet = signalRSubscription.sendPubGetDigHourCounters;
    this.signalRSendPubSet = "";
    this.signalRinvokeGet = signalRInvocation.getDigHourCounters;
  }

  ngOnInit() {
  }

  reset() {
    this.subscribe();
    if (this.connectionSignalR.connectionSub.value.state == signalR.HubConnectionState.Connected) {
      let list = [];
      this.selection.selected.forEach((x) => {
        list.push(
          {
            set: true,
            number: x.number
          }
        )
      });

      this.connectionSignalR.connectionSub.value.invoke(signalRInvocation.resetDigCounters, this.selectedMachine.machineSub.value.serial_number, list).then(
        () => {
          console.log("resetDigCounters");

        }).catch((reason: any) => {
          if (reason != null) {
            console.log(reason.message);
            this.alertService.error(reason.message.split("Exception: ")[1]);
          }
        });
    }
  }
}
