import { Component, OnInit } from '@angular/core';
import { Event } from '../models/event';
import { GenericListComponent } from '../generic-list/generic-list.component';
import { AlertService } from '../services/alert.service';
import { EventCrudService } from '../services/event-crud.service';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html'
})
export class EventListComponent extends GenericListComponent<Event,number> {
  prop: "id_events";

  displayedColumns: string[] = ['id_events','node_id','event_cmdspec','event_index','event_id','event_value','date_insert'];

  constructor(
    protected eventService :EventCrudService,
    protected alertService : AlertService
  ) {
    super(alertService,eventService);
    this.prop = 'id_events';
  }

  dec2hexString(dec:number) {
    return '0x' + (dec+0x10000).toString(16).substr(-4).toUpperCase();
  }
}

