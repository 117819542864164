import { Component, OnInit, Input, ViewChild, SimpleChanges } from '@angular/core';
import { IModelObj } from '../models/iModelObj';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IEntityListService } from '../services/ientity-list.service';
import { SelectionModel } from '@angular/cdk/collections';
import { AlertService } from '../services/alert.service';
import { GenericCrudService } from '../services/generic-crud.service';
import { environment } from 'src/environments/environment';

export abstract class GenericListComponent<Gen extends IModelObj, ID> implements OnInit {
  @Input() idObj: number;
  @Input() IEntitytListService: IEntityListService<Gen> = null;
  @Input() reducibleList: boolean;
  @Input() editableList: boolean;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  entityList: Gen[] = [];
  editing = false;
  filteredList: Gen[] = [];
  abstract prop: string;
  dataSource: MatTableDataSource<Gen>;
  displayedColumns: string[] = [];
  fil: string = '';
  selection: SelectionModel<Gen>;

  constructor(
    protected alertService: AlertService,
    protected crudService: GenericCrudService<Gen, ID>
  ) { }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  setCustomDataAccessor(){
  }

  setFilterPredicate(){}

  ngOnInit() {
    if (this.crudService != null) {
      this.crudService.listObservable.subscribe(
        (data: any[]) => {
          this.entityList = data;
          this.loadRelatedEntity();
        },
        error => console.log('error'),
        () => console.log('completeLoad')
      )
    }
    else {
      this.loadRelatedEntity();
    }
  }

  loadRelatedEntity() {
    this.IEntitytListService.getEntityList(this.idObj).subscribe(
      (data: Gen[]) => {
        if (this.entityList.length == 0) {
          this.entityList = data;
        }
        this.filteredList = data;
        this.reloadTable();
      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )
  }

  reloadTable() {
    if (this.filteredList != undefined && this.entityList != undefined) {

      var prop = this.prop;
      var temp = this.filteredList;
      this.filteredList = this.entityList.filter(entity =>
        temp.findIndex(function (tempEntity) {
          return tempEntity[prop] == entity[prop];
        }) > -1);

      this.dataSource = new MatTableDataSource(this.filteredList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.selection = new SelectionModel<Gen>(true, this.filteredList);

      this.applyFilter(this.fil);
      this.setCustomDataAccessor();
      this.setFilterPredicate();
    }
  }

  filterListModify() {
    this.dataSource = new MatTableDataSource(this.entityList);
    this.displayedColumns.splice(0, 0, "select");

  }

  onChange($event) {
    this.applyFilter($event); 
    this.setCustomDataAccessor();
    this.setFilterPredicate();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.reloadTable();
  }

  edit(modify: boolean) {
    this.editing = modify;
    if (modify) {
      this.filterListModify();
    }
    else {
      this.dataSource = new MatTableDataSource(this.filteredList);
      this.displayedColumns.splice(0, 1);
    }
    this.selection = new SelectionModel<Gen>(true, this.filteredList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.applyFilter(this.fil);
    this.setCustomDataAccessor();
    this.setFilterPredicate();
  }

  save() {
    var prop = this.prop;
    this.IEntitytListService.postEntityList(this.idObj, this.selection.selected).subscribe(
      () => {
        this.edit(false);
        this.alertService.success('List of related model has been successfully updated');
        this.loadRelatedEntity();
      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )
  }

  isDisabled(row: Gen) {
    var prop = this.prop;
    return !this.reducibleList && this.filteredList.findIndex(
      function (element) {
        return element[prop] === row[prop];
      }
    ) >= 0;
  }

  checked(row: Gen) {
    return this.selection.isSelected(row);
  }
}
