import { Component } from '@angular/core';
import { Machine } from '../models/machine';
import { ModelCrudService } from '../services/model-crud.service';
import { UserCrudService } from '../services/user-crud.service';
import { User } from '../models/user';
import { Model } from '../models/model';
import { MachineCrudService } from '../services/machine-crud.service';
import { AlertService } from '../services/alert.service';
import { GenericListComponent } from '../generic-list/generic-list.component';
import { ModelPipe } from '../pipes/model.pipe';
import { UserPipe } from '../pipes/user.pipe';

@Component({
  selector: 'app-machine-list',
  templateUrl: './machine-list.component.html',
  styleUrls: ['./machine-list.component.scss']
})
export class MachineListComponent extends GenericListComponent<Machine, number> {
  prop: 'id_machine'
  listModels: Model[];
  listUsers: User[];
  displayedColumns: string[] = ['md5_uid', 'serial_number', 'name', 'description', 'model', 'isonline', 'connectionType', 'owner','isenabled', 'date_status', 'battery_level'];

  constructor(
    protected modelService: ModelCrudService,
    protected userService: UserCrudService,
    protected machineService: MachineCrudService,
    protected alertService: AlertService
  ) {
    super(alertService, machineService)
  }

  ngOnInit() {
    this.modelService.listObservable.subscribe(
      (data: any[]) => {
        this.listModels = data;
      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )

    this.userService.listObservable.subscribe(
      (data: any[]) => {
        this.listUsers = data;
      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )
    this.prop = 'id_machine';
    super.ngOnInit();
  }

  getOnlineColor(isonline: number) {
    return Machine.getColor(isonline);
  }

  getStatusIcon(isonline: number) {
    return Machine.statusIcon(isonline);
  }

  setCustomDataAccessor(){
    let model = new ModelPipe();
    let user = new UserPipe();
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'model':
        return model.transform(this.listModels,item.id_model)?.name;
        case 'owner':
        return user.transform(this.listUsers,item.id_owner)?.name;
        default:
        return item[property];
      }
    };
  }

  setFilterPredicate(){
    let model = new ModelPipe();
    let user = new UserPipe();
    this.dataSource.filterPredicate =
    (data: any, filter: string) => !filter || model.transform(this.listModels,data.id_model)?.name.toLowerCase().includes(filter)|| user.transform(this.listUsers,data.id_owner)?.name.toLowerCase().includes(filter) || data.serial_number.includes(filter) || data.name?.toLowerCase().includes(filter) || data.description?.toLowerCase().includes(filter)  ;
  }
}
