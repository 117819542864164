import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable()
export class LoaderService {
  private counter: number = 0;
  isLoading = new Subject<number>();

  constructor() {
  }

  show() {
    this.counter++;
    this.isLoading.next(this.counter);
  }
  hide() {
    this.counter--;
    this.isLoading.next(this.counter);
  }
}