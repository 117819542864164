export const environment = {
  production: false,
  env_name: "Socage",
  apiBaseUrl: "/api",
  appVersion: require('../../package.json').version,
  IDNODELIST: [
    { value: "AQ==", order: 10, label: '01', val: 1, color: 'primary', labelHex: "0x601", valComp: 1537 },
    { value: "Ag==", order: 20, label: '02', val: 2, color: 'green', labelHex: "0x602", valComp: 1538 },
    { value: "Aw==", order: 30, label: '03', val: 3, color: 'lime', labelHex: "0x603", valComp: 1539 },
    { value: "BA==", order: 40, label: '04', val: 4, color: 'light-blue', labelHex: "0x604", valComp: 1540 },
    { value: "BQ==", order: 50, label: '05', val: 5, color: 'teal', labelHex: "0x605", valComp: 1541 },
    { value: "Bg==", order: 60, label: '06', val: 6, color: 'orange', labelHex: "0x606", valComp: 1542 },
    { value: "Bw==", order: 70, label: '07', val: 7, color: 'light-blue-grey', labelHex: "0x607", valComp: 1543 },
    { value: "CA==", order: 80, label: '08', val: 8, color: 'purple', labelHex: "0x608", valComp: 1544 },
    { value: "CQ==", order: 90, label: '09', val: 9, color: 'brown', labelHex: "0x609", valComp: 1545 },
    { value: "Cg==", order: 100, label: '0A', val: 10, color: 'dark-blue-grey', labelHex: "0x60A", valComp: 1546 },
    { value: "Cw==", order: 110, label: '0B', val: 11, color: 'grey', labelHex: "0x60B", valComp: 1547 },
  ],
  TELEMETRYGROUPLIST: [
    { value: 0, order: 10, label: "Telemetry", color: 'primary' },
    { value: 1, order: 20, label: "Activity", color: 'green' },
  ],
  ENVS: [
    { name: "Socage", title: "Socage IoT 4G", conn_string: "Socage", logo: "../../assets/images/socage-logo.jpeg", mini_logo: "../../assets/images/socage-mini-logo.png", url: "https://www.socageiotportal-diasteca.com/", active: false },
    { name: "GSR", title: "GSR IoT 4G", conn_string: "GSR", logo: "../../assets/images/gsr-logo.png", mini_logo: "../../assets/images/gsr-logo.png", url: "http://www.gsriotportal-diasteca.com/", active: false },
    { name: "Kimera", title: "Kimera IoT 4G", conn_string: "Kimera", logo: "../../assets/images/kimera-logo.png", mini_logo: "../../assets/images/kimera-logo.png", url: "http://www.kimeraiotportal-diasteca.com/", active: false },
    { name: "Diasteca", title: "Diasteca IoT 4G", conn_string: "Diasteca", logo: "../../assets/images/diasteca-logo.png", mini_logo: "../../assets/images/diasteca-logo.png", url: "http://www.diastecaiotportal-diasteca.com/", active: false },
    { name: "Stage", title: "Diasteca Stage IoT 4G", conn_string: "Diastaging", logo: "../../assets/images/logo-microsystems.svg", mini_logo: "../../assets/images/logo-micro-tab.svg", url: "http://www.stageiotportal.com/", active: true }
  ]
};
