import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Param } from '../models/param';

@Injectable({
  providedIn: 'root'
})
export class SelectedParameterService {
  paramSub = new BehaviorSubject<Param>(undefined);
  paramCurrent = this.paramSub.asObservable()

  constructor() { }

  setParam(param: Param) {
    this.paramSub.next(param);
  }

  unsetParam() {
    this.paramSub.next(undefined);
  }
}