import { Component } from '@angular/core';
import { UserTypeCrudService } from '../services/user-type-crud.service';
import { AlertService } from '../services/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { GenericCrudComponent } from '../generic-crud/generic-crud.component';
import { UserType } from '../models/userType';
import { UserTypeSettingsDialogComponent } from '../user-type-settings-dialog/user-type-settings-dialog.component';
import { Router } from '@angular/router';
import { SelectedUserTypeService } from '../services/selected-user-type.service';

@Component({
  selector: 'app-user-type-settings',
  templateUrl: './user-type-settings.component.html'
})
export class UserTypeSettingsComponent extends GenericCrudComponent<UserType, number> {
  displayedColumns: string[] = ['editing', 'id_usertype', 'description', 'pView', 'pEdit', 'pFull', 'isAdmin', 'user_lvl', 'date_del', 'date_upd'];

  constructor(
    protected crudService: UserTypeCrudService,
    protected alertService: AlertService,
    protected router: Router,
    protected selectedUserType: SelectedUserTypeService,
    public dialog: MatDialog
  ) {
    super(crudService, alertService, dialog)
  }

  showUser(row: UserType) {
    this.selectedUserType.setRole(row);
    this.router.navigate([`role-details`]);
  }

  more(row: UserType): void {
    throw new Error("Method not implemented.");
  }

  edit(row: UserType): void {
    this.showEditDialog(UserTypeSettingsDialogComponent, row);
  }
  showAddDialog(): void {
    this.showEditDialog(UserTypeSettingsDialogComponent, null);
  }
}
