import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SelectedParameterService } from '../services/selected-parameter.service';

@Injectable({
  providedIn: 'root'
})
export class ParameterGuard implements CanActivate {
  constructor(
    private router: Router,
    private selectedParameter: SelectedParameterService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.selectedParameter.paramSub.value) {
      // authorised so return true
      return true;
    }
    this.router.navigate(['/parameters'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
