import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { AlertService } from '../services/alert.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GenericSettingsDialogComponent } from '../generic-settings-dialog/generic-settings-dialog.component';
import { HexStringPipe } from '../pipes/hexstring.pipe';
import { CustomCommand } from '../models/customCommand';
import { CustomdCommandCrudService } from '../services/custom-command-crud.service';

@Component({
  selector: 'app-custom-command-settings-dialog',
  templateUrl: './custom-command-settings-dialog.component.html',
  providers: [HexStringPipe]
})
export class CustomCommandSettingsDialogComponent extends GenericSettingsDialogComponent<CustomCommand, number> {
  
  constructor(
    protected commandService: CustomdCommandCrudService,
    protected alertService: AlertService,
    public dialogRef: MatDialogRef<CustomCommandSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected fb: FormBuilder,
    private hexStringPipe: HexStringPipe
  ) {
    super(commandService, alertService, dialogRef, data, fb)
  }

  ngOnInit() {
    super.ngOnInit();
    this.title = this.isNew ? "Create new command" : "Modify command";
    this.form = this.fb.group({
      command_nameCtrl: [this.isNew ? '' : this.entity.name, Validators.compose([Validators.required, (c) => this.newCommandValidator(c, this.entityList, this.entity)])],
      command_can_sub_indexCtrl: this.isNew ? '' : this.hexStringPipe.transform(this.entity.can_subindex),
      command_can_indexCtrl: this.isNew ? '' : this.hexStringPipe.transform(this.entity.can_index),
      command_idnodeCtrl: this.isNew ? '' : this.hexStringPipe.transform(this.entity.id_node).substring(1,4),
      command_can_valueCtrl: this.isNew ? '' : this.hexStringPipe.transform(this.entity.can_value),
      command_can_commandCtrl: this.isNew ? '' : this.hexStringPipe.transform(this.entity.can_command)
    });
  }

  update(command: CustomCommand) {

    command.name = this.form.get('command_nameCtrl').value;
    command.can_subindex = this.hexStringPipe.untransform(this.form.get('command_can_sub_indexCtrl').value),
    command.can_index = this.hexStringPipe.untransform(this.form.get('command_can_indexCtrl').value),
    command.id_node = this.hexStringPipe.untransform("0"+this.form.get('command_idnodeCtrl').value),
    command.can_value = this.hexStringPipe.untransform(this.form.get('command_can_valueCtrl').value),
    command.can_command = this.hexStringPipe.untransform(this.form.get('command_can_commandCtrl').value),
    command.id = command.id_custom_command;

    this.commandService.update(command)
      .subscribe(
        (data) => {
          this.alertService.info("Command has been successfully updated");
          this.dialogRef.close(true);
        },
        error => {
          console.log(error)
          this.alertService.error(error.error);
        }
      );
  }

  add() {
    var command: CustomCommand = {
      id: undefined,
      id_custom_command: undefined,
      name: this.form.get('command_nameCtrl').value,
      can_subindex: this.hexStringPipe.untransform(this.form.get('command_can_sub_indexCtrl').value),
      can_index: this.hexStringPipe.untransform(this.form.get('command_can_indexCtrl').value),
      id_node: this.hexStringPipe.untransform("0"+this.form.get('command_idnodeCtrl').value),
      can_value: this.hexStringPipe.untransform(this.form.get('command_can_valueCtrl').value),
      can_command: this.hexStringPipe.untransform(this.form.get('command_can_commandCtrl').value)
    };
    this.commandService.create(command)
      .subscribe(
        (data) => {
          this.alertService.info("Command has been successfully added");
          this.dialogRef.close(true);
        },
        error => {
          console.log(error)
          this.alertService.error(error.error);
        }
      );
  }

  delete() {
    this.commandService.delete(this.entity.id_custom_command)
      .subscribe(
        (data) => {
          this.alertService.info("Command has been successfully deleted");
          this.dialogRef.close(true);
        },
        error => {
          console.log(error)
          this.alertService.error(error.error);
        }
      );
  }

  newCommandValidator(c: AbstractControl, listCommand: CustomCommand[], command: CustomCommand): { [s: string]: boolean } {
    var currentIdx = -2;
    if (listCommand.length > 0) {
      var index = listCommand.map(function (d) { return d.name; }).indexOf(c.value)
      if (command) {
        currentIdx = listCommand.map(function (d) { return d.name }).indexOf(command.name);
      }
      if (index >= 0 && index != currentIdx) {
        return { nameJustInUse: index >= 0 };
      }
    }
  }
}