import { Pipe, PipeTransform } from '@angular/core';

import { Field } from '../models/field';

@Pipe({
  name: 'param'
})
export class ParamPipe implements PipeTransform {

  transform(list: Field[], value: string): any {
    return list.filter(
      (field) => {
        return field.parameter.name.indexOf(value.toUpperCase()) > -1 || field.parameter.address.indexOf(value.toUpperCase()) > -1;
      });
  }
}