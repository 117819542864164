import { Component } from '@angular/core';
import { FirmwareIot } from '../models/firmwareIot';
import { GenericCrudComponent } from '../generic-crud/generic-crud.component';
import { FirmwareIotCrudService } from '../services/firmware-iot-crud.service';
import { AlertService } from '../services/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { FirmwareIotSettingsDialogComponent } from '../firmware-iot-settings-dialog/firmware-iot-settings-dialog.component';
import { saveAs } from 'file-saver';
import { MachineSettingsComponent } from '../machine-settings/machine-settings.component';
import { MachineListComponent } from '../machine-list/machine-list.component';
import { Router } from '@angular/router';
import { SelectedFirmwareIotService } from '../services/selected-firmware-iot.service';

@Component({
  selector: 'app-firmware-iot-settings',
  templateUrl: './firmware-iot-settings.component.html'
})
export class FirmwareIotSettingsComponent extends GenericCrudComponent<FirmwareIot,number> {
  http: any;

  constructor(
    protected crudService:FirmwareIotCrudService,
    protected alertService: AlertService,
    private sel: SelectedFirmwareIotService,
    public dialog: MatDialog,
    protected router: Router
    )
    {
      super(crudService,alertService,dialog)
    }

  displayedColumns: string[] = ['select','id_fw','name_fw','description_fw','connectionType','release_date_fw'];

  more(row: FirmwareIot): void {
    throw new Error("Method not implemented.");
  }

   showAddDialog(){
      this.showEditDialog(FirmwareIotSettingsDialogComponent,null,800);
    }

    edit(row:FirmwareIot)
    {
      this.showEditDialog(FirmwareIotSettingsDialogComponent,row,800);
    }

    download(row:FirmwareIot){
      this.crudService.download(row.id_fw).subscribe(data => this.downloadFile(data,row.name_fw)),//console.log(data),
      error => console.log('Error downloading the file.'),
      () => console.info('OK');
    }

    downloadFile(data: Response,name_fw:string) {

      saveAs(data,name_fw);

    }

    updateSw(row:FirmwareIot){
      super.more(row);
      this.sel.setFirmware(row);
      this.router.navigate([`firmware-iot-update`]);
    }
}
