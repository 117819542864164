import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IModelObj } from '../models/iModelObj';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';

@Injectable()
export abstract class IEntityListService<Gen extends IModelObj> {
    endpoint: string;

    constructor(http: HttpClient,
        appConfigService: AppConfigService) {
        this.endpoint = appConfigService.apiBaseUrl;
    }

    abstract getEntityList(idobj: number): Observable<Gen[]>;
    abstract postEntityList(idobj: number, listRelatedEntuty: Gen[]): Observable<any>;
}