import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { AlertService } from '../services/alert.service';
import { UserLogged } from '../models/user-logged';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { ChangeEnvComponent } from '../change-env/change-env.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  LoggedUser: Observable<UserLogged>;
  loading = false;
  submitted = false;
  username: string;
  password: string;
  returnUrl: string;

  public logo = environment.ENVS.find(x => x.name == environment.env_name).logo;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private dialog: MatDialog
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  onSubmit() {
    this.submitted = true;
    this.loading = true;
    this.authenticationService.login(this.username, this.password)
      .pipe(first())
      .subscribe(
        data => {
          this.router.navigate([this.returnUrl]);
        },
        error => {
          console.log(error.error.message);
          this.alertService.error(error.error.message);
          this.loading = false;
        });
  }

  changeEnv() {
    const dialogRef = this.dialog.open(ChangeEnvComponent,
      {
        width: '400px',
      });
  }
}
