import { MenuVoice } from "./menu-voice";

export const MENU: MenuVoice[] = [
  { id: 10, order: 10, label: 'Labels', link: "labels" },
  { id: 20, order: 20, label: 'Machines', link: "machines" },
  { id: 30, order: 30, label: 'Models', link: "models" },
  { id: 40, order: 40, label: 'Parameters', link: "parameters" },
  { id: 50, order: 50, label: 'Commands', link: "commands" },
  { id: 55, order: 55, label: 'Custom commands', link: "custom-commands" },
  { id: 60, order: 60, label: 'Telemetry groups', link: "telemetry-group" },
  { id: 70, order: 70, label: 'Roles', link: "roles" },
  { id: 80, order: 80, label: 'Users', link: "users" },
  { id: 90, order: 90, label: 'Firmware IOT', link: "firmware-iot" },
  { id: 100, order: 100, label: 'Firmware', link: "firmware" },
];