import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { CommandCrudService } from '../services/command-crud.service';
import { AlertService } from '../services/alert.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Command } from '../models/command';
import { COMMANDTYPELIST } from '../models/mock-string-list';
import { GenericSettingsDialogComponent } from '../generic-settings-dialog/generic-settings-dialog.component';
import { HexStringPipe } from '../pipes/hexstring.pipe';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-command-settings-dialog',
  templateUrl: './command-settings-dialog.component.html',
  providers: [HexStringPipe]
})
export class CommandSettingsDialogComponent extends GenericSettingsDialogComponent<Command, number> {
  commandTypeList = COMMANDTYPELIST;
  public idNodeList = environment.IDNODELIST;

  constructor(
    protected commandService: CommandCrudService,
    protected alertService: AlertService,
    public dialogRef: MatDialogRef<CommandSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected fb: FormBuilder,
    private hexStringPipe: HexStringPipe
  ) {
    super(commandService, alertService, dialogRef, data, fb)
  }

  ngOnInit() {
    super.ngOnInit();
    this.title = this.isNew ? "Create new command" : "Modify command";
    this.form = this.fb.group({
      command_nameCtrl: [this.isNew ? '' : this.entity.name, Validators.compose([Validators.required, (c) => this.newCommandValidator(c, this.entityList, this.entity)])],
      command_can_sub_indexCtrl: this.isNew ? '' : this.hexStringPipe.transform(this.entity.can_sub_index),
      command_can_indexCtrl: this.isNew ? '' : this.hexStringPipe.transform(this.entity.can_index),
      command_idnodeCtrl: this.isNew ? '' : this.entity.idnode,
      command_allModelCtrl: this.isNew ? false : this.entity.default_enable > 0,
    });
  }

  update(command: Command) {

    command.name = this.form.get('command_nameCtrl').value;
    command.can_sub_index = this.hexStringPipe.untransform(this.form.get('command_can_sub_indexCtrl').value),
      command.can_index = this.hexStringPipe.untransform(this.form.get('command_can_indexCtrl').value),
      command.idnode = this.form.get('command_idnodeCtrl').value,
      command.default_enable = (this.form.get('command_allModelCtrl').value) ? 1 : 0
    command.id = command.id_cmd;

    this.commandService.update(this.entity)
      .subscribe(
        (data) => {
          this.alertService.info("Command has been successfully updated");
          this.dialogRef.close(true);
        },
        error => {
          console.log(error)
          this.alertService.error(error.error);
        }
      );
  }

  add() {
    var command: Command = {
      id: undefined,
      id_cmd: undefined,
      name: this.form.get('command_nameCtrl').value,
      can_sub_index: this.hexStringPipe.untransform(this.form.get('command_can_sub_indexCtrl').value),
      can_index: this.hexStringPipe.untransform(this.form.get('command_can_indexCtrl').value),
      idnode: this.form.get('command_idnodeCtrl').value,
      default_enable: (this.form.get('command_allModelCtrl').value) ? 1 : 0
    };
    this.commandService.create(command)
      .subscribe(
        (data) => {
          this.alertService.info("Command has been successfully added");
          this.dialogRef.close(true);
        },
        error => {
          console.log(error)
          this.alertService.error(error.error);
        }
      );
  }

  delete() {
    this.commandService.delete(this.entity.id_cmd)
      .subscribe(
        (data) => {
          this.alertService.info("Command has been successfully deleted");
          this.dialogRef.close(true);
        },
        error => {
          console.log(error)
          this.alertService.error(error.error);
        }
      );
  }

  newCommandValidator(c: AbstractControl, listCommand: Command[], command: Command): { [s: string]: boolean } {
    var currentIdx = -2;
    if (listCommand.length > 0) {
      var index = listCommand.map(function (d) { return d.name; }).indexOf(c.value)
      if (command) {
        currentIdx = listCommand.map(function (d) { return d.name }).indexOf(command.name);
      }
      if (index >= 0 && index != currentIdx) {
        return { nameJustInUse: index >= 0 };
      }
    }
  }
}
