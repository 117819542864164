import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import * as signalR from '@aspnet/signalr';
import { MachineTestingComponent } from '../machine-testing/machine-testing.component';
import { AlertService } from '../services/alert.service';
import { ConnectionSignalRService } from '../services/connection-signal-r.service';
import { ModelCrudService } from '../services/model-crud.service';
import { SelectedMachineService } from '../services/selected-machine.service';
import { signalRInvocation, signalRSubscription } from '../models/signalRTopic';

@Component({
  selector: 'app-machine-general-settings',
  templateUrl: './machine-general-settings.component.html',
  styleUrls: ['./machine-general-settings.component.scss']
})
export class MachineGeneralSettingsComponent<int> extends MachineTestingComponent {
  @Output() back: EventEmitter<any> = new EventEmitter();

  protected first = true;
  public list: int[] = [];
  dataSource: MatTableDataSource<int>;
  public displayedColumns: string[];
  names = ["Key Time ON", "Accelerometer sensitivity", "Percentile ON threshold ANIN1"]
  dS = [];
  constructor(
    public selectedMachine: SelectedMachineService,
    public modelService: ModelCrudService,
    public connectionSignalR: ConnectionSignalRService,
    public alertService: AlertService
  ) {
    super(selectedMachine, modelService, alertService, connectionSignalR);
    this.displayedColumns = ["name","value"];
  }

  ngOnInit() {
  }

  subscribe() {

      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendPubGetGeneralSettings, (resp: string) => {
        this.list = JSON.parse(resp);
        this.dS = [];
        for(let i = 0; i< this.names.length ; i++)
        {
          this.dS.push(
            {
              name: this.names[i],
              value: this.list[i]
            }
          );
        }

        this.dataSource = new MatTableDataSource(this.dS);
      });
      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendPubSetGeneralSettings, (resp: string) => {
        this.alertService.info(resp);
      });

      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendPubGetBattery, (resp: string) => {
        this.alertService.info("Battery state: " + resp);
      });
  }

  getBattery(){
    this.subscribe();
    if (this.connectionSignalR.connectionSub.value.state == signalR.HubConnectionState.Connected) {
      this.connectionSignalR.connectionSub.value.invoke(signalRInvocation.getBattery, this.selectedMachine.machineSub.value.serial_number).then(
        () => {
          console.log(signalRInvocation.getBattery);
        }).catch((reason: any) => {
          if (reason != null) {
            console.log(reason.message);
            this.alertService.error(reason.message.split("Exception: ")[1]);
          }
        });
    }
  }

  getVal() {
    this.subscribe();
    if (this.connectionSignalR.connectionSub.value.state == signalR.HubConnectionState.Connected) {
      this.connectionSignalR.connectionSub.value.invoke(signalRInvocation.getGeneralSettings, this.selectedMachine.machineSub.value.serial_number).then(
        () => {
          console.log(signalRInvocation.getGeneralSettings);
        }).catch((reason: any) => {
          if (reason != null) {
            console.log(reason.message);
            this.alertService.error(reason.message.split("Exception: ")[1]);
          }
        });
    }
  }

  setVal() {
    this.subscribe();
    if (this.connectionSignalR.connectionSub.value.state == signalR.HubConnectionState.Connected) {
      let settings = [];
      this.dS.forEach((x) => {
        settings.push(x.value);
      });

      this.connectionSignalR.connectionSub.value.invoke(signalRInvocation.setGeneralSettings, this.selectedMachine.machineSub.value.serial_number, settings).then(
        () => {
          console.log("setGeneralSettings");
        }).catch((reason: any) => {
          if (reason != null) {
            console.log(reason.message);
            this.alertService.error(reason.message.split("Exception: ")[1]);
          }
        });
    }
  }
}
