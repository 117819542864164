export const signalRInvocation = {
    stopGetDataTelemetry: "stopGetDataTelemetry",
    setTelemetryTab: "setTelemetryTab",
    startGetDataTelemetry: "startGetDataTelemetry",
    removeConnTelemetry: "removeConnTelemetry",
    getLogError: "getLogError",
    getData: "getData",
    updateData: "updateData",
    checkConnection: "checkConnection",
    ping: "ping",
    stopAll: "stopAll",
    updateFirmware: "updateFirmware",
    updateFirmwareIot4G: "updateFirmwareIot4G",
    updateFirmwareBoard: "updateFirmwareBoard",
    updateFirmwareBoard4G: "updateFirmwareBoard4G",
    writeCmd: "writeCmd",
    setActivityTab: "setActivityTab",
    getActivity: "getActivity",
    getDigInputs: "getDigInputs",
    setDigInputs: "setDigInputs",
    getAnInputs: "getAnInputs",
    setAnInputs: "setAnInputs",
    getDigEventCounters: "getDigEventCounters",
    setDigEventCounters: "setDigEventCounters",
    resetDigCounters: "resetDigCounters",
    getDigHourCounters: "getDigHourCounters",
    getDigOutputs: "getDigOutputs",
    setDigOutputs: "setDigOutputs",
    getFwIOTVer: "getFwIOTVer",
    setIdNodeTable: "setIdNodeTable",
    getIdNodeTable: "getIdNodeTable",
    getEnv: "getEnv",
    setEnv: "setEnv",
    resetIot: "resetIot",
    getSensitivity:"getSensitivity",
    setSensitivity:"setSensitivity",
    getPeriodicReportPar:"getPeriodicReportPar",
    setPeriodicReportPar:"setPeriodicReportPar",
    getGeneralSettings:"getGeneralSettings",
    setGeneralSettings:"setGeneralSettings",
    getBattery:"getBattery"
}

export const signalRSubscription = {
    sendGetDataTelemetry: "sendGetDataTelemetry",
    sendTelemetryTabData: "sendTelemetryTabData",
    sendGetData: "sendGetData",
    sendGenericError: "sendGenericError",
    sendUpdatedData: "sendUpdatedData",
    sendPubLogError: "sendPubLogError",
    connectionStatus: "connectionStatus",
    sendConnectionStatus: "sendConnectionStatus",
    sendAggFirmware: "sendAggFirmware",
    sendAggFirmwareIot4G: "sendAggFirmwareIot4G",
    sendAggFirmwareBoard: "sendAggFirmwareBoard",
    sendAggFirmwareEnd: "sendAggFirmwareEnd",
    sendAggFirmwareBoardEnd: "sendAggFirmwareBoardEnd",
    sendAggFirmwareNext: "sendAggFirmwareNext",
    sendAggFirmwareBoardNext: "sendAggFirmwareBoardNext",
    sendActivityTabData: "sendActivityTabData",
    sendGetDataActivity: "sendGetDataActivity",
    sendStopGetDataTelemetry: "sendStopGetDataTelemetry",
    sendPubGetDigInputs: "sendPubGetDigInputs",
    sendPubSetDigInputs: "sendPubSetDigInputs",
    sendPubGetAnInputs: "sendPubGetAnInputs",
    sendPubSetAnInputs: "sendPubSetAnInputs",
    sendPubGetDigEventCounters: "sendPubGetDigEventCounters",
    sendPubSetDigEventCounters: "sendPubSetDigEventCounters",
    sendPubGetDigHourCounters: "sendPubGetDigHourCounters",
    sendPubGetDigOutputs: "sendPubGetDigOutputs",
    sendPubSetDigOutputs: "sendPubSetDigOutputs",
    sendPubGetFwIOTVer: "sendPubGetFwIOTVer",
    sendPubSetIdNodeTable: "sendPubSetIdNodeTable",
    sendPubGetIdNodeTable: "sendPubGetIdNodeTable",
    sendAggFirmware4G: "sendAggFirmware4G",
    sendAggFirmwareBoard4G: "sendAggFirmwareBoard4G",
    sendPubUpdateFileS19: "sendPubUpdateFileS19",
    sendPubEnv: "sendPubEnv",
    sendPubResetIot: "sendPubResetIot",
    sendGetSensitivity: "sendGetSensitivity",
    sendSetSensitivity: "sendSetSensitivity",
    sendGetPeriodicReportPar: "sendGetPeriodicReportPar",
    sendSetPeriodicReportPar: "sendSetPeriodicReportPar",
    sendPubGetGeneralSettings:"sendPubGetGeneralSettings",
    sendPubSetGeneralSettings:"sendPubSetGeneralSettings",
    sendGetBattery:"sendGetBattery",
    sendPubGetBattery:"sendPubGetBattery"


}
