import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from './app-config.service';
import { IEntityListService } from './ientity-list.service';
import { Machine } from '../models/machine';
import { FirmwareIot } from '../models/firmwareIot';
import { FirmwareIotInstallation } from '../models/firmwareIotInstallation';

@Injectable({
  providedIn: 'root'
})
export class FirmwareToMachineService extends IEntityListService<Machine>{
  protected apiName: string = "firmwareIotInstallation"

  constructor(protected http: HttpClient,
    appConfigService: AppConfigService) {
    super(http, appConfigService);
  }

  getEntityList(idobj: number): Observable<Machine[]> {
    return this.http.get<Machine[]>(`${this.endpoint}/${this.apiName}/firmware=${idobj}/machines`);
  }
  postEntityList(idobj: number, listRelatedEntity: Machine[]): Observable<any> {
    return this.http.post<any>(`${this.endpoint}/${this.apiName}/firmware=${idobj}`,  listRelatedEntity.map(x=>x.id_machine));
  }
  getFirmwareIotByStatusList(idobj: number, status: number): Observable<FirmwareIotInstallation[]> {
    return this.http.get<FirmwareIotInstallation[]>(`${this.endpoint}/${this.apiName}/firmware=${idobj}/status=${status}`);
  }

  getFirmwareIotListDate(dateStart:Date): Observable<FirmwareIotInstallation[]> {
    return this.http.get<FirmwareIotInstallation[]>(`${this.endpoint}/${this.apiName}/dateStart=${dateStart.toISOString()}`);
  }

  getFirmwareIotListDateOld(dateStart:Date): Observable<FirmwareIotInstallation[]> {
    return this.http.get<FirmwareIotInstallation[]>(`${this.endpoint}/${this.apiName}/dateStart=${dateStart.toISOString()}/old`);
  }


}
