import { Component, Inject, OnInit } from '@angular/core';
import { Command } from '../models/command';
import { AlertService } from '../services/alert.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StartCommand } from '../models/startCommand';
import { SelectedMachineService } from '../services/selected-machine.service';
import { StartCommandCrudService } from '../services/start-command-crud.service';
import { HexStringPipe } from '../pipes/hexstring.pipe';

@Component({
  selector: 'app-start-command-dialog',
  templateUrl: './start-command-dialog.component.html',
  styleUrls: ['./start-command-dialog.component.scss'],
  providers: [HexStringPipe]
})
export class StartCommandDialogComponent implements OnInit {

    
    listCommands: Command[];
    listStartCommand: StartCommand[];
    form: FormGroup;
    title: string;
  
    constructor(
      public selectedMachine: SelectedMachineService,
      public startCommandService: StartCommandCrudService,
      protected alertService: AlertService,
      public dialogRef: MatDialogRef<StartCommandDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      protected fb: FormBuilder,
      private hexStringPipe: HexStringPipe
    ) {
    }
  
    ngOnInit() {
      this.listCommands = this.data.listCommands;
      this.listStartCommand = this.data.listStartCommand;
      this.title = "Create new start command";
      this.form = this.fb.group({
        startCommand_cmdCtrl: [ '' , Validators.required],
        startCommand_canValueCtrl: ['' ],
      });
      
    }
  
    add() {
      var startCmd: StartCommand = {
        id: undefined,
        id_start_command: undefined,
        id_cmd: Number(this.form.get('startCommand_cmdCtrl').value),
        can_value: this.hexStringPipe.untransform(this.form.get('startCommand_canValueCtrl').value),
        date_insert: new Date(),
        date_applied: null,
        id_machine: this.selectedMachine.machineSub.value.id_machine,
        status: 0
      };
      this.startCommandService.create(startCmd)
        .subscribe(
          (data) => {
            this.alertService.info("Start command group has been successfully added");
            this.dialogRef.close(true);
          },
          error => {
            console.log(error)
            this.alertService.error(error.error);
          }
        );
    }
  

}
