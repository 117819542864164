import { Component } from '@angular/core';
import { ParameterCrudService } from '../services/parameter-crud.service';
import { GenericCrudComponent } from '../generic-crud/generic-crud.component';
import { AlertService } from '../services/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { Param } from '../models/param';
import { ParameterSettingsDialogComponent } from '../parameter-settings-dialog/parameter-settings-dialog.component';
import { SelectedParameterService } from '../services/selected-parameter.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-parameter-settings',
  templateUrl: './parameter-settings.component.html'
})

export class ParameterSettingsComponent extends GenericCrudComponent<Param, number> {
  displayedColumns: string[] = ['editing', 'id_param', 'name', 'type', 'address', 'idnode', 'update', 'sdo_segmented', 'applymask', 'min', 'max', 'all_models', 'date_upd', 'date_del'];

  constructor(
    protected crudService: ParameterCrudService,
    protected alertService: AlertService,
    protected selectedParam: SelectedParameterService,
    protected router: Router,
    public dialog: MatDialog
  ) {
    super(crudService, alertService, dialog)
    this.filterString = "paramFilter";
  }

  more(row: Param) {
    super.more(row);
    this.selectedParam.setParam(row);
    this.router.navigate([`parameter-details`]);

  }

  edit(row: Param): void {
    this.showEditDialog(ParameterSettingsDialogComponent, row);
  }
  showAddDialog(): void {
    this.showEditDialog(ParameterSettingsDialogComponent, null);
  }
}
