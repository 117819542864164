import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as signalR from '@aspnet/signalr';

@Injectable({
  providedIn: 'root'
})
export class ConnectionSignalRService {
  connectionSub = new BehaviorSubject<signalR.HubConnection>(undefined);
  connectionCurrent = this.connectionSub.asObservable()

  constructor() { }

  setConnection(conn: signalR.HubConnection) {
    this.connectionSub.next(conn);
  }

  unsetConnection() {
    this.connectionSub.next(undefined);
  }
}