import { IModelObj } from "./iModelObj";

export interface ICustomCommand {
    id_custom_command: number;
    name: string;
    can_index: string;
    can_subindex: string;
    id_node: string;
    can_value: string;
    can_command:string;
}

export class CustomCommand extends IModelObj implements ICustomCommand {
    id: string | number;
    id_custom_command: number;
    name: string;
    can_index: string;
    can_subindex: string;
    can_command:string;
    id_node: string;
    can_value: string;

    constructor(props: CustomCommand) {
        super();
        Object.keys(props).forEach(prop => {
            const value = props[prop];
            this[prop] = value;
        });
        this.id = props.id_custom_command;
    }
}

export class CustomCommandVal {
    command: CustomCommand;
    delay: number;
}