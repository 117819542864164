import { SimInfoDialogComponent } from './../sim-info-dialog/sim-info-dialog.component';
import { Component } from '@angular/core';
import { GenericCrudComponent } from '../generic-crud/generic-crud.component';
import { AlertService } from '../services/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { MachineCrudService } from '../services/machine-crud.service';
import { Machine } from '../models/machine';
import { ModelCrudService } from '../services/model-crud.service';
import { Model } from '../models/model';
import { User } from '../models/user';
import { UserCrudService } from '../services/user-crud.service';
import { MachineSettingsDialogComponent } from '../machine-settings-dialog/machine-settings-dialog.component';
import { Router } from '@angular/router';
import { SelectedMachineService } from '../services/selected-machine.service';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { ModelPipe } from '../pipes/model.pipe';
import { UserPipe } from '../pipes/user.pipe';

@Component({
  selector: 'app-machine-settings',
  templateUrl: './machine-settings.component.html',
  styleUrls: ['./machine-settings.component.scss'],
  providers: [DatePipe]
})
export class MachineSettingsComponent extends GenericCrudComponent<Machine, number> {
  listModels: Model[];
  listUsers: User[];
  displayedColumns: string[] = ['editing', 'md5_uid', 'serial_number', 'name', 'description', 'model', 'isonline', 'conn_version','isenabled', 'owner', 'date_status', 'date_auto', 'battery_level'];

  constructor(
    protected crudService: MachineCrudService,
    protected modelService: ModelCrudService,
    protected userService: UserCrudService,
    protected alertService: AlertService,
    protected router: Router,
    protected selectedMachine: SelectedMachineService,
    public dialog: MatDialog,
    protected http: HttpClient,
    protected datePipe: DatePipe
  ) {
    super(crudService, alertService, dialog)
  }

  ngOnInit() {
    this.filterString = 'machineFilter';
    this.modelService.listObservable.subscribe(
      (data: any[]) => {
        this.listModels = data;
      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )
    this.userService.listObservable.subscribe(
      (data: any[]) => {
        this.listUsers = data;

      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )

    super.ngOnInit();
  }

  more(row: Machine) {
    super.more(row);
    this.selectedMachine.setMachine(row);
    this.router.navigate([`machine-details`]);
  }

  edit(row: Machine): void {
    this.showEditDialog(MachineSettingsDialogComponent, row);
  }
  showAddDialog(): void {
    this.showEditDialog(MachineSettingsDialogComponent, null);
  }

  showAutoLoadIcon(row: Machine): boolean {
    if (row.id_model != null) {
      var model = this.listModels.find(x => x.id_model == row.id_model);
      if (model != undefined && model.date_auto != null && (row.date_auto == null || row.date_auto < model.date_auto)) {
        return true
      }
    }
    return false;
  }

  test(row: Machine) {
    super.more(row);
    this.selectedMachine.setMachine(row);
    this.router.navigate([`machine-test`]);
  }

  info(row: Machine) {
    this.crudService.getSimStatus(row.serial_number).subscribe(
      (data) => {
        this.alertService.info("Sim activation date: " + this.datePipe.transform(data, 'dd/MM/yyyy'))
      },
      error => {
        console.log(error)
        this.alertService.error(error.message);
      });
  }
  infoSim(row: Machine) {
    const dialogRef = this.dialog.open(SimInfoDialogComponent, {
      width: '600px',
      data: {
        serial_number: row.serial_number
      }
    });
  }

  setCustomDataAccessor(){
    let model = new ModelPipe();
    let user = new UserPipe();
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'model':
        return model.transform(this.listModels,item.id_model)?.name;
        case 'owner':
        return user.transform(this.listUsers,item.id_owner)?.name;
        default:
        return item[property];
      }
    };
  }

  setFilterPredicate(){
    let model = new ModelPipe();
    let user = new UserPipe();
    this.dataSource.filterPredicate =
    (data: any, filter: string) => !filter || model.transform(this.listModels,data.id_model)?.name.toLowerCase().includes(filter)|| user.transform(this.listUsers,data.id_owner)?.name.toLowerCase().includes(filter) || data.serial_number.includes(filter) || data.name?.toLowerCase().includes(filter) || data.description?.toLowerCase().includes(filter)  ;
  }

  getColore(isonline: number) {
    return Machine.getColor(isonline);
  }

  getStatusIcon(isonline: number) {
    return Machine.statusIcon(isonline);
  }
}

