import { Component, Inject } from '@angular/core';
import { AlertService } from '../services/alert.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivitySingleRecCrudService } from '../services/activity-single-rec-crud.service';
import { IOSingleReport } from '../models/ioreport';
import { IOSingleRecCrudService } from '../services/io-single-rec-crud.service';


@Component({
  selector: 'app-report-io-list-dialog',
  templateUrl: './report-io-list-dialog.component.html'
})
export class ReportIoListDialogComponent  {

  dataSource: IOSingleReport;
  title: string;
  date: Date;
  type: string;

  constructor(
    protected ioSingleRecService:IOSingleRecCrudService,
    protected alertService: AlertService,
    public dialogRef: MatDialogRef<ReportIoListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    this.ioSingleRecService.getIOSingleRec(this.data.id).subscribe(
      (data) => {
        this.dataSource = data;
        this.date = data.date;
        if(data.type == 1){
          this.type = "Periodic";
        } else if(data.type == 2){
          this.type = "Alarm";
        } else if(data.type == 0){
          this.type = "Turn On";
        } else if(data.type == 1000){
          this.type = "Turn Off";
        }

      },
      error => {
        console.log(error)
        this.alertService.error(error.error);
      }
    );

    this.title ="IO Records";
  }
}
