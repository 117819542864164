import { Injectable } from '@angular/core';
import { IEntityListService } from './ientity-list.service';
import { Model } from '../models/model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class CommandModelService extends IEntityListService<Model>{
  protected apiName: string = "modelCommands";

  constructor(protected http: HttpClient,
    appConfigService: AppConfigService) {
    super(http, appConfigService);
  }

  getEntityList(idobj: number): Observable<Model[]> {
    return this.http.get<Model[]>(`${this.endpoint}/${this.apiName}/id_cmd=${idobj}/models`);
  }
  postEntityList(idobj: number, listRelatedEntity: Model[]): Observable<any> {
    return this.http.post<any>(`${this.endpoint}/${this.apiName}/id_cmd=${idobj}`, listRelatedEntity.map(x=>x.id_model));
  }
}