import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Machine } from '../models/machine';
import { AppConfigService } from './app-config.service';
import { HttpClient } from '@angular/common/http';
import { IEntityListService } from './ientity-list.service';

@Injectable({
  providedIn: 'root'
})
export class UserToMachineCrudService extends IEntityListService<Machine>{
  protected apiName: string = "usersToMachines"

  constructor(protected http: HttpClient,
    appConfigService: AppConfigService) {
    super(http, appConfigService);
  }

  getEntityList(idobj: number): Observable<Machine[]> {
    return this.http.get<Machine[]>(`${this.endpoint}/${this.apiName}/userid=${idobj}/machines`);
  }
  postEntityList(idobj: number, listRelatedEntity: Machine[]): Observable<any> {
    return this.http.post<any>(`${this.endpoint}/${this.apiName}/userid=${idobj}`, listRelatedEntity.map(x=>x.id_machine));
  }
}