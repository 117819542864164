import { FirmwareToMachineService } from './../services/firmware-to-machine.service';
import { Component, EventEmitter, Output } from '@angular/core';
import { Machine } from '../models/machine';
import { ModelCrudService } from '../services/model-crud.service';
import { UserCrudService } from '../services/user-crud.service';
import { User } from '../models/user';
import { Model } from '../models/model';
import { MachineCrudService } from '../services/machine-crud.service';
import { AlertService } from '../services/alert.service';
import { GenericListComponent } from '../generic-list/generic-list.component';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { FirmwareIotInstallation } from '../models/firmwareIotInstallation';
import { ModelPipe } from '../pipes/model.pipe';
import { UserPipe } from '../pipes/user.pipe';

@Component({
  selector: 'app-machine-update',
  templateUrl: './machine-list.component.html',
  styleUrls: ['./machine-list.component.scss']
})
export class MachineUpdateComponent extends GenericListComponent<Machine, number> {
  prop: 'id_machine'
  listModels: Model[];
  listUsers: User[];
  displayedColumns: string[] = ['select-installation','md5_uid', 'serial_number', 'name', 'description', 'model', 'isonline', 'connectionType', 'owner','isenabled', 'date_status', 'battery_level'];
  @Output() closeDialogEvent = new EventEmitter<boolean>();
  mapIdMac = new Map();

  constructor(
    protected modelService: ModelCrudService,
    protected userService: UserCrudService,
    protected machineService: MachineCrudService,
    protected firmwareToMachineService: FirmwareToMachineService,
    protected alertService: AlertService
  ) {
    super(alertService, machineService)
    this.editing = true;
  }

  ngOnInit() {
    this.modelService.listObservable.subscribe(
      (data: any[]) => {
        this.listModels = data;
      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )

    this.userService.listObservable.subscribe(
      (data: any[]) => {
        this.listUsers = data;
      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )

    this.prop = 'id_machine';
    if (this.crudService != null) {
      this.crudService.listObservable.subscribe(
        (data: any[]) => {
          data.forEach(element => {
            if(element.conn_version == 4) {
              this.entityList.push(element);
            }
          });
          this.loadRelatedEntity();
        },
        error => console.log('error'),
        () => console.log('completeLoad')
      )
    }
    else {
      this.loadRelatedEntity();
    }
  }

  loadRelatedEntity() {
    this.IEntitytListService.getEntityList(this.idObj).subscribe(
      (data: Machine[]) => {
        this.filteredList = data;
        this.firmwareToMachineService.getFirmwareIotByStatusList(this.idObj,1).subscribe(
          (data: FirmwareIotInstallation[]) => {
            data.forEach(element => {
              this.mapIdMac.set(element.id_machine, element);
            });
            this.reloadTable();
          },
          error => console.log('error'),
          () => console.log('completeLoad')
        );

      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )
  }

  reloadTable() {
    if (this.filteredList != undefined && this.entityList != undefined) {

      var prop = this.prop;
      var temp = this.filteredList;
      this.filteredList = this.entityList.filter(entity =>
        temp.findIndex(function (tempEntity) {
          return tempEntity[prop] == entity[prop];
        }) > -1);

      this.dataSource = new MatTableDataSource(this.entityList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.selection = new SelectionModel<Machine>(true, this.filteredList);

      this.applyFilter(this.fil);
      this.setCustomDataAccessor();
    }
  }

  getOnlineColor(isonline: number) {
    return Machine.getColor(isonline);
  }

  getStatusIcon(isonline: number) {
    return Machine.statusIcon(isonline);
  }


  save() {
    var prop = this.prop;
    this.IEntitytListService.postEntityList(this.idObj, this.selection.selected).subscribe(
      () => {
        this.alertService.success('List of machines to install the firmware has been successfully updated');
        //this.closeDialogEvent.emit(true);
      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )
  }

  edit(modify: boolean) {
    this.closeDialogEvent.emit(true);
    super.edit(modify);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  isDisabled(row: Machine) {
    return this.mapIdMac.get(row.id_machine) != null;
  }

  setCustomDataAccessor(){
    let model = new ModelPipe();
    let user = new UserPipe();
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'model':
        return model.transform(this.listModels,item.id_model)?.name;
        case 'owner':
        return user.transform(this.listUsers,item.id_owner)?.name;
        default:
        return item[property];
      }
    };
  }

  setFilterPredicate(){
    let model = new ModelPipe();
    let user = new UserPipe();
    this.dataSource.filterPredicate =
    (data: any, filter: string) => !filter || model.transform(this.listModels,data.id_model)?.name.toLowerCase().includes(filter)|| user.transform(this.listUsers,data.id_owner)?.name.toLowerCase().includes(filter) || data.serial_number.includes(filter) || data.name?.toLowerCase().includes(filter) || data.description?.toLowerCase().includes(filter)  ;
  }
}
