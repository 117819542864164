import { Component } from '@angular/core';
import { ModelCrudService } from '../services/model-crud.service';
import { UserCrudService } from '../services/user-crud.service';
import { User } from '../models/user';
import { Model } from '../models/model';
import { AlertService } from '../services/alert.service';
import { GenericListComponent } from '../generic-list/generic-list.component';
import { UserPipe } from '../pipes/user.pipe';

@Component({
  selector: 'app-model-list',
  templateUrl: './model-list.component.html',
  styleUrls: ['./model-list.component.scss']
})
export class ModelListComponent extends GenericListComponent<Model, number> {
  prop: 'id_model';
  listUsers: User[];
  displayedColumns: string[] = ['id_model', 'name', 'maker', 'date_upd', 'second_periodic_report', 'date_del'];

  constructor(
    protected modelService: ModelCrudService,
    protected userService: UserCrudService,
    protected alertService: AlertService
  ) {
    super(alertService, modelService)
  }

  ngOnInit() {
    this.prop = 'id_model';
    super.ngOnInit();

    this.userService.listObservable.subscribe(
      (data: any[]) => {
        this.listUsers = data;
      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )
  }

  setCustomDataAccessor(){
    let user = new UserPipe();
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'maker':
        return user.transform(this.listUsers,item.id_maker)?.name;
        default:
        return item[property];
      }
    };
  }
}
