import { OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AlertService } from '../services/alert.service';
import { IModelObj } from '../models/iModelObj';
import { GenericCrudService } from '../services/generic-crud.service';

export abstract class GenericSettingsDialogComponent<Gen extends IModelObj,ID> implements OnInit {

  form: FormGroup;
  public isNew:boolean;
  public entity:Gen;
  public entityList :Gen[];
  public title:string;
  
  constructor(
    protected crudService : GenericCrudService<Gen,ID>,
    protected alertService: AlertService,
    public dialogRef: MatDialogRef<GenericSettingsDialogComponent<Gen,ID>>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected fb: FormBuilder
  ) { }

  ngOnInit() {
    this.entity = this.data.element;
    this.entityList = this.data.listElements;
    this.isNew = this.entity===null;
    this.title = this.isNew? "Create new entity":"Modify entity";
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  save(){
    if(this.isNew){
      this.add();
    }
    else{
      this.update(this.entity);
    }
  }

  abstract update(entity:Gen):void;

  abstract add():void;

  abstract delete():void;
  
}
