import { Component, Inject, ChangeDetectorRef } from '@angular/core';
import { GenericSettingsDialogComponent } from '../generic-settings-dialog/generic-settings-dialog.component';
import { Firmware } from '../models/firmware';
import { FirmwareCrudService } from '../services/firmware-crud.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertService } from '../services/alert.service';
import { ModelCrudService } from '../services/model-crud.service';
import { Model } from '../models/model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-firmware-settings-dialog',
  templateUrl: './firmware-settings-dialog.component.html'
})
export class FirmwareSettingsDialogComponent extends GenericSettingsDialogComponent<Firmware, number> {
  listModels: Model[];
  public idNodeList = environment.IDNODELIST;

  constructor(
    protected firmwareService: FirmwareCrudService,
    protected alertService: AlertService,
    public dialogRef: MatDialogRef<FirmwareSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected fb: FormBuilder,
    private ref: ChangeDetectorRef,
    protected modelService: ModelCrudService
  ) {
    super(firmwareService, alertService, dialogRef, data, fb);
  }

  ngOnInit() {
    super.ngOnInit();
    this.title = this.isNew ? "Create new firmware " : "Modify firmware";
    this.form = this.fb.group({
      firmware_idnodeCtrl: [this.isNew ? '' : this.entity.idnode, Validators.required],
      firmwarename_fwCtrl: this.isNew ? '' : this.data.element.name_fw,
      firmwaredescription_fwCtrl: this.isNew ? '' : this.data.element.description_fw,
      firmwareversion_fwCtrl: this.isNew ? '' : this.data.element.version_fw,
      firmwarebin_fwCtrl: [''],
    });

    this.modelService.listObservable.subscribe(
      (data: any[]) => {
        this.listModels = data;
      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )
  }

  update(f: Firmware) {
    f.id = f.id_fw;
    f.name_fw = this.form.get('firmwarename_fwCtrl').value;
    f.description_fw = this.form.get('firmwaredescription_fwCtrl').value;
    f.version_fw = this.form.get('firmwareversion_fwCtrl').value;
    f.idnode = (this.form.get('firmware_idnodeCtrl').value);

    var fw = this.form.get('firmwarebin_fwCtrl').value;
    if (fw != null) {
      var sub = fw.indexOf(";base64,");
      f.bin_fw = fw.substr(sub + 8);
    }
    else {
      f.bin_fw = undefined;
    }
    f.release_date_fw = null;

    this.firmwareService.update(f)
      .subscribe(
        (data) => {
          this.alertService.info("Firmware has been successfully updated");
          this.dialogRef.close(true);
        },
        error => {
          console.log(error)
          this.alertService.error(error.error);
        }
      );
  }

  onFileChange(event) {
    let reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.form.patchValue({
          firmwarebin_fwCtrl: reader.result
        });

        this.ref.markForCheck();
      };
    }
  }

  delete() {
    this.firmwareService.delete(this.entity.id_fw)
      .subscribe(x => {
        this.alertService.info('Firmware has been successfully deleted!');
        this.dialogRef.close(true);
      },
        error => {
          console.log(error)
          this.alertService.error(error.error);
        });
  }

  add() {
    var fw = this.form.get('firmwarebin_fwCtrl').value;
    if (fw == null || fw == '') {
      this.alertService.error('Please upload a file');
      return;
    }
    var sub = fw.indexOf(";base64,");

    var firmware: Firmware = {
      name_fw: this.form.get('firmwarename_fwCtrl').value,
      description_fw: this.form.get('firmwaredescription_fwCtrl').value,
      version_fw: this.form.get('firmwareversion_fwCtrl').value,
      idnode: (this.form.get('firmware_idnodeCtrl').value),
      release_date_fw: null,
      bin_fw: fw.substr(sub + 8),
      id: undefined,
      id_fw: undefined
    };

    this.firmwareService.create(firmware)
      .subscribe(
        (data) => {
          this.alertService.info("Firmware has been successfully added");
          this.dialogRef.close(true);
        },
        error => {
          console.log(error)
          this.alertService.error(error.error);
        }
      );
  }
}
