import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { User } from '../models/user';
import { UserCrudService } from '../services/user-crud.service';
import { AlertService } from '../services/alert.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LANGLIST } from '../models/mock-string-list';
import { UserType } from '../models/userType';
import { UserTypeCrudService } from '../services/user-type-crud.service';
import { GenericSettingsDialogComponent } from '../generic-settings-dialog/generic-settings-dialog.component';

export interface AbstractControlWarn extends AbstractControl { warnings: any; }

@Component({
  selector: 'app-user-settings-dialog',
  templateUrl: './user-settings-dialog.component.html'
})
export class UserSettingsDialogComponent extends GenericSettingsDialogComponent<User, number> {
  langList = LANGLIST;
  roleList: UserType[];

  constructor(
    protected userService: UserCrudService,
    protected userTypeService: UserTypeCrudService,
    protected alertService: AlertService,
    public dialogRef: MatDialogRef<UserSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected fb: FormBuilder
  ) {
    super(userService, alertService, dialogRef, data, fb);
  }

  ngOnInit() {
    super.ngOnInit();

    this.userTypeService.listObservable.subscribe(
      (data: any[]) => {
        this.roleList = data;
      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )

    this.title = this.isNew ? "Create new user" : "Modify user";
    //console.log(this.userEdit.name);
    this.form = this.fb.group({
      user_nameCtrl: [this.isNew ? '' : this.entity.name, Validators.compose([Validators.required, (c) => this.newUserValidator(c, this.entityList, this.entity)])],
      user_pwdCtrl: [this.isNew ? '' : this.entity.password, Validators.required],
      user_roleCtrl: [this.isNew ? '' : this.entity.id_usertype, Validators.required],
      user_mailCtrl: [this.isNew ? '' : this.entity.email, Validators.compose([Validators.email, (c: AbstractControlWarn) => this.mailJustInUseValidator(c, this.entityList, this.entity)])],
      user_isactive: this.isNew ? false : this.entity.isactive,
      user_langCtrl: this.isNew ? '' : this.entity.lang,
      user_makerCtrl: this.isNew ? '' : this.entity.id_maker,
      user_telCtrl: [this.isNew ? '' : this.entity.telephone],
    });

    this.userService.listObservable.subscribe(
      (data: any[]) => {
        this.entityList = data;
      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )
  }

  newUserValidator(c: AbstractControl, listUser: User[], user: User): { [s: string]: boolean } {
    var currentIdx = -2;
    if (listUser.length > 0) {
      var index = listUser.map(function (d) { return d.name; }).indexOf(c.value)
      if (user) {
        currentIdx = listUser.map(function (d) { return d.name }).indexOf(user.name);
      }
      if (index >= 0 && index != currentIdx) {
        return { nameJustInUse: index >= 0 };
      }
    }
  }

  mailJustInUseValidator(c: AbstractControl, listUser: User[], user: User) {
    var currentIdx = -2;
    if (listUser.length > 0) {
      var index = listUser.map(function (d) { return d.email; }).indexOf(c.value)
      if (user) {
        currentIdx = listUser.map(function (d) { return d.email }).indexOf(user.email);
      }

      if (index >= 0 && currentIdx != index) {
        this.alertService.warning("Email just in use")
      }
    }
    return null;
  }

  setIsActive() {
    var val = !this.form.get('user_isactive').value;
    this.form.patchValue({ user_isactive: val });
  }

  update(user: User) {
    user.id_maker = this.form.get('user_makerCtrl').value;
    user.name = this.form.get('user_nameCtrl').value;
    user.password = this.form.get('user_pwdCtrl').value;
    user.email = this.form.get('user_mailCtrl').value;
    user.isactive = this.form.get('user_isactive').value;
    user.lang = this.form.get('user_langCtrl').value;
    user.telephone = this.form.get('user_telCtrl').value;
    user.id_usertype = this.form.get('user_roleCtrl').value;
    user.id = user.id_user;

    this.userService.update(user)
      .subscribe(
        (data) => {
          this.alertService.info("User has been successfully updated");
          this.dialogRef.close(true);
        },
        error => {
          console.log(error)
          this.alertService.error(error.error);
        }
      );
  }

  add() {
    var user: User = {
      id: undefined,
      id_user: undefined,
      id_maker: this.form.get('user_makerCtrl').value,
      name: this.form.get('user_nameCtrl').value,
      password: this.form.get('user_pwdCtrl').value,
      email: this.form.get('user_mailCtrl').value,
      isactive: this.form.get('user_isactive').value,
      lang: this.form.get('user_langCtrl').value,
      telephone: this.form.get('user_telCtrl').value,
      id_usertype: this.form.get('user_roleCtrl').value
    };
    this.userService.create(user)
      .subscribe(
        (data) => {
          this.alertService.info("User has been successfully added");
          this.dialogRef.close(true);
        },
        error => {
          console.log(error)
          this.alertService.error(error.error);
        }
      );
  }

  delete() {
    this.userService.delete(this.entity.id_user)
      .subscribe(x => {
        this.alertService.info('User has been successfully deleted!');
        this.dialogRef.close(true);
      },
        error => {
          console.log(error)
          this.alertService.error(error.error);
        });
  }
}