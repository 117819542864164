import { ModelParamService } from './../services/model-param.service';
import { Component, Input } from '@angular/core';
import { GenericListComponent } from '../generic-list/generic-list.component';
import { Param } from '../models/param';
import { ParameterCrudService } from '../services/parameter-crud.service';
import { AlertService } from '../services/alert.service';

@Component({
  selector: 'app-parameter-list-update',
  templateUrl: './parameter-list.component.html',
  styleUrls: ['./parameter-list.component.scss']
})
export class ParameterListUpdateComponent extends GenericListComponent<Param, number>{
  @Input() idNode: string;
  @Input() idModel: number;

  prop: string;
  displayedColumns: string[] = ['id_param', 'name', 'type', 'address', 'idnode', 'update', 'applymask', 'min', 'max','all_models'];

  constructor(
    protected crudService: ParameterCrudService,
    protected modelParamService: ModelParamService,
    protected alertService: AlertService
  ) {
    super(alertService, crudService)
  }

  ngOnInit() {
    this.modelParamService.getEntityList(this.idModel).subscribe(
      (data: Param[]) => {
        this.entityList = data.filter((x) => (x.idnode == this.idNode));
        this.loadRelatedEntity();
      },

      error => console.log('error'),
      () => console.log('completeLoad')
    )

    this.prop = 'id_param';
  }
}
