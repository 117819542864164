export class DigitalInput {
    active: boolean;
    number: number;
    activeState: ActiveState;
    type: InputType;
}

export class DigitalInputSet extends DigitalInput {
    set: boolean = false;
}

export enum InputType {
    Disabled = 0,
    Switch = 1,
    HourCounter = 2,
    EventCounter = 3
}

export enum ActiveState {
    Low = 0,
    High = 1
}