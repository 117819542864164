import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import * as signalR from '@aspnet/signalr';
import { MachineIoComponent } from '../machine-io/machine-io.component';
import { AnalogicInput } from '../models/analogicInput';
import { signalRInvocation, signalRSubscription } from '../models/signalRTopic';
import { AlertService } from '../services/alert.service';
import { ConnectionSignalRService } from '../services/connection-signal-r.service';
import { ModelCrudService } from '../services/model-crud.service';
import { SelectedMachineService } from '../services/selected-machine.service';

@Component({
  selector: 'app-machine-analog-input',
  templateUrl: './machine-analog-input.component.html',
  styleUrls: ['./machine-analog-input.component.scss']
})
export class MachineAnalogInputComponent extends MachineIoComponent<AnalogicInput> {
  displayedColumns: string[] = ['select', 'number', 'type','enabled', 'val', 'activeSoil', 'sCSoil', 'oCSoil','timeAlarm'];

  constructor(
    public selectedMachine: SelectedMachineService,
    public modelService: ModelCrudService,
    public connectionSignalR: ConnectionSignalRService,
    public alertService: AlertService
  ) {
    super(selectedMachine, modelService, connectionSignalR, alertService);
    this.signalRSendPubGet = signalRSubscription.sendPubGetAnInputs;
    this.signalRSendPubSet = signalRSubscription.sendPubSetAnInputs;
    this.signalRinvokeGet = signalRInvocation.getAnInputs;
  }

  ngOnInit() {
  }

  setEnabled(input, event) {
    var a = this.list.find((x) => { return x.number == input.number });
    a.enabled = event.checked;
    this.dataSource = new MatTableDataSource(this.list);
  }

  changeActiveSoil(input, event) {
    var a = this.list.find((x) => { return x.number == input.number });
    a.activeSoil = event.target.value !== "" ? Number(event.target.value) : null;
    this.dataSource = new MatTableDataSource(this.list);
  }

  changeOCSoil(input, event) {
    var a = this.list.find((x) => { return x.number == input.number });
    a.oCSoil = event.target.value !== "" ? Number(event.target.value) : 0;
    this.dataSource = new MatTableDataSource(this.list);
  }

  changeSCSoil(input, event) {
    var a = this.list.find((x) => { return x.number == input.number });
    a.sCSoil = event.target.value !== "" ? Number(event.target.value) : 5000;
    this.dataSource = new MatTableDataSource(this.list);
  }

  changeTimeAlarm(input, event) {
    var a = this.list.find((x) => { return x.number == input.number });
    a.timeAlarm = event.target.value !== "" ? Number(event.target.value) : null;
    this.dataSource = new MatTableDataSource(this.list);
  }

  setVal() {
    this.setValue("0");
  }

  setValue(complete: string) {
    this.subscribe();
    if (this.connectionSignalR.connectionSub.value.state == signalR.HubConnectionState.Connected) {
      let analogicInputList = [];
      this.selection.selected.forEach((x) => {
        analogicInputList.push(
          {
            set: true,
            number: x.number,
            activeSoil: x.activeSoil,
            oCSoil: x.oCSoil,
            sCSoil: x.sCSoil,
            enabled: x.enabled,
            timeAlarm: x.timeAlarm
          }
        )
      });

      this.connectionSignalR.connectionSub.value.invoke(signalRInvocation.setAnInputs, this.selectedMachine.machineSub.value.serial_number, analogicInputList, complete).then(
        () => {
          console.log("setAnInputs");

        }).catch((reason: any) => {
          if (reason != null) {
            console.log(reason.message);
            this.alertService.error(reason.message.split("Exception: ")[1]);
          }
        });
    }
  }

  getVal() {
    this.getValAndSoil("0");
  }

  getValAndSoil(complete: string) {
    this.subscribe();
    if (this.connectionSignalR.connectionSub.value.state == signalR.HubConnectionState.Connected) {
      this.connectionSignalR.connectionSub.value.invoke(this.signalRinvokeGet, this.selectedMachine.machineSub.value.serial_number, complete).then(
        () => {
          console.log(this.signalRinvokeGet);

        }).catch((reason: any) => {
          if (reason != null) {
            console.log(reason.message);
            this.alertService.error(reason.message.split("Exception: ")[1]);
          }
        });
    }
  }
}
