import { Injectable } from '@angular/core';
import { TelemetryGroup } from '../models/telemetryGroup';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConfigService } from './app-config.service';
import { GenericCrudService } from './generic-crud.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TelemetryGroupCrudService extends GenericCrudService<TelemetryGroup, number>{

  constructor(http: HttpClient,
    appConfigService: AppConfigService) {
    super(http, appConfigService, "telemetryGroup");
    this.load();
  }

  getByModel(id_model): Observable<TelemetryGroup[]> {
    return this.http.get<TelemetryGroup[]>(`${this.endpoint}/${this.apiName}?id_model=${id_model}`);
  }
}