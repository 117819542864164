import { Injectable } from '@angular/core';
import { GenericCrudService } from '../services/generic-crud.service'
import { Label } from '../models/label';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class LabelCrudService extends GenericCrudService<Label, string>{

  constructor(http: HttpClient,
    appConfigService: AppConfigService) {
    super(http, appConfigService, "labels");
    this.load();
  }
}