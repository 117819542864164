import { Injectable } from '@angular/core';
import { IEntityListService } from './ientity-list.service';
import { TelemetryGroup } from '../models/telemetryGroup';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class ParamTelemetryService extends IEntityListService<TelemetryGroup>{
  protected apiName: string = "telemetryParam";

  constructor(protected http: HttpClient,
    appConfigService: AppConfigService) {
    super(http, appConfigService);
  }

  getEntityList(idobj: number): Observable<TelemetryGroup[]> {
    return this.http.get<TelemetryGroup[]>(`${this.endpoint}/${this.apiName}/id_param=${idobj}/telemetryGroups`);
  }
  postEntityList(idobj: number, listRelatedEntity: TelemetryGroup[]): Observable<any> {
    return this.http.post<any>(`${this.endpoint}/${this.apiName}/id_param=${idobj}`, listRelatedEntity.map(x=>x.id_group));
  }
}