import { Component, OnInit } from '@angular/core';
import { SelectedFirmwareService } from '../services/selected-firmware.service';
import { FirmwareModelService } from '../services/firmware-model.service';
import { Firmware } from '../models/firmware';
import { first } from 'rxjs/operators';
import { Location } from '@angular/common';

@Component({
  selector: 'app-firmware-more',
  templateUrl: './firmware-more.component.html'
})
export class FirmwareMoreComponent implements OnInit {
  public fw: Firmware;

  constructor(
    protected selectedFirmware: SelectedFirmwareService,
    protected location: Location,
    public firmwareModelService: FirmwareModelService
  ) { }

  ngOnInit() {
    this.selectedFirmware.firmwareCurrent.pipe(first()).subscribe(
      firm => {
        this.fw = firm;
      }
    );
  }

  back() {
    this.location.back();
  }
}