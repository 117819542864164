import { Injectable, Optional, Inject, InjectionToken } from '@angular/core';
import { Observable, BehaviorSubject, interval } from 'rxjs';
import { AppConfigService } from './app-config.service';
import { HttpClient } from '@angular/common/http';
import { IModelObj } from '../models/iModelObj';

export const API_NAME = new InjectionToken<string>('API_NAME');

@Injectable({
  providedIn: 'root'
})

export class GenericCrudService<Gen extends IModelObj, ID> {
  endpoint: string;
  public data: Gen[] = [];
  public list: BehaviorSubject<Gen[]> = new BehaviorSubject<Gen[]>(this.data);
  public listObservable: Observable<Gen[]>;

  constructor(
    protected http: HttpClient,
    protected appConfigService: AppConfigService,
    @Inject(API_NAME) @Optional() public apiName?: String,
  ) {
    this.endpoint = appConfigService.apiBaseUrl;
    this.listObservable = this.list.asObservable();
  }

  load() {
    this.read().subscribe((data) => {
      this.list.next(data);
    }, () => { });
  }

  create(objToCreate: Gen): Observable<any> {
    return this.http.post<any>(`${this.endpoint}/${this.apiName}`, objToCreate);
  }

  read(): Observable<Gen[]> {
    console.log(this.apiName);
    return this.http.get<Gen[]>(`${this.endpoint}/${this.apiName}`);
  }

  update(objToUpdate: Gen): Observable<any> {
    return this.http.patch<any>(`${this.endpoint}/${this.apiName}/${objToUpdate.id}`, objToUpdate);
  }

  delete(objToDeleteId: ID): Observable<any> {
    return this.http.delete<any>(`${this.endpoint}/${this.apiName}/${objToDeleteId}`);
  }
}