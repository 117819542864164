import { Component, OnInit } from '@angular/core';
import { SelectedTelemetryGroupService } from '../services/selected-telemetry-group.service';
import { Location } from '@angular/common';
import { TelemetryGroup } from '../models/telemetryGroup';
import { first } from 'rxjs/operators';
import { IEntityListService } from '../services/ientity-list.service';
import { Model } from '../models/model';
import { TelemetryParamService } from '../services/telemetry-param.service';
import { ModelCrudService } from '../services/model-crud.service';

@Component({
  selector: 'app-telemetry-group-more',
  templateUrl: './telemetry-group-more.component.html',
  providers: [
    { provide: IEntityListService, useClass: TelemetryParamService }
  ]
})
export class TelemetryGroupMoreComponent implements OnInit {
  listModels: Model[];
  public telGroup: TelemetryGroup;

  constructor(
    public selectedTelGroup: SelectedTelemetryGroupService,
    public location: Location,
    protected modelService: ModelCrudService,
    public telemetryParamService: TelemetryParamService
  ) { }

  ngOnInit() {
    this.modelService.listObservable.subscribe(
      (data: any[]) => {
        this.listModels = data;
      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )

    this.selectedTelGroup.telemetryGroupCurrent.pipe(first()).subscribe(
      telGroup => {
        this.telGroup = telGroup;
      }
    );
  }

  back() {
    this.location.back();
  }
}