import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import * as signalR from '@aspnet/signalr';
import { MachineIoComponent } from '../machine-io/machine-io.component';
import { DigitalOutput } from '../models/digitalOutputs';
import { signalRInvocation, signalRSubscription } from '../models/signalRTopic';
import { AlertService } from '../services/alert.service';
import { ConnectionSignalRService } from '../services/connection-signal-r.service';
import { ModelCrudService } from '../services/model-crud.service';
import { SelectedMachineService } from '../services/selected-machine.service';

@Component({
  selector: 'app-machine-digital-output',
  templateUrl: './machine-digital-output.component.html',
  styleUrls: ['./machine-digital-output.component.scss']
})
export class MachineDigitalOutputComponent extends MachineIoComponent<DigitalOutput> {
  displayedColumns: string[] = ['number', 'softwareSwitchOn', 'activeStateDigIn', 'activeStateAnIn', 'activeStateDigOut', 'configuration', 'digInputNum', 'anInputNum', 'keyOnConsideration']

  constructor(
    public selectedMachine: SelectedMachineService,
    public modelService: ModelCrudService,
    public connectionSignalR: ConnectionSignalRService,
    public alertService: AlertService
  ) {
    super(selectedMachine, modelService, connectionSignalR, alertService);
    this.signalRSendPubGet = signalRSubscription.sendPubGetDigOutputs;
    this.signalRSendPubSet = signalRSubscription.sendPubSetDigOutputs;
    this.signalRinvokeGet = signalRInvocation.getDigOutputs;
  }

  ngOnInit() {
  }

  setActiveStateAnInputValue(input) {
    var a = this.list.find((x) => { return x.number == input.number });
    a.activeStateAnIn = a.activeStateAnIn === 0 ? 1 : 0;
    this.dataSource = new MatTableDataSource(this.list);
  }

  setActiveStateDigInputValue(input) {
    var a = this.list.find((x) => { return x.number == input.number });
    a.activeStateDigIn = a.activeStateDigIn === 0 ? 1 : 0;
    this.dataSource = new MatTableDataSource(this.list);
  }

  setActiveStateDigOutputValue(input) {
    var a = this.list.find((x) => { return x.number == input.number });
    a.activeStateDigOut = a.activeStateDigOut === 0 ? 1 : 0;
    this.dataSource = new MatTableDataSource(this.list);
  }

  setSwitchStateValue(input, event) {
    var a = this.list.find((x) => { return x.number == input.number });
    a.softwareSwitchOn = event.checked;
    this.dataSource = new MatTableDataSource(this.list);
  }

  setKeyStateValue(input, event) {
    var a = this.list.find((x) => { return x.number == input.number });
    a.keyOnConsideration = event.checked;
    this.dataSource = new MatTableDataSource(this.list);
  }

  setAnInputNumber(input, event) {
    var a = this.list.find((x) => { return x.number == input.number });
    a.anInputNum = Number(event.target.value);
    this.dataSource = new MatTableDataSource(this.list);
  }

  setDigInputNumber(input, event) {
    var a = this.list.find((x) => { return x.number == input.number });
    a.digInputNum = Number(event.target.value);
    this.dataSource = new MatTableDataSource(this.list);
  }

  setConfigurationNumber(input, event) {
    var a = this.list.find((x) => { return x.number == input.number });
    a.configuration = Number(event.target.value);
    this.dataSource = new MatTableDataSource(this.list);
  }

  setVal() {
    this.subscribe();
    if (this.connectionSignalR.connectionSub.value.state == signalR.HubConnectionState.Connected) {

      this.connectionSignalR.connectionSub.value.invoke(signalRInvocation.setDigOutputs, this.selectedMachine.machineSub.value.serial_number, this.list).then(
        () => {
          console.log("setDigOutputs");

        }).catch((reason: any) => {
          if (reason != null) {
            console.log(reason.message);
            this.alertService.error(reason.message.split("Exception: ")[1]);
          }
        });
    }
  }
}
