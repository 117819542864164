import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SelectedUserTypeService } from '../services/selected-user-type.service';

@Injectable({
  providedIn: 'root'
})
export class UserTypeGuard implements CanActivate {

  constructor(
    private router: Router,
    private selectedRole: SelectedUserTypeService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.selectedRole.userTypeSub.value) {
      // authorised so return true
      return true;
    }
    this.router.navigate(['/roles'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}