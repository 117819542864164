import { Injectable } from '@angular/core';
import { Param } from '../models/param';
import { AppConfigService } from './app-config.service';
import { GenericCrudService } from './generic-crud.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ParameterCrudService extends GenericCrudService<Param, number> {

  constructor(http: HttpClient,
    appConfigService: AppConfigService) {
    super(http, appConfigService, "params");
    this.load();
  }

  getParameter(idobj: number): Observable<Param> {
    return this.http.get<Param>(`${this.endpoint}/${this.apiName}/id_param=${idobj}`);
  }
}
