import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Location } from '@angular/common';
import { FirmwareIot } from '../models/firmwareIot';
import { SelectedFirmwareIotService } from '../services/selected-firmware-iot.service';
import { FirmwareToMachineService } from '../services/firmware-to-machine.service';

@Component({
  selector: 'app-firmware-iot-update',
  templateUrl: './firmware-iot-update.component.html'
})
export class FirmwareIotUpdateComponent implements OnInit {
  public fwIot: FirmwareIot;

  constructor(
    protected selectedIotFirmware: SelectedFirmwareIotService,
    protected location: Location,
    public firmwareMachineService: FirmwareToMachineService
  ) { }

  ngOnInit() {
    this.selectedIotFirmware.firmwareIotCurrent.pipe(first()).subscribe(
      firmIot => {
        this.fwIot = firmIot;
      }
    );
  }

  back() {
    this.location.back();
  }
}
