import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Activity } from '../models/activity';
import { AppConfigService } from './app-config.service';
import { GenericCrudService } from './generic-crud.service';
import { StartCommand } from '../models/startCommand';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StartCommandCrudService extends GenericCrudService<StartCommand, number> {

  constructor(http: HttpClient,
    appConfigService: AppConfigService) {
    super(http, appConfigService, "startCommands");
    this.load();
  }

  getStartCommandsForMachine(machineId: number): Observable<StartCommand[]> {
    console.log(this.apiName);
    return this.http.get<StartCommand[]>(`${this.endpoint}/${this.apiName}/machineId=${machineId}`);
  }
}