import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Firmware } from '../models/firmware';

@Injectable({
  providedIn: 'root'
})
export class SelectedFirmwareService {
  firmwareSub = new BehaviorSubject<Firmware>(undefined);
  firmwareCurrent = this.firmwareSub.asObservable()

  constructor() { }

  setFirmware(fw: Firmware) {
    this.firmwareSub.next(fw);
  }

  unsetFirmware() {
    this.firmwareSub.next(undefined);
  }
}