import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { editButtonFabAnimations } from './edit-buttons.animations'
import { fabButton } from '../models/fabButton'
import { RouterLinkWithHref } from '@angular/router';

@Component({
  selector: 'app-edit-buttons',
  templateUrl: './edit-buttons.component.html',
  styleUrls: ['./edit-buttons.component.scss'],
  animations: editButtonFabAnimations
})
export class EditButtonsComponent implements OnInit {
  @Input() protected row: any = true;
  @Input() protected fabButtons: fabButton[];
  @Output() protected more = new EventEmitter<any>();
  @Output() protected clicked = new EventEmitter<any>();

  buttons: fabButton[];
  editTogglerState = 'inactive';

  constructor() { }

  ngOnInit(): void {
    this.buttons = [];
  }

  showItems() {
    this.editTogglerState = 'active';
    this.buttons = this.fabButtons;
    this.more.emit(this.row);
  }

  hideItems() {
    this.editTogglerState = 'inactive';
    this.buttons = [];
    this.more.emit(this.row);
  }

  onToggleFab() {
    this.buttons.length ? this.hideItems() : this.showItems();
  }

  click(btn: any) {
    var val = {
      element: this.row,
      type: btn.function
    }
    this.clicked.emit(val);
  }

  showUser(test: any) {
    console.log('aaaaa');
  }
}

