import { Component } from '@angular/core';
import { GenericCrudComponent } from '../generic-crud/generic-crud.component';
import { AlertService } from '../services/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { UserCrudService } from '../services/user-crud.service';
import { UserTypeCrudService } from '../services/user-type-crud.service';
import { User } from '../models/user';
import { LANGLIST} from '../models/mock-string-list'
import { UserSettingsDialogComponent } from '../user-settings-dialog/user-settings-dialog.component';
import { fabButton } from '../models/fabButton';
import { UserType } from '../models/userType';
import { SelectedUserService } from '../services/selected-user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent extends GenericCrudComponent<User, number> {
  langList = LANGLIST;
  roleList: UserType[];
  fabButtons: fabButton[];
  displayedColumns: string[] = ['editing', 'id_user', 'id_usertype', 'name', 'password', 'email', 'isactive', 'lang', 'id_maker', 'telephone'];

  constructor(
    protected crudService: UserCrudService,
    protected userTypeService: UserTypeCrudService,
    protected alertService: AlertService,
    protected selectedUserService: SelectedUserService,
    protected router: Router,
    public dialog: MatDialog
  ) {
    super(crudService, alertService, dialog)
  }
  
  ngOnInit() {
    this.filterString = "userFilter";
    this.userTypeService.listObservable.subscribe(
      (data: any[]) => {
        this.roleList = data;
      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )
    super.ngOnInit();
  }

  more(row: User) {
    super.more(row);
    this.selectedUserService.setUser(row);
    this.router.navigate([`user-details`]);
  }

  edit(row: User): void {
    this.showEditDialog(UserSettingsDialogComponent, row);
  }
  showAddDialog(): void {
    this.showEditDialog(UserSettingsDialogComponent, null);
  }
}