import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import * as signalR from '@aspnet/signalr';
import { MachineIoComponent } from '../machine-io/machine-io.component';
import { DigitalOutput } from '../models/digitalOutputs';
import { signalRInvocation, signalRSubscription } from '../models/signalRTopic';
import { AlertService } from '../services/alert.service';
import { ConnectionSignalRService } from '../services/connection-signal-r.service';
import { ModelCrudService } from '../services/model-crud.service';
import { SelectedMachineService } from '../services/selected-machine.service';

@Component({
  selector: 'report-io-digital-output',
  templateUrl: './report-io-digital-output.component.html',
  styleUrls: ['./report-io-digital-output.component.scss']
})
export class ReportIoDigitalOutputComponent{
  @Input() dataSource: DigitalOutput[];

  displayedColumns: string[] = ['softwareSwitchOn', 'activeStateDigIn', 'activeStateAnIn', 'activeStateDigOut', 'configuration', 'digInputNum', 'anInputNum', 'keyOnConsideration']

  constructor(
    public selectedMachine: SelectedMachineService,
    public modelService: ModelCrudService,
    public connectionSignalR: ConnectionSignalRService,
    public alertService: AlertService
  ) {
  }

  ngOnInit() {
  }
}
