import { Pipe, PipeTransform } from '@angular/core';
import { stringList } from '../models/mock-string-list';

@Pipe({
  name: 'stringList'
})
export class StringListPipe implements PipeTransform {
  transform(stringList: stringList[], value: string): any {
    return stringList.find(lang => lang.value === value);
  }
}