import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'nodeColor'
})
export class NodeColorPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    try {
      return environment.IDNODELIST.find(x => x.label === value || x.val === value).color;
    }
    catch {
      return 'warn';
    }
  }

}