import { Component, OnInit } from '@angular/core';
import { SelectedMachineService } from '../services/selected-machine.service';
import { Machine } from '../models/machine';
import { first } from 'rxjs/operators';
import { Location } from '@angular/common';
import { ModelCrudService } from '../services/model-crud.service';
import { UserCrudService } from '../services/user-crud.service';
import { Model } from '../models/model';
import { User } from '../models/user';
import { IEntityListService } from '../services/ientity-list.service';
import { MachineToUserService } from '../services/machine-to-user.service';
import { MachineToActivityService } from '../services/machine-to-activity.service';
import { MachineToEventService } from '../services/machine-to-event.service';
import { MachineToGeolocationService } from '../services/machine-to-geolocation.service';
import { MachineToFirmwareService } from '../services/machine-to-firmware.service';
import { MachineToReportService } from '../services/machine-to-report.service';

@Component({
  selector: 'app-machine-more',
  templateUrl: './machine-more.component.html',
  styleUrls: ['./machine-more.component.scss'],
  providers: [
    { provide: IEntityListService, useClass: MachineToUserService }
  ]
})
export class MachineMoreComponent implements OnInit {
  machine: Machine;
  listModels: Model[];
  listUsers: User[];
  listRelatedUsers: User[];

  constructor(
    public selectedMachine: SelectedMachineService,
    public location: Location,
    public modelService: ModelCrudService,
    public userService: UserCrudService,
    public machineToUserService: MachineToUserService,
    public machineToActivityService: MachineToActivityService,
    public machineToGeolocationService: MachineToGeolocationService,
    public machineToEventService: MachineToEventService,
    public machineToFirmwareService: MachineToFirmwareService,
    public machineToReportService:MachineToReportService
  ) { }

  ngOnInit() {
    this.modelService.listObservable.subscribe(
      (data: any[]) => {
        this.listModels = data;
      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )
    this.userService.listObservable.subscribe(
      (data: any[]) => {
        this.listUsers = data;
      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )

    this.selectedMachine.machineCurrent.pipe(first()).subscribe(
      machine => {
        this.machine = machine;
      }
    );
  }

  back() {
    this.location.back();
  }
  getColor(isonline: number) {
    return Machine.getColor(isonline);
  }
}
