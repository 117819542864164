import { OnInit } from '@angular/core';
import { SelectedMachineService } from '../services/selected-machine.service';
import { ModelCrudService } from '../services/model-crud.service';
import { Machine } from '../models/machine';
import { Model } from '../models/model';
import { first } from 'rxjs/operators';
import { from } from 'rxjs';
import { AlertService } from '../services/alert.service';
import { environment } from 'src/environments/environment';
import { ConnectionSignalRService } from '../services/connection-signal-r.service';
//import xml2js from 'xml2js';

export abstract class MachineTestingComponent implements OnInit {
  public machine: Machine;
  public model: Model;
  public board: Object = null;
  public enabledMessage = true;
  listModels: Model[];

  constructor(
    public selectedMachine: SelectedMachineService,
    public modelService: ModelCrudService,
    public alertService: AlertService,
    public connectionSignalR: ConnectionSignalRService
  ) { }

  interceptDisconnection() {
    if (this.connectionSignalR.connectionSub.value != null) {

      this.connectionSignalR.connectionSub.value.serverTimeoutInMilliseconds = 25 * 1000;
      this.connectionSignalR.connectionSub.value.onclose(error => {
        if (error != undefined) {
          this.alertService.error("Connection was unsafely closed by browser. Please reload page");
        }
        else if (this.enabledMessage) {
          this.alertService.info("Connection was safely closed by browser");
        }
      });
    }
  }

  ngOnInit() {
    this.modelService.listObservable.subscribe(
      (data: any[]) => {
        this.listModels = data;
        this.model = this.listModels[0];
      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )

    this.selectedMachine.machineCurrent.pipe(first()).subscribe(
      machine => {
        this.machine = machine;
        from(this.listModels).pipe(first(x => x.id_model == machine.id_model)).subscribe(
          x => {
            this.model = x;
            /* this.parseXML(x.jclient).then((data) => {
              this.board = data;
            });*/
          }
        );
      }
    );
  }
}
