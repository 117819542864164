import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import * as signalR from '@aspnet/signalr';
import { MachineIoComponent } from '../machine-io/machine-io.component';
import { DigitalInput } from '../models/digitalInput';
import { signalRInvocation, signalRSubscription } from '../models/signalRTopic';
import { AlertService } from '../services/alert.service';
import { ConnectionSignalRService } from '../services/connection-signal-r.service';
import { ModelCrudService } from '../services/model-crud.service';
import { SelectedMachineService } from '../services/selected-machine.service';

@Component({
  selector: 'app-machine-digital-input-read',
  templateUrl: './machine-digital-input-read.component.html'
})
export class MachineDigitalInputReadComponent  {
  dataSource = [];
  displayedColumns: string[] = ['number', 'type', 'active', 'activeState']
  eventCounterList: number[] = [3, 4, 5, 6];


  ngOnInit(): void {
  }

}


