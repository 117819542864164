import { Injectable } from '@angular/core';
import { GenericCrudService } from './generic-crud.service';
import { ActivityParam } from '../models/activityParam';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActivityParamCrudService extends GenericCrudService<ActivityParam, number> {

  constructor(http: HttpClient,
    appConfigService: AppConfigService) {
    super(http, appConfigService, "activityParams");
    this.load();
  }

  getListByActSingleRecId(idobj: number): Observable<ActivityParam[]> {
    return this.http.get<ActivityParam[]>(`${this.endpoint}/${this.apiName}/id_act_single_rec=${idobj}`);
  }
}
