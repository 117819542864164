import { Pipe, PipeTransform } from '@angular/core';
import { Model } from '../models/model';

@Pipe({
  name: 'model'
})
export class ModelPipe implements PipeTransform {

  transform(modelList: Model[], id_model: number): any {
    return modelList.find(model => model.id_model === id_model);
  }
}