import { Component, ViewChild } from '@angular/core';
import { CommandCrudService } from '../services/command-crud.service';
import { GenericCrudComponent } from '../generic-crud/generic-crud.component';
import { AlertService } from '../services/alert.service';
import { SelectedCommandService } from '../services/selected-command.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CommandSettingsDialogComponent } from '../command-settings-dialog/command-settings-dialog.component';
import { Command } from '../models/command';
import { COMMANDTYPELIST } from '../models/mock-string-list';
import { GenericCrudService } from '../services/generic-crud.service';


@Component({
  selector: 'app-command-settings',
  templateUrl: './command-settings.component.html',
})
export class CommandSettingsComponent extends GenericCrudComponent<Command, number> {
  commandTypeList = COMMANDTYPELIST;
  displayedColumns: string[] = ['editing', 'id_cmd', 'name', 'can_index', 'can_sub_index', 'idnode','default_enable'];

  constructor(
    protected crudService: CommandCrudService,
    protected alertService: AlertService,
    protected selectedCommand: SelectedCommandService,
    protected router: Router,
    public dialog: MatDialog,
  ) {
    super(crudService, alertService, dialog)
    this.filterString = "cmdFilter";
  }

  more(row: Command) {
    super.more(row);
    this.selectedCommand.setCommand(row);
    this.router.navigate([`command-details`]);
  }

  edit(row: Command): void {
    this.showEditDialog(CommandSettingsDialogComponent, row);
  }
  showAddDialog(): void {
    this.showEditDialog(CommandSettingsDialogComponent, null);
  }
}

