import { Component, Inject, ChangeDetectorRef } from '@angular/core';
import { GenericSettingsDialogComponent } from '../generic-settings-dialog/generic-settings-dialog.component';
import { FirmwareIot } from '../models/firmwareIot';
import { FirmwareIotCrudService } from '../services/firmware-iot-crud.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertService } from '../services/alert.service';
import { MACHINECONNECTIONTYPE } from '../models/mock-machineState';

@Component({
  selector: 'app-firmware-iot-settings-dialog',
  templateUrl: './firmware-iot-settings-dialog.component.html'
})
export class FirmwareIotSettingsDialogComponent extends GenericSettingsDialogComponent<FirmwareIot,number> {
  
  constructor(
    protected firmwareService : FirmwareIotCrudService,
    protected alertService: AlertService,
    public dialogRef: MatDialogRef<FirmwareIotSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected fb: FormBuilder,
    private ref: ChangeDetectorRef
  ){ 
    super(firmwareService,alertService,dialogRef,data,fb);
   }

   listConnections= MACHINECONNECTIONTYPE;

  ngOnInit() {
    super.ngOnInit();
    this.title = this.isNew? "Create new firmware IoT":"Modify firmware IoT";
    this.form = this.fb.group({
      firmwarename_fwCtrl: this.isNew?'':this.data.element.name_fw, 
      firmwaredescription_fwCtrl:this.isNew?'':this.data.element.description_fw,
      firmwareconnversion_fwCtrl:[this.isNew?'':this.entity.conn_version,Validators.required],
      firmwarebin_fwCtrl:[''],
      });
  }

  update(f:FirmwareIot){
    f.id=f.id_fw;
    f.name_fw=this.form.get('firmwarename_fwCtrl').value;
    f.description_fw=this.form.get('firmwaredescription_fwCtrl').value;
    f.conn_version=this.form.get('firmwareconnversion_fwCtrl').value
    var fw = this.form.get('firmwarebin_fwCtrl').value;
    
    if(fw!=null){
      var sub = fw.indexOf(";base64,");
      f.bin_fw = fw.substr(sub+8);
    }
    else{
      f.bin_fw=undefined;
    }

    f.release_date_fw=null;

    this.firmwareService.update(f)
    .subscribe(
      (data) => {
          this.alertService.info("Firmware has been successfully updated");
          this.dialogRef.close(true);
      },
      error => {
        console.log(error)
        this.alertService.error(error.error);
      }
    );
  }

  onFileChange(event){
    let reader = new FileReader();
 
    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
        this.form.patchValue({
          firmwarebin_fwCtrl: reader.result
        });
        
        this.ref.markForCheck();
      };
    }
  }

  delete()
  {
    this.firmwareService.delete(this.entity.id_fw)
      .subscribe(x=> 
        {
          this.alertService.info('Firmware successfully deleted!' );
          this.dialogRef.close(true);
        },
      error => {
        console.log(error)
        this.alertService.error(error.error);
      });
  }

  add()
  {
    var fw = this.form.get('firmwarebin_fwCtrl').value;

    if(fw==null|| fw=='')
    {
      this.alertService.error('Please upload a file');
      return;
    }

    var sub = fw.indexOf(";base64,");
    
    var firmware: FirmwareIot = {
      name_fw:this.form.get('firmwarename_fwCtrl').value,
      description_fw:this.form.get('firmwaredescription_fwCtrl').value,
      release_date_fw:null,
      conn_version:this.form.get('firmwareconnversion_fwCtrl').value,
      bin_fw:fw.substr(sub+8),
      id: undefined,
      id_fw:undefined
    };

    this.firmwareService.create(firmware)
    .subscribe(
      (data) => {
          this.alertService.info("Firmware has been successfully added");
          this.dialogRef.close(true);
      },
      error => {
        console.log(error)
        this.alertService.error(error.error);
      }
    );
  }


}
