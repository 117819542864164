import { Field } from './field';

export class RespGetData {
    fields: Field[];
    code: string;
    date: Date;
}

export class RespTableNode {
    node: any[];
    code: string;
    date: Date;
}