import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FirmwareIot } from '../models/firmwareIot';

@Injectable({
  providedIn: 'root'
})
export class SelectedFirmwareIotService {
  firmwareIotSub = new BehaviorSubject<FirmwareIot>(undefined);
  firmwareIotCurrent = this.firmwareIotSub.asObservable()

  constructor() { }

  setFirmware(fwIot: FirmwareIot) {
    this.firmwareIotSub.next(fwIot);
  }

  unsetFirmware() {
    this.firmwareIotSub.next(undefined);
  }
}
