import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import { Observable } from 'rxjs';
import { Command } from '../models/command';
import { IEntityListService } from './ientity-list.service';

@Injectable({
  providedIn: 'root'
})
export class ModelCommandService extends IEntityListService<Command>{
  protected apiName: string = "modelCommands";

  constructor(protected http: HttpClient,
    appConfigService: AppConfigService) {
    super(http, appConfigService);
  }

  getEntityList(idobj: number): Observable<Command[]> {
    return this.http.get<Command[]>(`${this.endpoint}/${this.apiName}/id_model=${idobj}/commands`);
  }
  postEntityList(idobj: number, listRelatedEntity: Command[]): Observable<any> {
    return this.http.post<any>(`${this.endpoint}/${this.apiName}/id_model=${idobj}`, listRelatedEntity.filter(x=>x.default_enable!=1).map(x=>x.id_cmd));
  }
}
