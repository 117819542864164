import { Component, OnInit } from '@angular/core';
import { GenericCrudComponent } from '../generic-crud/generic-crud.component';
import { CommandParam } from '../models/commandParam';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { CommandParamCrudService } from '../services/command-param-crud.service';
import { AlertService } from '../services/alert.service';
import { SelectedCommandService } from '../services/selected-command.service';
import { first, map } from 'rxjs/operators';
import { CommandParamSettingsDialogComponent } from '../command-param-settings-dialog/command-param-settings-dialog.component';

@Component({
  selector: 'app-command-param-settings',
  templateUrl: './command-param-settings.component.html'
})
export class CommandParamSettingsComponent extends GenericCrudComponent<CommandParam, number> {
  displayedColumns: string[] = ['editing', 'id_cmdParam', 'name', 'bytelen', 'order_by', 'date_upd', 'date_del', 'fix_val'];

  constructor(
    protected crudService: CommandParamCrudService,
    protected alertService: AlertService,
    public dialog: MatDialog,
    protected selectedCommand: SelectedCommandService
  ) {
    super(crudService, alertService, dialog)
  }

  load() {
    return this.crudService.listObservable.pipe(map(cmdParams => cmdParams.filter(cmdParam => cmdParam.id_cmd === this.selectedCommand.commandSub.value.id_cmd))).subscribe(
      (data: CommandParam[]) => {
        this.listElements = data;
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.applyFilter(this.fil);
      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )
  }

  ngOnInit() {
    this.selectedCommand.commandCurrent.pipe(first()).subscribe(() => {
      this.load();
    });
  }

  more(row: CommandParam): void {
    throw new Error("Method not implemented.");
  }
  edit(row: CommandParam): void {
    this.showEditDialog(CommandParamSettingsDialogComponent, row);
  }
  showAddDialog(): void {
    this.showEditDialog(CommandParamSettingsDialogComponent, null);
  }

}
