import { Pipe, PipeTransform } from '@angular/core';
import { MACHINECONNECTIONTYPE } from '../models/mock-machineState';

@Pipe({
  name: 'machineConnectionType'
})
export class MachineConnectionTypePipe implements PipeTransform {

  transform(value:number, attribute: string): any {
    var elem =  MACHINECONNECTIONTYPE.find((element) =>{
      return element.id==value;
    });
    return elem[attribute]; 
  }
}