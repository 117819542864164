import { Component, ViewChild } from '@angular/core';
import { MachineTestingComponent } from '../machine-testing/machine-testing.component';
import { SelectedMachineService } from '../services/selected-machine.service';
import { ModelCrudService } from '../services/model-crud.service';
import * as signalR from "@aspnet/signalr";
import { ConnectionSignalRService } from '../services/connection-signal-r.service';
import { RespGetData } from '../models/respGetData';
import { signalRInvocation, signalRSubscription } from '../models/signalRTopic';
import { MatExpansionPanel } from '@angular/material/expansion';
import { AlertService } from '../services/alert.service';
import { CustomCommand, CustomCommandVal } from '../models/customCommand';
import { CustomdCommandCrudService } from '../services/custom-command-crud.service';

@Component({
  selector: 'app-machine-custom-commands',
  templateUrl: './machine-custom-commands.component.html',
  styleUrls: ['./machine-custom-commands.component.scss']
})
export class MachineCustomCommandsComponent extends MachineTestingComponent {
  @ViewChild(MatExpansionPanel, { static: true }) paramPanel: MatExpansionPanel;

  public listCommand: CustomCommand[] = [];
  public listSelectedCommand: CustomCommandVal[] = [];
  public listResp: RespGetData[] = [];
  public name_cmd: string;
  first = true;
  cmdOn = false;

  constructor(
    public selectedMachine: SelectedMachineService,
    public modelService: ModelCrudService,
    public customCommandService: CustomdCommandCrudService,
    public connectionSignalR: ConnectionSignalRService,
    public alertService: AlertService
  ) {
    super(selectedMachine, modelService, alertService, connectionSignalR);
  }

  ngOnInit() {
    this.customCommandService.listObservable.subscribe((data:any[])=> {
      this.listCommand = data;
    });
    super.ngOnInit();
  }

  clean() {
    this.listResp = [];
  }

  subscribe() {
    if (this.first) {
      this.interceptDisconnection();
      this.first = false;

      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendUpdatedData, (resp: string) => {
        //  this.selectedMachine.machineSub.value.isonline = status;
        this.cmdOn = false;
        var r = new RespGetData();
        r.date = new Date();
        r.code = "Sended command: "+ resp;
        
        this.listResp.push(r);
      });
    }
  }

  addCmd() {
    var idx = this.listCommand.findIndex((element) => {
      return element.name == this.name_cmd;
    });
    if(this.listSelectedCommand.length==10){
      this.alertService.error("Too many commands. Max 10 commands admitted");
    }
    else if (idx > -1) {
      var cmdVal = new CustomCommandVal();
      cmdVal.delay = 50;
      cmdVal.command = this.listCommand[idx];
      this.listSelectedCommand.push(cmdVal);
    }
  }

  remove(cmdName) {
    var idx = this.listSelectedCommand.findIndex((element) => {
      return element.command.name == cmdName;
    });
    if (idx > -1)
      this.listSelectedCommand.splice(idx, 1);
  }

  launchCommand() {
    this.subscribe();
    var dataToSend = [];
    this.listSelectedCommand.forEach(element => {
      dataToSend.push({
        "name": element.command.name,
        "value": element.delay
      })
    });
    if (this.connectionSignalR.connectionSub.value.state == signalR.HubConnectionState.Connected) {
      this.connectionSignalR.connectionSub.value.invoke(signalRInvocation.writeCmd, this.selectedMachine.machineSub.value.serial_number, dataToSend)
        .then(() => {
          console.log('writeCmd');
          this.cmdOn = true;;
        }
        ).catch((reason: any) => {
          if (reason != null) {
            console.log(reason.message);
            this.alertService.error(reason.message.split("Exception: ")[1]);
          }
        });
    }
  }
}