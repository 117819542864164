import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Model } from '../models/model';
import { User } from '../models/user';
import { FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { AlertService } from '../services/alert.service';
import { UserCrudService } from '../services/user-crud.service';
import { ModelCrudService } from '../services/model-crud.service';
import { MachineCrudService } from '../services/machine-crud.service';
import { Machine } from '../models/machine';
import { GenericSettingsDialogComponent } from '../generic-settings-dialog/generic-settings-dialog.component';
import { MACHINECONNECTIONTYPE } from '../models/mock-machineState';
import {COMMA, ENTER, SEMICOLON} from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-machine-settings-dialog',
  templateUrl: './machine-settings-dialog.component.html'
})
export class MachineSettingsDialogComponent extends GenericSettingsDialogComponent<Machine, number> {
  listUsers: User[];
  listModels: Model[];
  listConnections = MACHINECONNECTIONTYPE;

  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, SEMICOLON] as const;
  mails: string[] = []

  constructor(
    protected machineService: MachineCrudService,
    protected userService: UserCrudService,
    protected modelService: ModelCrudService,
    protected alertService: AlertService,
    public dialogRef: MatDialogRef<MachineSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected fb: FormBuilder
  ) {
    super(machineService, alertService, dialogRef, data, fb)
  }

  ngOnInit() {
    super.ngOnInit();
    this.title = this.isNew ? "Create new machine" : "Modify machine";
    this.form = this.fb.group({
      machine_serialNumberCtrl: [this.isNew ? '' : this.entity.serial_number, Validators.compose([Validators.required, (c) => this.newMachineValidator(c, this.entityList, this.entity)])],
      machine_nameCtrl: [this.isNew ? '' : this.entity.name, Validators.required],
      machine_descriptionCtrl: this.isNew ? '' : this.entity.description,
      machine_ownerCtrl: this.isNew ? '' : this.entity.id_owner,
      machine_enabledCtrl: this.isNew ? '' : this.entity.isenabled,
      machine_ioRecordingsCtrl: this.isNew ? '' : this.entity.ioRecordings,
      machine_connVersionCtrl: [this.isNew ? '' : this.entity.conn_version, Validators.required],
      machine_modelCtrl: [this.isNew ? '' : this.entity.id_model, Validators.required],
      machine_batteryAlarmSoilCtrl: this.isNew ? '' : this.entity.battery_alarm_soil,
      machine_batteryWarningSoilCtrl: this.isNew ? '' : this.entity.battery_warning_soil,
      machine_batteryMailAlertCtrl: this.isNew ? '' : this.entity.battery_mail_alert
    });
    this.mails = this.isNew || this.entity.battery_mail_alert==undefined || this.entity.battery_mail_alert==null ?[]:this.entity.battery_mail_alert.split(";");

    this.userService.listObservable.subscribe(
      (data: any[]) => {
        this.listUsers = data;
      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )

    this.modelService.listObservable.subscribe(
      (data: any[]) => {
        this.listModels = data;
      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )
  }

  add() {
    var machine: Machine = {
      id: undefined,
      id_machine: undefined,
      id_model: this.form.get('machine_modelCtrl').value,
      name: this.form.get('machine_nameCtrl').value,
      description: this.form.get('machine_descriptionCtrl').value,
      isenabled: this.form.get('machine_enabledCtrl').value ? 1 : 0,
      isonline: 0,
      ioRecordings: this.form.get('machine_ioRecordingsCtrl').value ? 1 : 0,
      serial_number: this.form.get('machine_serialNumberCtrl').value,
      id_owner: this.form.get('machine_ownerCtrl').value,
      date_IoT_upd: null,
      date_auto: null,
      date_last_status: null,
      conn_version: this.form.get('machine_connVersionCtrl').value,
      battery_alarm_soil: this.form.get('machine_batteryAlarmSoilCtrl').value,
      battery_level: null,
      battery_mail_alert: this.mails.join(";"),
      battery_warning_soil: this.form.get('machine_batteryWarningSoilCtrl').value,
      fw_type:null,
      md5_uid:null,
    };

    this.machineService.create(machine)
      .subscribe(
        (data) => {
          this.alertService.info("Machine has been successfully added");
          this.dialogRef.close(true);
        },
        error => {
          console.log(error)
          this.alertService.error(error.error.message);
        }
      );
  }

  update(machine: Machine) {
    machine.id_owner = this.form.get('machine_ownerCtrl').value;
    machine.id_model = this.form.get('machine_modelCtrl').value;
    machine.name = this.form.get('machine_nameCtrl').value;
    machine.description = this.form.get('machine_descriptionCtrl').value;
    machine.isenabled = this.form.get('machine_enabledCtrl').value ? 1 : 0;
    machine.ioRecordings = this.form.get('machine_ioRecordingsCtrl').value ? 1 : 0;
    machine.serial_number = this.form.get('machine_serialNumberCtrl').value;
    machine.conn_version = this.form.get('machine_connVersionCtrl').value;
    machine.id = machine.id_machine;
    machine.battery_alarm_soil = this.form.get('machine_batteryAlarmSoilCtrl').value;
    machine.battery_warning_soil = this.form.get('machine_batteryWarningSoilCtrl').value;
    machine.battery_mail_alert = this.mails.join(";");

    this.machineService.update(machine)
      .subscribe(
        (data) => {
          this.alertService.info("Machine has been successfully updated");
          this.dialogRef.close(true);
        },
        error => {
          console.log(error)
          this.alertService.error(error.error);
        }
      );
  }

  newMachineValidator(c: AbstractControl, listMachine: Machine[], machine: Machine): { [s: string]: boolean } {
    var currentIdx = -2;
    if (listMachine.length > 0) {
      var index = listMachine.map(function (d) { return d.serial_number; }).indexOf(c.value)
      if (machine) {
        currentIdx = listMachine.map(function (d) { return d.serial_number }).indexOf(machine.serial_number);
      }
      if (index >= 0 && index != currentIdx) {
        return { serialJustInUse: index >= 0 };
      }
    }
  }

  delete() {
    this.machineService.delete(this.entity.id_machine)
      .subscribe(x => {
        this.alertService.info('Machine has been successfully deleted!');
        this.dialogRef.close(true);
      },
        error => {
          console.log(error)
          this.alertService.error(error.error);
        });
  }

  addMail(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.mails.push(value);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(mail: string): void {
    const index = this.mails.indexOf(mail);

    if (index >= 0) {
      this.mails.splice(index, 1);
    }
  }
}
