import { Component, OnInit } from '@angular/core';
import { GenericListComponent } from '../generic-list/generic-list.component';
import { AlertService } from '../services/alert.service';
import { ActivityParamDialogComponent } from '../activity-param-list-dialog/activity-param-list-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { IIOReport, IOReport } from '../models/ioreport';
import { ReportIoListDialogComponent } from '../report-io-list-dialog/report-io-list-dialog.component';

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html'
})
export class ReportListComponent extends GenericListComponent<IOReport, number> {
  prop: "id_iorecordings";
  displayedColumns: string[] = ['id_iorecordings', 'lat', 'lng', 'date_start', 'date_end','id_iosingle_rec_start','id_iosingle_rec_stop'];

  constructor(
    protected alertService: AlertService,
    public dialog: MatDialog

  ) {
    super(alertService, null);
    this.prop = 'id_iorecordings';
  }

  more(id: string) {
    const dialogRef = this.dialog.open(
      ReportIoListDialogComponent, {
      width: '800px',
      height: '600px',
      data: {
        id: id,
      }
    });

    dialogRef.afterClosed().subscribe(result => {});

  }
}

