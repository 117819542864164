import { Pipe, PipeTransform } from '@angular/core';
import { InputType } from '../models/digitalInput';

@Pipe({
  name: 'digitalInputTypeIcon'
})
export class DigitalInputTypeIconPipe implements PipeTransform {

  transform(value: InputType): any {
    switch (value) {
      case InputType.Disabled:
        return "remove";
      case InputType.Switch:
        return "toggle_on";
      case InputType.EventCounter:
        return "note_add";
      case InputType.HourCounter:
        return "timer";
    }
  }
}