export class Alert {
    type: string;
    message: string;
    duration: number=5000;

    constructor(message: string,type:string,duration:number=5000 ) {
        this.message = message;
        this.type = type;
        this.duration=duration;
    }
}