import { AlertService } from './../services/alert.service';
import { Sim } from './../models/sim';
import { RespGetSims } from './../models/respGetSims';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SimService } from '../services/sim.service';

@Component({
  templateUrl: './sim-info-dialog.component.html',
  styleUrls: ['./sim-info-dialog.component.scss']
})
export class SimInfoDialogComponent implements OnInit {

  title = "Informazioni Sim";
  respGetSims: RespGetSims;
  sim: Sim;
  serialNumber: string;

  constructor(
    public alertService: AlertService,
    public simService: SimService,
    public dialogRef: MatDialogRef<SimInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    ) {

    }

  ngOnInit(): void {
    this.serialNumber = this.data.serial_number;
    this.simService.getSims(this.serialNumber).subscribe( resp => {
      this.respGetSims = resp;
      this.sim =  this.respGetSims.sims[0];
      console.log('SIm info:',resp);
    },
    error => {
      console.log(error)
      this.alertService.error(error.message);
    });
  }

  activeSim(){
    this.simService.activateSim(this.serialNumber).subscribe(
    success => {
      console.log(success)
      this.alertService.info(success.message);
      this.dialogRef.close();
    },
    error => {
      console.log(error)
      this.alertService.error(error.message);
    });
  }

}
