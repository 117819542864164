import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Machine } from '../models/machine';

@Injectable({
  providedIn: 'root'
})
export class SelectedMachineService {
  machineSub = new BehaviorSubject<Machine>(undefined);
  machineCurrent = this.machineSub.asObservable()

  constructor() { }

  setMachine(mac: Machine) {
    this.machineSub.next(mac);
  }

  unsetMachine() {
    this.machineSub.next(undefined);
  }
}