import { Injectable } from '@angular/core';
import { GenericCrudService } from './generic-crud.service'
import { AppConfigService } from './app-config.service';
import { HttpClient } from '@angular/common/http';
import { SignalRConnection } from '../models/signalRConnection';

@Injectable({
  providedIn: 'root'
})
export class DebugCrudService extends GenericCrudService<SignalRConnection, string>{

  constructor(http: HttpClient,
    appConfigService: AppConfigService) {
    super(http, appConfigService, "debug");
    this.load();
  }
}