import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  private appConfig: any;

  constructor(private http: HttpClient) { }

  loadAppConfig() {
    return this.http.get('/assets/config.json')
      .toPromise()
      .then(data => {
        this.appConfig = data;
      });
  }

  get apiBaseUrl() {
    if (!environment.apiBaseUrl) {
      //throw Error('Config file not loaded!');
      return "http://localhost:9220/api";
    }
    return environment.apiBaseUrl;
  }

  get title() {
    debugger;
    console.debug(environment.env_name);
    return environment.ENVS.find(x => x.name == environment.env_name).title;
  }

  get envs() {
    return environment.ENVS;
  }

  get version() {
    return environment.appVersion;
  }

  get miniLogo() {
    return environment.ENVS.find(x => x.name == environment.env_name).mini_logo;
  }

  get name() {
    return environment.env_name;
  }

  get documentation(){
    return {label: 'Documentation', link: "swagger", logo: "../../assets/images/swagger.png" }
  }
}
