import { Component, Input } from '@angular/core';
import { DigitalHourCounter } from '../models/digitalHourCounter';
import { AlertService } from '../services/alert.service';
import { ConnectionSignalRService } from '../services/connection-signal-r.service';
import { ModelCrudService } from '../services/model-crud.service';
import { SelectedMachineService } from '../services/selected-machine.service';

@Component({
  selector: 'report-io-hour-counter',
  templateUrl: './report-io-hour-counter.component.html',
  styleUrls: ['./report-io-hour-counter.component.scss']
})
export class ReportIoHourCounterComponent{
  @Input() dataSource: DigitalHourCounter[];
  displayedColumns: string[] = ['number', 'isHourCounter', 'activeState', 'counter']

  constructor(
    public selectedMachine: SelectedMachineService,
    public modelService: ModelCrudService,
    public connectionSignalR: ConnectionSignalRService,
    public alertService: AlertService
  ) {
  }

  ngOnInit() {
  }

}
