import { RespGetSims } from './../models/respGetSims';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Sim } from "../models/sim";
import { AppConfigService } from "./app-config.service";
import { GenericCrudService } from "./generic-crud.service";

@Injectable({
  providedIn: 'root'
})
export class SimService extends GenericCrudService<Sim, number>{

  constructor(http: HttpClient,
    appConfigService: AppConfigService) {
    super(http, appConfigService, "Sim");
  }

  getSims(serialNumber: string): Observable<RespGetSims> {
    console.log(this.apiName);
    return this.http.get<any>(`${this.endpoint}/${this.apiName}/getSimStatus?microSysSerialNumber=${serialNumber}`);
  }

  activateSim(serialNumber: string): Observable<any> {
    console.log(this.apiName);
    return this.http.put<any>(`${this.endpoint}/${this.apiName}/activeSim?microSysSerialNumber=${serialNumber}`, {});
  }
}
