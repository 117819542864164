import { Component, OnInit } from '@angular/core';
import { SelectedUserTypeService } from '../services/selected-user-type.service';
import { UserCrudService } from '../services/user-crud.service';
import { User } from '../models/user';
import { Location } from '@angular/common';
import { first } from 'rxjs/operators';
import { UserType } from '../models/userType';
import { UserTypeUserService } from '../services/user-type-user.service';

@Component({
  selector: 'app-user-type-more',
  templateUrl: './user-type-more.component.html'
})
export class UserTypeMoreComponent implements OnInit {
  listUsers: User[];
  role: UserType;

  constructor(
    protected selectedRole: SelectedUserTypeService,
    protected location: Location,
    protected userService: UserCrudService,
    public userTypeUserService: UserTypeUserService
  ) { }

  ngOnInit() {
    this.selectedRole.userTypeCurrent.pipe(first()).subscribe(
      role => {
        this.role = role;
        this.userService.listObservable.subscribe(
          (data: any[]) => {
            this.listUsers = data.filter(function (user) {
              return user.id_usertype == role.id_usertype;
            })
          },
          error => console.log('error'),
          () => console.log('completeLoad')
        )

      }
    );
  }

  back() {
    this.location.back();
  }
}