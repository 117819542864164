import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'telemetryGroup'
})
export class TelemetryGroupPipe implements PipeTransform {

  transform(value: number): any {
    return environment.TELEMETRYGROUPLIST.find(x => x.value === value).label;
  }
}