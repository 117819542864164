import { Component } from '@angular/core';
import { Command } from '../models/command';
import { IModelObj } from "../models/iModelObj";
import { CommandCrudService } from '../services/command-crud.service';
import { AlertService } from '../services/alert.service';
import { SelectionModel } from '@angular/cdk/collections';
import { GenericListComponent } from '../generic-list/generic-list.component';
import { COMMANDTYPELIST } from '../models/mock-string-list';

@Component({
  selector: 'app-command-list',
  templateUrl: './command-list.component.html',
  styleUrls: ['./command-list.component.scss'],
})
export class CommandListComponent extends GenericListComponent<Command,number> {
  prop: string;
  commandTypeList= COMMANDTYPELIST;
  displayedColumns: string[] = ['id_cmd','name','can_index','can_sub_index','idnode','default_enable'];

  constructor(
    protected crudService: CommandCrudService,
    protected alertService: AlertService
  ) { 
    super(alertService,crudService)
  }

  ngOnInit(){
    super.ngOnInit();
      this.prop = 'id_cmd';
  }  
}
