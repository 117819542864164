import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { SelectedParameterService } from '../services/selected-parameter.service';
import { Param } from '../models/param';
import { first } from 'rxjs/operators';
import { ParamModelService } from '../services/param-model.service';
import { ParamTelemetryService } from '../services/param-telemetry.service';

@Component({
  selector: 'app-parameter-more',
  templateUrl: './parameter-more.component.html'
})
export class ParameterMoreComponent implements OnInit {
  public param: Param;

  constructor(
    protected selectedParameter: SelectedParameterService,
    protected location: Location,
    public paramModelService: ParamModelService,
    public paramTelemetryService: ParamTelemetryService
  ) { }

  ngOnInit() {
    this.selectedParameter.paramCurrent.pipe(first()).subscribe(
      param => {
        this.param = param;
      }
    );
  }

  back() {
    this.location.back();
  }
}