import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { GenericCrudComponent } from '../generic-crud/generic-crud.component';
import { ModelLevelSettingsDialogComponent } from '../model-level-settings-dialog/model-level-settings-dialog.component';
import { Model } from '../models/model';
import { ModelLevel } from '../models/modelLevel';
import { UserType } from '../models/userType';
import { AlertService } from '../services/alert.service';
import { ModelCrudService } from '../services/model-crud.service';
import { ModelLevelCrudService } from '../services/model-level-crud.service';
import { UserTypeCrudService } from '../services/user-type-crud.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-model-level-settings',
  templateUrl: './model-level-settings.component.html',
  styleUrls: ['./model-level-settings.component.scss']
})
export class ModelLevelSettingsComponent extends GenericCrudComponent<ModelLevel, number>  {
  @Input() idModel: number = null;
  @Input() idUserType: number = null;

  listModels: Model[];
  listUserType: UserType[];
  displayedColumns: string[] = ['editing', 'id_modelbylevel', 'id_model', 'id_usertype'];

  constructor(
    protected crudService: ModelLevelCrudService,
    protected modelService: ModelCrudService,
    protected userTypeService: UserTypeCrudService,
    protected alertService: AlertService,
    public dialog: MatDialog,
    protected http: HttpClient
  ) {
    super(crudService, alertService, dialog)
  }

  ngOnInit() {

    this.modelService.listObservable.subscribe(
      (data: any[]) => {
        this.listModels = data;
      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )
    this.userTypeService.listObservable.subscribe(
      (data: any[]) => {
        this.listUserType = data;
      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )
    this.load();
    super.ngOnInit();
  }

  load() {
    return this.crudService.listObservable.subscribe(
      (data: ModelLevel[]) => {

        if (this.idModel != undefined && this.idModel !== null) {
          data = data.filter(x => {
            return x.id_model == this.idModel;
          })
        };

        if (this.idUserType != undefined && this.idUserType != null) {
          data = data.filter(x => {
            return x.id_usertype == this.idUserType;
          })
        };

        this.listElements = data;
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.applyFilter(this.fil);
      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )
  }

  more(row: ModelLevel): void {
    throw new Error('Method not implemented.');
  }

  edit(row: ModelLevel): void {
    this.showEditDialog(ModelLevelSettingsDialogComponent, row);
  }
  showAddDialog(): void {
    this.showEditDialog(ModelLevelSettingsDialogComponent, null);
  }

  showEditDialog(dialog: any, element: ModelLevel, width: number = 400) {
    const dialogRef = this.dialog.open(dialog, {
      width: width + 'px',
      data: {
        listElements: this.listElements,
        element: element,
        idModel: this.idModel,
        idUserType: this.idUserType
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.reload();
    });
  }

  download(row: ModelLevel) {
    this.crudService.download(row.id_modelbylevel).subscribe(data => this.downloadFile(data, row.id_modelbylevel + ".xml")),//console.log(data),
      error => console.log('Error downloading the file.'),
      () => console.info('OK');
  }

  downloadFile(data: Response, name_fw: string) {
    saveAs(data, name_fw);
  }
}