import { Pipe, PipeTransform } from '@angular/core';
import { Command } from '../models/command';

@Pipe({
  name: 'command'
})
export class CommandPipe implements PipeTransform {

   transform(commandList: Command[], id_cmd: number): any {
      return commandList.find(command => command.id_cmd === id_cmd);
    }

}
