import { Component, ChangeDetectorRef } from '@angular/core';
import { MachineTestingComponent } from '../machine-testing/machine-testing.component';
import { SelectedMachineService } from '../services/selected-machine.service';
import { ModelCrudService } from '../services/model-crud.service';
import { ModelTelemetryService } from '../services/model-telemetry.service';
import { TelemetryGroup } from '../models/telemetryGroup';
import { from, of, Subscription } from 'rxjs';
import { switchMap, delay } from 'rxjs/operators';
import * as signalR from "@aspnet/signalr";
import { ConnectionSignalRService } from '../services/connection-signal-r.service';
import { RespGetData } from '../models/respGetData';
import { signalRInvocation, signalRSubscription } from '../models/signalRTopic';
import { AlertService } from '../services/alert.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-machine-telemetry',
  templateUrl: './machine-telemetry.component.html',
  styleUrls: ['./machine-telemetry.component.scss']
})
export class MachineTelemetryComponent extends MachineTestingComponent {
  public listTelGroup: TelemetryGroup[] = [];
  public telInterval: number = 5;
  public telIntervalStop: number = 30;
  public idnode: string = "AQ==";
  public idNodeList = environment.IDNODELIST;
  public listResp: RespGetData[] = [];
  public telType: number = 0;
  first = true;
  public telemetryOn: string[] = [];
  telObsSub: Subscription

  constructor(
    public selectedMachine: SelectedMachineService,
    public modelService: ModelCrudService,
    protected modelTelemetryService: ModelTelemetryService,
    public connectionSignalR: ConnectionSignalRService,
    public alertService: AlertService,
    private cd: ChangeDetectorRef
  ) {
    super(selectedMachine, modelService, alertService, connectionSignalR);
  }

  ngOnInit() {
    super.ngOnInit();
    this.modelTelemetryService.getEntityList(this.selectedMachine.machineSub.value.id_model).
      pipe(switchMap((x: TelemetryGroup[]) => from(x))).
      subscribe(
        (data: TelemetryGroup) => {

          var telGr = data;
          if(telGr.type==0){
            this.listTelGroup.push(telGr);
          }

        },
        error => console.log('error'),
        () => console.log('completeLoad')
      )
  }

  clean() {
    this.listResp = [];
  }

  stopTel(node: string) {
    if (this.connectionSignalR.connectionSub.value.state == signalR.HubConnectionState.Connected) {
      this.connectionSignalR.connectionSub.value.invoke(signalRInvocation.stopGetDataTelemetry, this.selectedMachine.machineSub.value.serial_number, node).then(
        () => {
          this.telemetryOn = this.telemetryOn.filter((value) => { return value != node });
          //this.telemetryOn
          this.telObsSub.unsubscribe();
          console.log('stopTelemetry');
        }).catch((reason: any) => {
          if (reason != null) {
            console.log(reason.message);
            this.alertService.error(reason.message.split("Exception: ")[1]);
          }
        });
    }
  }

  setTelemetry() {
    this.subscribe();
    if (this.connectionSignalR.connectionSub.value.state == signalR.HubConnectionState.Connected) {
      this.connectionSignalR.connectionSub.value.invoke(signalRInvocation.setTelemetryTab, this.selectedMachine.machineSub.value.serial_number, this.telType).then(
        () => {
          console.log('setTelemetryTab');
        }).catch((reason: any) => {
          if (reason != null) {
            console.log(reason.message);
            this.alertService.error(reason.message.split("Exception: ")[1]);
          }
        });
    }
  }

  subscribe() {
    if (this.first) {
      this.interceptDisconnection();
      this.first = false;

      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendStopGetDataTelemetry, (resp: string) => {
        this.alertService.info(resp);
      });

      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendGenericError, (resp: string) => {
        this.alertService.error(resp);
      });

      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendGetDataTelemetry, (resp: string) => {
        //  this.selectedMachine.machineSub.value.isonline = status;
        var r = new RespGetData();
        r.date = new Date();
        r.fields = JSON.parse(resp);
        this.listResp.push(r);
        console.info('Telemetry data have just been received');
        //this.cd.markForCheck();
        //this.cd.detectChanges();
      });

      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendTelemetryTabData, (resp: string) => {
        //  this.selectedMachine.machineSub.value.isonline = status;
        var r = new RespGetData();
        r.date = new Date();
        if (resp === "\0") {
          r.code = "0"
        } else {
          r.code = resp;
        }
        this.listResp.push(r);
        //this.cd.markForCheck();
        //this.cd.detectChanges();
      });

      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendGetDataActivity, (resp: string) => {
        //  this.selectedMachine.machineSub.value.isonline = status;
        var r = new RespGetData();
        r.date = new Date();
        r.fields = JSON.parse(resp);
        this.listResp.push(r);
        //this.cd.markForCheck();
        //this.cd.detectChanges();
      });

      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendActivityTabData, (resp: string) => {
        //  this.selectedMachine.machineSub.value.isonline = status;
        var r = new RespGetData();
        r.date = new Date();
        if (resp === "\0") {
          r.code = "0"
        } else {
          r.code = resp;
        }
        this.listResp.push(r);
        //this.cd.markForCheck();
        //this.cd.detectChanges();
      });
    }
  }

  startTel() {
    this.subscribe();
    if (this.telemetryOn.length > 2) {
      this.alertService.error("Maximum number of simultaneous telemetry has been reached");
    }
    else if (this.telemetryOn.findIndex(val => this.idnode == val) > -1) {
      this.alertService.error("Telemetry just running");
    }
    else {
      this.telemetryOn.push(this.idnode);
      var node = this.idnode;
      this.telObsSub = of(this.telInterval).pipe(delay(10000 + this.telIntervalStop * 1000)).subscribe(
        x => {
          this.telemetryOn = this.telemetryOn.filter((value) => { return value != node });
          console.log("finishedTelemetry");
        }
      );

      if (this.connectionSignalR.connectionSub.value.state == signalR.HubConnectionState.Connected) {
        this.connectionSignalR.connectionSub.value.invoke(signalRInvocation.startGetDataTelemetry, this.selectedMachine.machineSub.value.serial_number, this.idnode, this.telInterval, this.telIntervalStop)
          .then(() => console.log('startTelemetry')).catch((reason: any) => {
            if (reason != null) {
              console.log(reason.message);
              this.alertService.error(reason.message.split("Exception: ")[1]);
            }
          });
      }
    }
  }

}
