import { Component, Inject } from '@angular/core';
import { FormBuilder, AbstractControl, Validators } from '@angular/forms';
import { Param } from '../models/param';
import { ParameterCrudService } from '../services/parameter-crud.service';
import { AlertService } from '../services/alert.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GenericSettingsDialogComponent } from '../generic-settings-dialog/generic-settings-dialog.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-parameter-settings-dialog',
  templateUrl: './parameter-settings-dialog.component.html'
})
export class ParameterSettingsDialogComponent extends GenericSettingsDialogComponent<Param, number> {

  constructor(
    protected paramService: ParameterCrudService,
    protected alertService: AlertService,
    public dialogRef: MatDialogRef<ParameterSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected fb: FormBuilder
  ) {
    super(paramService, alertService, dialogRef, data, fb)
  }

  public idNodeList = environment.IDNODELIST;
  ngOnInit() {
    super.ngOnInit();
    this.title = this.isNew ? "Create new parameter" : "Modify parameter";
    this.form = this.fb.group({
      param_nameCtrl: [this.isNew ? '' : this.entity.name, Validators.compose([Validators.required, (c) => this.newParameterValidator(c, this.entityList, this.entity)])],
      param_addressCtrl: [this.isNew ? '' : this.entity.address],
      param_idnodeCtrl: this.isNew ? '' : this.entity.idnode,
      param_updateCtrl: this.isNew ? false : this.entity.update,
      param_sdo_segmentedCtrl: this.isNew ? false : this.entity.sdo_segmented,
      param_minCtrl: this.isNew ? null : this.entity.min,
      param_maxCtrl: this.isNew ? null : this.entity.max,
      param_applymaskCtrl: this.isNew ? null : this.entity.applymask,
      param_typeCtrl: this.isNew ? '' : this.entity.type,
      param_all_modelsCtrl: this.isNew ? false : this.entity.all_models
    });
  }

  update(param: Param) {
    param.name = this.form.get('param_nameCtrl').value;
    param.address = this.form.get('param_addressCtrl').value,
      param.idnode = this.form.get('param_idnodeCtrl').value,
      param.update = this.form.get('param_updateCtrl').value,
      param.sdo_segmented = this.form.get('param_sdo_segmentedCtrl').value,
      param.min = this.form.get('param_minCtrl').value,
      param.max = this.form.get('param_maxCtrl').value,
      param.applymask = this.form.get('param_applymaskCtrl').value,
      param.type = this.form.get('param_typeCtrl').value,
      param.id = param.id_param;
      param.all_models = this.form.get('param_all_modelsCtrl').value,


    this.paramService.update(this.entity)
      .subscribe(
        (data) => {
          this.alertService.info("Parameter has been successfully updated");
          this.dialogRef.close(true);
        },
        error => {
          console.log(error)
          this.alertService.error(error.error);
        }
      );
  }

  add() {
    var param: Param = {
      id: undefined,
      id_param: undefined,
      name: this.form.get('param_nameCtrl').value,
      address: this.form.get('param_addressCtrl').value,
      idnode: this.form.get('param_idnodeCtrl').value,
      update: this.form.get('param_updateCtrl').value,
      sdo_segmented: this.form.get('param_sdo_segmentedCtrl').value,
      min: this.form.get('param_minCtrl').value,
      max: this.form.get('param_maxCtrl').value,
      applymask: this.form.get('param_applymaskCtrl').value,
      type: this.form.get('param_typeCtrl').value,
      date_del: null,
      date_upd: null,
      all_models: this.form.get('param_all_modelsCtrl').value
    };
    this.paramService.create(param)
      .subscribe(
        (data) => {
          this.alertService.info("Parameter has been successfully added");
          this.dialogRef.close(true);
        },
        error => {
          console.log(error)
          this.alertService.error(error.error);
        }
      );
  }

  delete() {
    var param_temp = this.entity;
    param_temp.date_del = new Date();
    this.update(param_temp);
  }

  restore() {
    var param_temp = this.entity;
    param_temp.date_del = null;
    this.update(param_temp);

  }

  newParameterValidator(c: AbstractControl, listParam: Param[], param: Param): { [s: string]: boolean } {
    var currentIdx = -2;
    if (listParam.length > 0) {
      var index = listParam.map(function (d) { return d.name; }).indexOf(c.value)
      if (param) {
        currentIdx = listParam.map(function (d) { return d.name }).indexOf(param.name);
      }
      if (index >= 0 && index != currentIdx) {
        return { nameJustInUse: index >= 0 };
      }
    }
  }
}
