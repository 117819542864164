import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertService } from '../services/alert.service'
import { from, Subscription } from 'rxjs';
import { Alert } from '../models/alert';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  private subscription: Subscription;
  message: Alert;

  constructor(private _snackBar: MatSnackBar, private alertService: AlertService) { }

  ngOnInit() {
    this.subscription = this.alertService.getMessage().subscribe((message) => {
      if (message != undefined) {
        console.log(JSON.stringify(message));
        this.message = message;
        this._snackBar.open(this.message.message, this.message.duration == null ? 'Close' : null, {
          duration: this.message.duration,
          panelClass: [this.message.type]
        });
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
