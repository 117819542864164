import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        height: 'auto',
        opacity: 1,
      })),
      state('closed', style({
        height: '0px',
        opacity: 0,
      })),
      transition('open => closed', [
        animate('0s')
      ]),
      transition('closed => open', [
        animate('0s')
      ]),
    ]),
  ],
  styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent implements OnInit {
  messages: any[] = [];
  
  constructor(
    public dialogRef: MatDialogRef<MessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.data.messages.forEach(element => {
      this.messages.push({
        message: element,
        opened: true
      });
    });
  }

  confirm() {
    this.dialogRef.close({ "success": true });
  }

  toggle(message: any) {
    message.opened = !message.opened;
  }

  getColor(type: string) {
    switch (type) {
      case "info_new":
        return "#4caf50";
        break;
      case "info_upd":
        return "#455a64";
        break;
      case "warn":
        return "#ff9800";
        break;

      default:
        return "#000000"
        break;
    }
  }
}