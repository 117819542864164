import { Injectable } from '@angular/core';
import { GenericCrudService } from './generic-crud.service';
import { CommandParam } from '../models/commandParam';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class CommandParamCrudService extends GenericCrudService<CommandParam, number> {

  constructor(http: HttpClient,
    appConfigService: AppConfigService) {
    super(http, appConfigService, "commandParams");
    this.load();
  }
}