import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserLogged } from '../models/user-logged';
import { AppConfigService } from './app-config.service';
import * as moment from "moment";

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<UserLogged>;
    public currentUser: Observable<UserLogged>;

    constructor(private http: HttpClient, private appConfigService: AppConfigService) {
        this.currentUserSubject = new BehaviorSubject<UserLogged>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): UserLogged {
        return this.currentUserSubject.value;
    }

    login(username: string, password: string) {
        return this.http.post<any>(this.appConfigService.apiBaseUrl + `/login`, { username, password })
            .pipe(map(jwt => {
                console.log('ok');
                // login successful if there's a jwt token in the response
                if (jwt && jwt.token) {
                    console.log(jwt.token);
                    let us = new UserLogged(jwt.token);
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(us));
                    this.currentUserSubject.next(us);
                }
                else {
                    console.log('Token error');
                }
                return true;
            },
                error => {
                    console.log('error');
                },

            ));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        console.log('logout');
    }

    getExpiration() {
        const expiration = localStorage.getItem("currentUser")['exp'];
        const expiresAt = JSON.parse(expiration);
        return moment(expiresAt);
    }

    public isLoggedIn() {
        return moment().isBefore(this.getExpiration());
    }
}