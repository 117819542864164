import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import * as signalR from '@aspnet/signalr';
import { MachineIoComponent } from '../machine-io/machine-io.component';
import { DigitalEventCounter } from '../models/digitalEventCounter';
import { signalRInvocation, signalRSubscription } from '../models/signalRTopic';
import { AlertService } from '../services/alert.service';
import { ConnectionSignalRService } from '../services/connection-signal-r.service';
import { ModelCrudService } from '../services/model-crud.service';
import { SelectedMachineService } from '../services/selected-machine.service';

@Component({
  selector: 'app-machine-event-counter',
  templateUrl: './machine-event-counter.component.html',
  styleUrls: ['./machine-event-counter.component.scss']
})
export class MachineEventCounterComponent extends MachineIoComponent<DigitalEventCounter>{
  displayedColumns: string[] = ['select', 'number', 'counterEdge', 'counter']

  constructor(
    public selectedMachine: SelectedMachineService,
    public modelService: ModelCrudService,
    public connectionSignalR: ConnectionSignalRService,
    public alertService: AlertService
  ) {
    super(selectedMachine, modelService, connectionSignalR, alertService);
    this.signalRSendPubGet = signalRSubscription.sendPubGetDigEventCounters;
    this.signalRSendPubSet = signalRSubscription.sendPubSetDigEventCounters;
    this.signalRinvokeGet = signalRInvocation.getDigEventCounters;
  }

  ngOnInit() {
  }

  setCounterEdge(input) {
    var a = this.list.find((x) => { return x.number == input.number });
    a.counterEdge = (a.counterEdge + 1) % 4;
    this.dataSource = new MatTableDataSource(this.list);
  }

  reset() {
    this.subscribe();
    if (this.connectionSignalR.connectionSub.value.state == signalR.HubConnectionState.Connected) {
      let list = [];
      this.selection.selected.forEach((x) => {
        list.push(
          {
            set: true,
            number: x.number
          }
        )
      });

      this.connectionSignalR.connectionSub.value.invoke(signalRInvocation.resetDigCounters, this.selectedMachine.machineSub.value.serial_number, list).then(
        () => {
          console.log("resetDigCounters");

        }).catch((reason: any) => {
          if (reason != null) {
            console.log(reason.message);
            this.alertService.error(reason.message.split("Exception: ")[1]);
          }
        });
    }
  }

  setVal() {
    this.subscribe();
    if (this.connectionSignalR.connectionSub.value.state == signalR.HubConnectionState.Connected) {
      let digitalEventCounterList = [];
      this.selection.selected.forEach((x) => {
        digitalEventCounterList.push(
          {
            counterEdge: x.counterEdge,
            set: true,
            number: x.number
          }
        )
      });

      this.connectionSignalR.connectionSub.value.invoke(signalRInvocation.setDigEventCounters, this.selectedMachine.machineSub.value.serial_number, digitalEventCounterList).then(
        () => {
          console.log("setDigEventCounters");

        }).catch((reason: any) => {
          if (reason != null) {
            console.log(reason.message);
            this.alertService.error(reason.message.split("Exception: ")[1]);
          }
        });
    }
  }
}
