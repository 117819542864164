import { Component } from '@angular/core';
import { GenericCrudComponent } from '../generic-crud/generic-crud.component';
import { TelemetryGroupCrudService } from '../services/telemetry-group-crud.service';
import { AlertService } from '../services/alert.service';
import { SelectedTelemetryGroupService } from '../services/selected-telemetry-group.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TelemetryGroup } from '../models/telemetryGroup';
import { TelemetryGroupSettingsDialogComponent } from '../telemetry-group-settings-dialog/telemetry-group-settings-dialog.component';
import { ModelCrudService } from '../services/model-crud.service';
import { Model } from '../models/model';

@Component({
  selector: 'app-telemetry-group-settings',
  templateUrl: './telemetry-group-settings.component.html'
})
export class TelemetryGroupSettingsComponent extends GenericCrudComponent<TelemetryGroup, number> {
  listModels: Model[];
  displayedColumns: string[] = ['editing', 'id_group', 'descr_group', 'id_model', 'idnode', 'type', 'autoload'];

  constructor(
    protected crudService: TelemetryGroupCrudService,
    protected alertService: AlertService,
    protected selectedTelemetryGroup: SelectedTelemetryGroupService,
    protected modelService: ModelCrudService,
    protected router: Router,
    public dialog: MatDialog
  ) {
    super(crudService, alertService, dialog)
  }

  ngOnInit() {
    this.modelService.listObservable.subscribe(
      (data: any[]) => {
        this.listModels = data;
      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )
    super.ngOnInit();
  }

  more(row: TelemetryGroup) {
    this.selectedTelemetryGroup.setTelGroup(row);
    this.router.navigate([`telemetry-group-details`]);
  }

  edit(row: TelemetryGroup): void {
    this.showEditDialog(TelemetryGroupSettingsDialogComponent, row);
  }
  showAddDialog(): void {
    this.showEditDialog(TelemetryGroupSettingsDialogComponent, null);
  }
}