import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GenericCrudComponent } from '../generic-crud/generic-crud.component';
import { SignalRConnection } from '../models/signalRConnection';
import { AlertService } from '../services/alert.service';
import { DebugCrudService } from '../services/debug-crud.service';

@Component({
  selector: 'app-machine-debug',
  templateUrl: './machine-debug.component.html',
  styleUrls: ['./machine-debug.component.scss']
})
export class MachineDebugComponent extends GenericCrudComponent<SignalRConnection, string>
{
  displayedColumns: string[] = ['editing', 'connection_id', 'group', 'date_add', 'serial_number'];

  constructor(
    protected crudService: DebugCrudService,
    protected alertService: AlertService,
    public dialog: MatDialog
  ) {
    super(crudService, alertService, dialog)
  }

  edit(row: SignalRConnection): void {
    throw new Error('Method not implemented.');
  }
  showAddDialog(): void {
    throw new Error('Method not implemented.');
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  delete(id: string) {
    this.crudService.delete(id).subscribe(
      aaa => {
        this.alertService.info('Connection successfully deleted');
        this.reload();
      }
    );
  }
}
