import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { UserType } from '../models/userType';
import { UserTypeCrudService } from '../services/user-type-crud.service';
import { AlertService } from '../services/alert.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GenericSettingsDialogComponent } from '../generic-settings-dialog/generic-settings-dialog.component';

@Component({
  selector: 'app-user-type-settings-dialog',
  templateUrl: './user-type-settings-dialog.component.html'
})
export class UserTypeSettingsDialogComponent extends GenericSettingsDialogComponent<UserType, number> {

  constructor(
    protected userTypeService: UserTypeCrudService,
    protected alertService: AlertService,
    public dialogRef: MatDialogRef<UserTypeSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected fb: FormBuilder
  ) {
    super(userTypeService, alertService, dialogRef, data, fb)
  }

  ngOnInit() {
    super.ngOnInit();
    this.title = "Modify role";
    this.form = this.fb.group({
      role_descriptionCtrl: [this.isNew ? '' : this.entity.description, Validators.compose([Validators.required, (c) => this.newUserTypeValidator(c, this.entityList, this.entity)])],
      role_viewCtrl: [this.isNew ? false : this.entity.pView > 0],
      role_editCtrl: [this.isNew ? false : this.entity.pEdit > 0],
      role_fullCtrl: [this.isNew ? false : this.entity.pFull > 0],
      role_adminCtrl: [this.isNew ? false : this.entity.isAdmin > 0],
      role_levelCtrl: [this.isNew ? 0 : this.entity.user_lvl],
    });

  }

  newUserTypeValidator(c: AbstractControl, listUser: UserType[], user: UserType): { [s: string]: boolean } {
    var currentIdx = -2;
    if (listUser.length > 0) {
      var index = listUser.map(function (d) { return d.description; }).indexOf(c.value)
      if (user) {
        currentIdx = listUser.map(function (d) { return d.description }).indexOf(user.description);
      }
      if (index >= 0 && index != currentIdx) {
        return { descriptionJustInUse: index >= 0 };
      }
    }
  }

  update(userType: UserType) {
    userType.description = this.form.get('role_descriptionCtrl').value;
    userType.pView = this.form.get('role_viewCtrl').value ? 1 : 0;
    userType.pEdit = this.form.get('role_editCtrl').value ? 1 : 0;
    userType.pFull = this.form.get('role_fullCtrl').value ? 1 : 0;
    userType.isAdmin = this.form.get('role_adminCtrl').value ? 1 : 0;
    userType.user_lvl = this.form.get('role_levelCtrl').value;
    userType.id = userType.id_usertype;

    this.userTypeService.update(userType)
      .subscribe(
        (data) => {
          this.alertService.info("Role has been successfully updated");
          this.dialogRef.close(true);
        },
        error => {
          console.log(error)
          this.alertService.error(error.error);
        }
      );
  }

  add(): void {
    throw new Error("Method not implemented.");
  }
  delete(): void {
    throw new Error("Method not implemented.");
  }
}