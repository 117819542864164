import { Component } from '@angular/core';
import { AlertService } from '../services/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { Model } from '../models/model';
import { GenericCrudComponent } from '../generic-crud/generic-crud.component';
import { ModelCrudService } from '../services/model-crud.service';
import { User } from '../models/user';
import { UserCrudService } from '../services/user-crud.service';
import { ModelSettingsDialogComponent } from '../model-settings-dialog/model-settings-dialog.component';
import { SelectedModelService } from '../services/selected-model.service';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { UserPipe } from '../pipes/user.pipe';

@Component({
  selector: 'app-model-settings',
  templateUrl: './model-settings.component.html',
  styleUrls: ['./model-settings.component.scss']
})

export class ModelSettingsComponent extends GenericCrudComponent<Model, number> {
  displayedColumns: string[] = ['editing', 'id_model', 'name', 'maker', 'date_auto', 'date_upd', 'second_periodic_report', 'date_del'];
  listUsers: User[];

  constructor(
    protected userService: UserCrudService,
    protected crudService: ModelCrudService,
    protected alertService: AlertService,
    protected selectedModel: SelectedModelService,
    protected router: Router,
    public dialog: MatDialog
  ) {
    super(crudService, alertService, dialog)
  }

  ngOnInit() {
    this.filterString = 'modelFilter';
    this.userService.listObservable.subscribe(
      (data: any[]) => {
        this.listUsers = data;
      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )
    super.ngOnInit();
  }

  more(row: Model) {
    super.more(row);
    this.selectedModel.setModel(row);
    this.router.navigate([`model-details`]);
  }

  edit(row: Model): void {
    this.showEditDialog(ModelSettingsDialogComponent, row);
  }
  showAddDialog(): void {
    this.showEditDialog(ModelSettingsDialogComponent, null);
  }

  download(row: Model) {
    this.crudService.download(row.id_model).subscribe(data => this.downloadFile(data, row.name + ".xml")),//console.log(data),
      error => console.log('Error downloading the file.'),
      () => console.info('OK');
  }

  loadParameters(row: Model) {
    this.crudService.loadParameters(row.id_model).subscribe(
      (data) => {
        const modal = this.dialog.open(MessageDialogComponent, {
          minWidth: '400px',
          data: {
            messages: data
          }
        });
      },
      error => {
        console.log(error)
        this.alertService.error(error.error);
      }
    );
  }

  downloadFile(data: Response, name_fw: string) {
    saveAs(data, name_fw);
  }

  setCustomDataAccessor(){
    let user = new UserPipe();
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'maker':
        return user.transform(this.listUsers,item.id_maker)?.name;
        default:
        return item[property];
      }
    };
  }
}
