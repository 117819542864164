import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../models/user';

@Pipe({
  name: 'user'
})
export class UserPipe implements PipeTransform {

  transform(userList: User[], id_user: number): any {
    return userList.find(user => user.id_user === id_user);
  }
}