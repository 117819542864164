import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(list: any[], value: any, property: string): any {
    return list.filter(elem => elem[property] == value);
  }
}