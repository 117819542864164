import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import * as signalR from '@aspnet/signalr';
import { MachineIoComponent } from '../machine-io/machine-io.component';
import { DigitalInput } from '../models/digitalInput';
import { signalRInvocation, signalRSubscription } from '../models/signalRTopic';
import { AlertService } from '../services/alert.service';
import { ConnectionSignalRService } from '../services/connection-signal-r.service';
import { ModelCrudService } from '../services/model-crud.service';
import { SelectedMachineService } from '../services/selected-machine.service';


@Component({
  selector: 'app-machine-digital-input',
  templateUrl: './machine-digital-input.component.html',
  styleUrls: ['./machine-digital-input.component.scss']
})
export class MachineDigitalInputComponent extends MachineIoComponent<DigitalInput> {
  displayedColumns: string[] = ['select', 'number', 'type', 'active', 'activeState']
  eventCounterList: number[] = [3, 4, 5, 6];

  constructor(
    public selectedMachine: SelectedMachineService,
    public modelService: ModelCrudService,
    public connectionSignalR: ConnectionSignalRService,
    public alertService: AlertService
  ) {
    super(selectedMachine, modelService, connectionSignalR, alertService);
    this.signalRSendPubGet = signalRSubscription.sendPubGetDigInputs;
    this.signalRSendPubSet = signalRSubscription.sendPubSetDigInputs;
    this.signalRinvokeGet = signalRInvocation.getDigInputs;
  }

  ngOnInit() {
  }

  setActiveStateValue(input) {
    var a = this.list.find((x) => { return x.number == input.number });
    a.activeState = a.activeState === 0 ? 1 : 0;
    this.dataSource = new MatTableDataSource(this.list);
  }

  setTypeValue(input) {
    var a = this.list.find((x) => { return x.number == input.number });
    a.type = (a.type + 1) % 4;
    this.dataSource = new MatTableDataSource(this.list);
  }

  setActiveValue(input, event) {
    var a = this.list.find((x) => { return x.number == input.number });
    a.active = event.checked;
    this.dataSource = new MatTableDataSource(this.list);
  }

  setVal() {
    this.subscribe();
    if (this.connectionSignalR.connectionSub.value.state == signalR.HubConnectionState.Connected) {
      let digInputList = [];
      this.selection.selected.forEach((x) => {
        digInputList.push(
          {
            type: x.type,
            set: true,
            number: x.number,
            activeState: x.activeState
          }
        )
      });

      this.connectionSignalR.connectionSub.value.invoke(signalRInvocation.setDigInputs, this.selectedMachine.machineSub.value.serial_number, digInputList).then(
        () => {
          console.log("setDigInputs");

        }).catch((reason: any) => {
          if (reason != null) {
            console.log(reason.message);
            this.alertService.error(reason.message.split("Exception: ")[1]);
          }
        });
    }
  }
}


