import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { TelemetryGroup } from '../models/telemetryGroup';
import { TelemetryGroupCrudService } from '../services/telemetry-group-crud.service';
import { AlertService } from '../services/alert.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GenericSettingsDialogComponent } from '../generic-settings-dialog/generic-settings-dialog.component';
import { environment } from 'src/environments/environment';
import { ModelCrudService } from '../services/model-crud.service';
import { Model } from '../models/model';

@Component({
  selector: 'app-telemetry-group-settings-dialog',
  templateUrl: './telemetry-group-settings-dialog.component.html'
})
export class TelemetryGroupSettingsDialogComponent extends GenericSettingsDialogComponent<TelemetryGroup, number> {
  public idNodeList = environment.IDNODELIST;
  public telemetryTypeList = environment.TELEMETRYGROUPLIST;
  listModels: Model[];

  constructor(
    protected telGroupService: TelemetryGroupCrudService,
    protected alertService: AlertService,
    protected modelService: ModelCrudService,
    public dialogRef: MatDialogRef<TelemetryGroupSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected fb: FormBuilder
  ) {
    super(telGroupService, alertService, dialogRef, data, fb)
  }

  ngOnInit() {
    super.ngOnInit();
    this.title = this.isNew ? "Create new telemetry group" : "Rename telemetry group";
    this.form = this.fb.group({
      telGroup_descrCtrl: [this.isNew ? '' : this.entity.descr_group, Validators.compose([Validators.required, (c) => this.newTelGroupValidator(c, this.entityList, this.entity)])],
      telGroup_idnodeCtrl: [this.isNew ? '' : this.entity.idnode, Validators.compose([Validators.required, (c) => this.newTelGroupValidator(c, this.entityList, this.entity)])],
      telGroup_idmodelCtrl: [this.isNew ? '' : this.entity.id_model, Validators.required],
      telGroup_typeCtrl: [this.isNew ? '' : "" + this.entity.type, Validators.compose([Validators.required, (c) => this.newTelGroupValidator(c, this.entityList, this.entity)])],
      telGroup_autoloadCtrl: [this.isNew ? false : this.entity.autoload],
    });
    this.modelService.listObservable.subscribe(
      (data: any[]) => {
        this.listModels = data;
      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )
  }

  update(telGroup: TelemetryGroup) {
    telGroup.descr_group = this.form.get('telGroup_descrCtrl').value;
    telGroup.idnode = (this.form.get('telGroup_idnodeCtrl').value);
    telGroup.id_model = (this.form.get('telGroup_idmodelCtrl').value);
    telGroup.type = Number(this.form.get('telGroup_typeCtrl').value);
    telGroup.id = telGroup.id_group;

    this.telGroupService.update(telGroup)
      .subscribe(
        (data) => {
          this.alertService.info("Telemetry group has been successfully updated");
          this.dialogRef.close(true);
        },
        error => {
          console.log(error)
          this.alertService.error(error.error);
        }
      );
  }

  add() {
    var telGr: TelemetryGroup = {
      id: undefined,
      id_group: undefined,
      descr_group: this.form.get('telGroup_descrCtrl').value,
      idnode: (this.form.get('telGroup_idnodeCtrl').value),
      type: Number(this.form.get('telGroup_typeCtrl').value),
      id_model: Number(this.form.get('telGroup_idmodelCtrl').value),
      autoload: false,
      date_upd: new Date(),
      params: [],
    };
    this.telGroupService.create(telGr)
      .subscribe(
        (data) => {
          this.alertService.info("Telemetry group has been successfully added");
          this.dialogRef.close(true);
        },
        error => {
          console.log(error)
          this.alertService.error(error.error);
        }
      );
  }

  delete() {
    this.telGroupService.delete(this.entity.id_group)
      .subscribe(
        (data) => {
          this.alertService.info("Telemetry group has been successfully removed");
          this.dialogRef.close(true);
        },
        error => {
          console.log(error)
          this.alertService.error(error.error);
        }
      );
  }

  newTelGroupValidator(c: AbstractControl, listTelGroup: TelemetryGroup[], telGroup: TelemetryGroup): { [s: string]: boolean } {
    var currentIdx = -2;
    if (listTelGroup.length > 0) {
      var index = listTelGroup.map(function (d) { return d.descr_group; }).indexOf(c.value)
      if (telGroup) {
        currentIdx = listTelGroup.map(function (d) { return d.descr_group }).indexOf(telGroup.descr_group);
      }
      if (index >= 0 && index != currentIdx) {
        return { descrJustInUse: index >= 0 };
      }
    }
  }
}