import { Component, Input } from '@angular/core';
import { GenericListComponent } from '../generic-list/generic-list.component';
import { TelemetryGroup } from '../models/telemetryGroup';
import { TelemetryGroupCrudService } from '../services/telemetry-group-crud.service';
import { AlertService } from '../services/alert.service';
import { ModelCrudService } from '../services/model-crud.service';
import { Model } from '../models/model';
import { MatTableDataSource } from '@angular/material/table';
import { ModelPipe } from '../pipes/model.pipe';

@Component({
  selector: 'app-telemetry-group-list',
  templateUrl: './telemetry-group-list.component.html',
  styleUrls: ['./telemetry-group-list.component.scss']
})
export class TelemetryGroupListComponent extends GenericListComponent<TelemetryGroup, number> {
  @Input() idNode: string = null;

  prop: 'id_group';
  listModels: Model[];
  displayedColumns: string[] = ['id_group', 'descr_group', 'model', 'idnode', 'type', 'autoload'];

  constructor(
    protected telemetryGroupService: TelemetryGroupCrudService,
    protected alertService: AlertService,
    protected modelService: ModelCrudService
  ) {
    super(alertService, telemetryGroupService)
  }

  ngOnInit() {
    this.prop = 'id_group';
    super.ngOnInit();
    this.modelService.listObservable.subscribe(
      (data: any[]) => {
        this.listModels = data;
      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )
  }

  filterListModify() {
    if (this.idNode != null) {
      var temp = this.entityList.filter(x => x.idnode == this.idNode);
      this.dataSource = new MatTableDataSource(temp);
      this.displayedColumns.splice(0, 0, "select");
    }
    else {
      super.filterListModify();
    }
  }

  isDisabled(row: TelemetryGroup) {
    if (row.autoload)
      var prop = this.prop;
    return !this.reducibleList && this.filteredList.findIndex(
      function (element) {
        return element[prop] === row[prop];
      }
    ) >= 0;
  }

  setCustomDataAccessor(){
    let user = new ModelPipe();
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'model':
        return user.transform(this.listModels,item.id_model)?.name;
        default:
        return item[property];
      }
    };
  }
}