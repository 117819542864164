import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { Alert } from '../models/alert';

@Injectable({ providedIn: 'root' })
export class AlertService {
    private subject = new Subject<any>();
    private keepAfterNavigationChange = false;

    constructor(private router: Router) {
        // clear alert message on route change
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterNavigationChange) {
                    // only keep for a single location change
                    this.keepAfterNavigationChange = false;
                } else {
                    // clear alert
                    this.subject.next();
                }
            }
        });
    }

    success(message: string, duration = null, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;

        this.subject.next(new Alert(message, 'snack-success', duration));
    }

    warning(message: string, duration = null, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next(new Alert(message, 'snack-warning', duration));
    }

    info(message: string, duration = 5000, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next(new Alert(message, 'snack-info', duration));
    }

    error(message: string, duration = null, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        let al = new Alert(message, 'snack-error', duration);
        this.subject.next(al);
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}