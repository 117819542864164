import { Component, OnInit } from '@angular/core';
import { UserCrudService } from '../services/user-crud.service';
import { UserTypeCrudService } from '../services/user-type-crud.service';
import { SelectedUserService } from '../services/selected-user.service';
import { User } from '../models/user';
import { UserType } from '../models/userType';
import { first } from 'rxjs/operators';
import { Location } from '@angular/common';
import { LANGLIST } from '../models/mock-string-list';
import { UserToUserCrudService } from '../services/user-to-user-crud.service';
import { Machine } from '../models/machine';
import { UserToMachineCrudService } from '../services/user-to-machine-crud.service';

@Component({
  selector: 'app-user-more',
  templateUrl: './user-more.component.html',
  providers: [
    //{ provide: IEntityListService, useClass: UserToUserCrudService },
    // { provide: IEntityListService, useClass: UserToMachineCrudService },
  ]
})
export class UserMoreComponent implements OnInit {
  user: User;
  roleList: UserType[];
  listUsers: User[];
  listMachines: Machine[];
  langList = LANGLIST;

  constructor(
    protected selectedUser: SelectedUserService,
    protected location: Location,
    public userTypeService: UserTypeCrudService,
    protected userService: UserCrudService,
    public userToUserService: UserToUserCrudService,
    public userToMachineService: UserToMachineCrudService
  ) { }

  ngOnInit() {
    this.userTypeService.listObservable.subscribe(
      (data: any[]) => {
        this.roleList = data;
      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )

    this.userService.listObservable.subscribe(
      (data: any[]) => {
        this.listUsers = data;
      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )

    this.selectedUser.userCurrent.pipe(first()).subscribe(
      user => {
        this.user = user;
      }
    );
  }

  back() {
    this.location.back();
  }
}