import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hexstring'
})
export class HexStringPipe implements PipeTransform {

  transform(value: string | number, byteLen: number = 1): any {
    if (value != null && value != undefined) {
      var val = "";
      var valASCii = ""
      if (typeof value === "number") {
        val = value.toString(16).toUpperCase().padStart(2 * byteLen, '0')
      }
      else {
        var valASCii = atob(value);
        for (var i = 0; i < valASCii.length; i++) {
          val += valASCii.charCodeAt(i).toString(16).toUpperCase().padStart(2 * byteLen, '0');
        }
      }
      return val;
    }
  }

  untransform(str1) {
    var hex = str1.toString();
    var str = '';
    for (var n = 0; n < hex.length; n += 2) {
      str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
    }
    return btoa(str);
  }
}