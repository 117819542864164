import { Pipe, PipeTransform } from '@angular/core';
import {UserType} from '../models/userType';

@Pipe({
  name: 'role'
})
export class RolePipe implements PipeTransform {

  transform(roleList:UserType[],value:number): any {
    return roleList.find(role=>role.id_usertype===value);
  }
}