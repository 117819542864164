import { Component, OnInit } from '@angular/core';
import { GenericListComponent } from '../generic-list/generic-list.component';
import { Geoloc } from '../models/geoloc';
import { AlertService } from '../services/alert.service';

@Component({
  selector: 'app-geolocation-list',
  templateUrl: './geolocation-list.component.html'
})
export class GeolocationListComponent extends GenericListComponent<Geoloc, number> {
  prop: "id_geolocation";
  displayedColumns: string[] = ['id_geolocation', 'lat', 'lng', 'alt', 'date'];

  constructor(
    //protected geolocationService :GeolocCrudService,
    protected alertService: AlertService
  ) {
    super(alertService, null);
    this.prop = 'id_geolocation';
  }

}
