import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-update-bar',
  templateUrl: './update-bar.component.html',
  styleUrls: ['./update-bar.component.scss']
})
export class UpdateBarComponent implements OnInit, OnChanges {

  @Input() numFailed = 0;
  @Input() numWarning = 0;
  @Input() other = 0;
  @Input() numSuccess = 0;
  @Input() numRunning = 0;
  total = 0;

  constructor() { }

  ngOnInit(): void {
    this.total= this.numFailed + this.numWarning +this.other + this.numSuccess + this.numRunning;
  }

  ngOnChanges(changes: SimpleChanges): void{
    this.ngOnInit();
  }

}
