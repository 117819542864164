import { Injectable } from '@angular/core';
import { GenericCrudService } from './generic-crud.service';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import { Command } from '../models/command';
import { CustomCommand } from '../models/customCommand';

@Injectable({
  providedIn: 'root'
})
export class CustomdCommandCrudService extends GenericCrudService<CustomCommand, number> {

  constructor(http: HttpClient,
    appConfigService: AppConfigService) {
    super(http, appConfigService, "customCommands");
    this.load();
  }
}