import { Injectable } from '@angular/core';
import { GenericCrudService } from '../services/generic-crud.service'
import { Model } from '../models/model';
import { AppConfigService } from './app-config.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ModelCrudService extends GenericCrudService<Model, number>{

  constructor(http: HttpClient,
    appConfigService: AppConfigService) {
    super(http, appConfigService, "models");
    this.load();
  }

  download(id_model: number): Observable<Response> {
    console.log(this.apiName);
    return this.http.get<Response>(`${this.endpoint}/${this.apiName}/${id_model}/jclient`
      , { responseType: 'blob' as 'json' },
    );
  }

  loadParameters(id_model: number): Observable<Response> {
    return this.http.post<any>(`${this.endpoint}/${this.apiName}/${id_model}/updateParametersFromXml`, null);
  }
}