import { Component, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { SelectedMachineService } from '../services/selected-machine.service';
import { ModelCrudService } from '../services/model-crud.service';
import { ConnectionSignalRService } from '../services/connection-signal-r.service';
import { MachineTestingComponent } from '../machine-testing/machine-testing.component';
import { RespGetData } from '../models/respGetData';
import { signalRInvocation, signalRSubscription } from '../models/signalRTopic';
import * as signalR from '@aspnet/signalr';
import { AlertService } from '../services/alert.service';
import { FirmwareIotCrudService } from '../services/firmware-iot-crud.service';
import { environment } from 'src/environments/environment';
import { log } from 'console';

@Component({
  selector: 'app-machine-env',
  templateUrl: './machine-env.component.html',
  styleUrls: ['./machine-env.component.scss']
})

export class MachineEnvComponent extends MachineTestingComponent {
  @Output() back: EventEmitter<any> = new EventEmitter();

  public env: string;
  public updateOn = false;
  protected first = true;
  public listEnv: any[];
  private changeEnv = false;
  changeSensitivity: boolean;
  sensitivity: number;

  constructor(
    public selectedMachine: SelectedMachineService,
    public modelService: ModelCrudService,
    public connectionSignalR: ConnectionSignalRService,
    public fwIoTService: FirmwareIotCrudService,
    public alertService: AlertService,
    private cd: ChangeDetectorRef
  ) {
    super(selectedMachine, modelService, alertService, connectionSignalR);
  }

  ngOnInit() {
    super.ngOnInit();
    this.listEnv = environment.ENVS.filter(x => x.active == true);
  }

  sensitivityOnChange(event: number){
   if(event < 0){
    this.sensitivity = 0;
   }
   if(event > 10){
    this.sensitivity = 0;
   }
  }

  setSensitivity() {
    this.changeSensitivity = true;
    this.subscribe();
    if (this.connectionSignalR.connectionSub.value.state == signalR.HubConnectionState.Connected) {
      this.connectionSignalR.connectionSub.value.invoke(signalRInvocation.setSensitivity, this.selectedMachine.machineSub.value.serial_number, this.sensitivity).then(
        () => {
        }).catch((reason: any) => {
          if (reason != null) {
            console.log(reason.message);
            this.alertService.error(reason.message.split("Exception: ")[1]);
          }
        });
    }
  }

  readSensivity(){
    this.subscribe();
    if (this.connectionSignalR.connectionSub.value.state == signalR.HubConnectionState.Connected) {
      this.connectionSignalR.connectionSub.value.invoke(signalRInvocation.getSensitivity, this.selectedMachine.machineSub.value.serial_number).then(
        () => {

        }).catch((reason: any) => {
          if (reason != null) {
            console.log(reason.message);
            this.alertService.error(reason.message.split("Exception: ")[1]);
          }
        });
    }
  }

  setEnv() {
    this.changeEnv = true;
    this.subscribe();
    if (this.connectionSignalR.connectionSub.value.state == signalR.HubConnectionState.Connected) {
      this.connectionSignalR.connectionSub.value.invoke(signalRInvocation.setEnv, this.selectedMachine.machineSub.value.serial_number, this.env).then(
        () => {
        }).catch((reason: any) => {
          if (reason != null) {
            console.log(reason.message);
            this.alertService.error(reason.message.split("Exception: ")[1]);
          }
        });
    }
  }

  readEnv() {
    this.subscribe();
    if (this.connectionSignalR.connectionSub.value.state == signalR.HubConnectionState.Connected) {
      this.connectionSignalR.connectionSub.value.invoke(signalRInvocation.getEnv, this.selectedMachine.machineSub.value.serial_number).then(
        () => {

        }).catch((reason: any) => {
          if (reason != null) {
            console.log(reason.message);
            this.alertService.error(reason.message.split("Exception: ")[1]);
          }
        });
    }
  }

  resetIot() {
    this.subscribe();
    if (this.connectionSignalR.connectionSub.value.state == signalR.HubConnectionState.Connected) {
      this.connectionSignalR.connectionSub.value.invoke(signalRInvocation.resetIot, this.selectedMachine.machineSub.value.serial_number).then(
        () => {

        }).catch((reason: any) => {
          if (reason != null) {
            console.log(reason.message);
            this.alertService.error(reason.message.split("Exception: ")[1]);
          }
        });
    }
  }

  subscribe() {
    if (this.first) {
      this.interceptDisconnection();

      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendConnectionStatus, (status: string) => {
        //  this.selectedMachine.machineSub.value.isonline = status;
        if (status === "0") {
          this.back.emit();
        }
      });

      this.first = false;

      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendGenericError, (resp: string) => {
        //  this.selectedMachine.machineSub.value.isonline = status;
        this.alertService.error(resp);
      });

      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendPubEnv, (resp: string) => {

        this.alertService.error("Environment string: " + resp);
        if (this.changeEnv) {
          this.back.emit();
        }
      });

      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendPubResetIot, (resp: string) => {
        this.alertService.error("Iot HW has been succesfully reset");
      });

      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendGetSensitivity, (par: string) => {

        this.alertService.error("Accelerometer sensitivity: " + par);
      });

      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendSetSensitivity, (par: string) => {
        this.alertService.error("Accelerometer settings: " + par);
      });
    }
  }
}
