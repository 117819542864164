export class DigitalEventCounter {
    counter: number;
    number: number;
    counterEdge: CounterEdge;
}

export enum CounterEdge {
    Disabled = 0,
    Fall = 1,
    Rise = 2,
    RiseFall = 3
}