
import {jwtDecode} from 'jwt-decode';

export class UserLogged {
    aud: number;
    email: string;
    role: string;
    exp: Date;
    iss: string;
    sub: string;
    token: string;

    constructor(token: string) {
        let tokenInfo = this.getDecodedAccessToken(token); // decode token
        //console.log(tokenInfo);
        this.token = token;
        this.aud = tokenInfo.aud;
        this.email = tokenInfo.email;
        this.role = tokenInfo['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
        this.exp = new Date(tokenInfo.exp);
        this.iss = tokenInfo.iis;
        this.sub = tokenInfo.sub;
    }

    getDecodedAccessToken(token: string): any {
        try {
            return jwtDecode(token);
        }
        catch (Error) {
            return null;
        }
    }
}
