import { Pipe, PipeTransform } from '@angular/core';
import { CounterEdge } from '../models/digitalEventCounter';

@Pipe({
  name: 'counterEdgeTooltip'
})
export class CounterEdgeTooltipPipe implements PipeTransform {

  transform(value: CounterEdge): any {
    switch (value) {
      case CounterEdge.Disabled:
        return "Disabled";
      case CounterEdge.Fall:
        return "Fall";
      case CounterEdge.Rise:
        return "Rise";
      case CounterEdge.RiseFall:
        return "Rise/Fall";
    }
  }
}