import { Param } from './param';

export interface IField {
    value: string;
    parameter: Param;
    byteVal: string;
}

export class Field implements IField {
    value: string;
    parameter: Param;
    byteVal: string;
    errorCode: number;
}