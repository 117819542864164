import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import * as signalR from "@aspnet/signalr";
import { Subscription, from, of } from "rxjs";
import { switchMap, delay } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { MachineTestingComponent } from "../machine-testing/machine-testing.component";
import { RespGetData } from "../models/respGetData";
import { signalRInvocation, signalRSubscription } from "../models/signalRTopic";
import { TelemetryGroup } from "../models/telemetryGroup";
import { AlertService } from "../services/alert.service";
import { ConnectionSignalRService } from "../services/connection-signal-r.service";
import { ModelCrudService } from "../services/model-crud.service";
import { ModelTelemetryService } from "../services/model-telemetry.service";
import { SelectedMachineService } from "../services/selected-machine.service";

@Component({
  selector: "app-machine-report",
  templateUrl: "./machine-report.component.html",
  styleUrls: ["./machine-report.component.scss"],
})
export class MachineReportComponent extends MachineTestingComponent {
  public listTelGroup: TelemetryGroup[] = [];
  public activityInterval: number = 30;
  public idnode: string = "AQ==";
  public idNodeList = environment.IDNODELIST;
  public listResp: RespGetData[] = [];
  public telType: number;
  first = true;
  public telemetryOn: string[] = [];
  telObsSub: Subscription;
  public periRepoInterval: number = 300;

  constructor(
    public selectedMachine: SelectedMachineService,
    public modelService: ModelCrudService,
    protected modelTelemetryService: ModelTelemetryService,
    public connectionSignalR: ConnectionSignalRService,
    public alertService: AlertService,
    private cd: ChangeDetectorRef
  ) {
    super(selectedMachine, modelService, alertService, connectionSignalR);
  }

  ngOnInit() {
    super.ngOnInit();
    this.modelTelemetryService
      .getEntityList(this.selectedMachine.machineSub.value.id_model)
      .pipe(switchMap((x: TelemetryGroup[]) => from(x)))
      .subscribe(
        (data: TelemetryGroup) => {
          var telGr = data;
          if(telGr.type==1){
            this.listTelGroup.push(telGr);
          }
        },
        (error) => console.log("error"),
        () => console.log("completeLoad")
      );
  }

  clean() {
    this.listResp = [];
  }

  setActivity() {
    this.subscribe();
    if (
      this.connectionSignalR.connectionSub.value.state ==
      signalR.HubConnectionState.Connected
    ) {
      this.connectionSignalR.connectionSub.value
        .invoke(
          signalRInvocation.setActivityTab,
          this.selectedMachine.machineSub.value.serial_number,
          this.telType,
          this.activityInterval
        )
        .then(() => {
          console.log("setActivityTab");
        })
        .catch((reason: any) => {
          if (reason != null) {
            console.log(reason.message);
            this.alertService.error(reason.message.split("Exception: ")[1]);
          }
        });
    }
  }

  subscribe() {
    if (this.first) {
      this.interceptDisconnection();
      this.first = false;

      this.connectionSignalR.connectionSub.value.on(
        signalRSubscription.sendStopGetDataTelemetry,
        (resp: string) => {
          this.alertService.info(resp);
        }
      );

      this.connectionSignalR.connectionSub.value.on(
        signalRSubscription.sendGenericError,
        (resp: string) => {
          this.alertService.error(resp);
        }
      );

      this.connectionSignalR.connectionSub.value.on(
        signalRSubscription.sendGetDataTelemetry,
        (resp: string) => {
          //  this.selectedMachine.machineSub.value.isonline = status;
          var r = new RespGetData();
          r.date = new Date();
          r.fields = JSON.parse(resp);
          this.listResp.push(r);
          console.info("Telemetry data have just been received");
          //this.cd.markForCheck();
          //this.cd.detectChanges();
        }
      );

      this.connectionSignalR.connectionSub.value.on(
        signalRSubscription.sendTelemetryTabData,
        (resp: string) => {
          //  this.selectedMachine.machineSub.value.isonline = status;
          var r = new RespGetData();
          r.date = new Date();
          if (resp === "\0") {
            r.code = "0";
          } else {
            r.code = resp;
          }
          this.listResp.push(r);
          //this.cd.markForCheck();
          //this.cd.detectChanges();
        }
      );

      this.connectionSignalR.connectionSub.value.on(
        signalRSubscription.sendGetDataActivity,
        (resp: string) => {
          //  this.selectedMachine.machineSub.value.isonline = status;
          var r = new RespGetData();
          r.date = new Date();
          r.fields = JSON.parse(resp);
          this.listResp.push(r);
          //this.cd.markForCheck();
          //this.cd.detectChanges();
        }
      );

      this.connectionSignalR.connectionSub.value.on(
        signalRSubscription.sendActivityTabData,
        (resp: string) => {
          //  this.selectedMachine.machineSub.value.isonline = status;
          var r = new RespGetData();
          r.date = new Date();
          if (resp === "\0") {
            r.code = "0";
          } else {
            r.code = resp;
          }
          this.listResp.push(r);
          //this.cd.markForCheck();
          //this.cd.detectChanges();
        }
      );

      this.connectionSignalR.connectionSub.value.on(
        signalRSubscription.sendGetPeriodicReportPar,
        (resp: string) => {
          var sec = resp.split(",")[0];
          var ioRecordings = resp.split(",")[1];
          this.alertService.error("Periodic Report settings: every " + sec + " sec " + (ioRecordings ==="0" ? "without" : "with" )+ " I/O");
        }
      );

      this.connectionSignalR.connectionSub.value.on(
        signalRSubscription.sendSetPeriodicReportPar,
        (par: string) => {
          this.alertService.error("Periodic Report settings: " + par);
        }
      );
    }
  }

  getActivity() {
    this.subscribe();

    if (
      this.connectionSignalR.connectionSub.value.state ==
      signalR.HubConnectionState.Connected
    ) {
      this.connectionSignalR.connectionSub.value
        .invoke(
          signalRInvocation.getActivity,
          this.selectedMachine.machineSub.value.serial_number,
          this.idnode
        )
        .then(() => console.log("startTelemetry"))
        .catch((reason: any) => {
          if (reason != null) {
            console.log(reason.message);
            this.alertService.error(reason.message.split("Exception: ")[1]);
          }
        });
    }
  }

  readPeriodicReportPar() {
    this.subscribe();
    if (
      this.connectionSignalR.connectionSub.value.state ==
      signalR.HubConnectionState.Connected
    ) {
      this.connectionSignalR.connectionSub.value
        .invoke(
          signalRInvocation.getPeriodicReportPar,
          this.selectedMachine.machineSub.value.serial_number
        )
        .then(() => {})
        .catch((reason: any) => {
          if (reason != null) {
            console.log(reason.message);
            this.alertService.error(reason.message.split("Exception: ")[1]);
          }
        });
    }
  }

  setPeriodicReportPar() {
    this.subscribe();
    if (
      this.connectionSignalR.connectionSub.value.state ==
      signalR.HubConnectionState.Connected
    ) {
      this.connectionSignalR.connectionSub.value
        .invoke(
          signalRInvocation.setPeriodicReportPar,
          this.selectedMachine.machineSub.value.serial_number,
          this.periRepoInterval,
          this.selectedMachine.machineSub.value.ioRecordings
        )
        .then(() => {})
        .catch((reason: any) => {
          if (reason != null) {
            console.log(reason.message);
            this.alertService.error(reason.message.split("Exception: ")[1]);
          }
        });
    }
  }

  disablePeriodicReportPar(){
    this.periRepoInterval = 0;
    this.setPeriodicReportPar();
  }
}
