import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import * as signalR from '@aspnet/signalr';
import { MachineTestingComponent } from '../machine-testing/machine-testing.component';
import { AlertService } from '../services/alert.service';
import { ConnectionSignalRService } from '../services/connection-signal-r.service';
import { ModelCrudService } from '../services/model-crud.service';
import { SelectedMachineService } from '../services/selected-machine.service';

@Component({
  selector: 'app-machine-io',
  templateUrl: './machine-io.component.html',
  styleUrls: ['./machine-io.component.scss']
})
export class MachineIoComponent<Gen> extends MachineTestingComponent {
  @Output() back: EventEmitter<any> = new EventEmitter();

  protected first = true;
  public list: Gen[] = [];
  dataSource: MatTableDataSource<Gen>;
  public selection: SelectionModel<Gen>;
  protected displayedColumns: string[]
  protected signalRSendPubGet: string;
  protected signalRSendPubSet: string;
  protected signalRinvokeGet: string;

  constructor(
    public selectedMachine: SelectedMachineService,
    public modelService: ModelCrudService,
    public connectionSignalR: ConnectionSignalRService,
    public alertService: AlertService
  ) {
    super(selectedMachine, modelService, alertService, connectionSignalR);
  }

  ngOnInit() {
  }

  isNoneSelected() {
    const numSelected = this.selection.selected.length;
    return numSelected < 1
  }

  subscribe() {
    if (this.first) {
      this.interceptDisconnection();
      this.first = false;
      this.connectionSignalR.connectionSub.value.on(this.signalRSendPubGet, (resp: string) => {
        this.list = JSON.parse(resp);

        this.dataSource = new MatTableDataSource(this.list);
        this.selection = new SelectionModel<Gen>(true, this.list);
      });
      this.connectionSignalR.connectionSub.value.on(this.signalRSendPubSet, (resp: string) => {
        this.alertService.info(resp);
      });
    }
  }

  setVal() {
  }

  checked(row: Gen) {
    return this.selection.isSelected(row);
  }

  getVal() {
    this.subscribe();
    if (this.connectionSignalR.connectionSub.value.state == signalR.HubConnectionState.Connected) {
      this.connectionSignalR.connectionSub.value.invoke(this.signalRinvokeGet, this.selectedMachine.machineSub.value.serial_number).then(
        () => {
          console.log(this.signalRinvokeGet);

        }).catch((reason: any) => {
          if (reason != null) {
            console.log(reason.message);
            this.alertService.error(reason.message.split("Exception: ")[1]);
          }
        });
    }
  }
}
