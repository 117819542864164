import { Component, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { SelectedMachineService } from '../services/selected-machine.service';
import { ModelCrudService } from '../services/model-crud.service';
import { ConnectionSignalRService } from '../services/connection-signal-r.service';
import { MachineTestingComponent } from '../machine-testing/machine-testing.component';
import { FirmwareIot } from '../models/firmwareIot';
import { RespGetData } from '../models/respGetData';
import { signalRInvocation, signalRSubscription } from '../models/signalRTopic';
import * as signalR from '@aspnet/signalr';
import { AlertService } from '../services/alert.service';
import { FirmwareIotCrudService } from '../services/firmware-iot-crud.service';

@Component({
  selector: 'app-machine-firmware-iot4-g',
  templateUrl: './machine-firmware-iot4-g.component.html',
  styleUrls: ['./machine-firmware-iot4-g.component.scss']
})

export class MachineFirmwareIot4GComponent extends MachineTestingComponent {
  @Output() back: EventEmitter<any> = new EventEmitter();

  public listFw: FirmwareIot[] = [];
  public id_fw: number;
  public listResp: RespGetData[] = [];
  public updateOn = false;
  protected first = true;

  constructor(
    public selectedMachine: SelectedMachineService,
    public modelService: ModelCrudService,
    public connectionSignalR: ConnectionSignalRService,
    public fwIoTService: FirmwareIotCrudService,
    public alertService: AlertService,
    private cd: ChangeDetectorRef
  ) {
    super(selectedMachine, modelService, alertService, connectionSignalR);
  }

  ngOnInit() {
    super.ngOnInit();
    this.fwIoTService.read().subscribe(
      (data: FirmwareIot[]) => {
        var conn = this.selectedMachine.machineSub.value.conn_version;
        var fw = data.filter(x => x.conn_version == conn);
        fw.forEach(element => {
          this.listFw.push(element);
        });
      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )
  }

  clean() {
    this.listResp = [];
  }

  startDownload() {
    this.subscribe();
    if (this.connectionSignalR.connectionSub.value.state == signalR.HubConnectionState.Connected) {
      this.connectionSignalR.connectionSub.value.invoke(signalRInvocation.updateFirmwareIot4G, this.selectedMachine.machineSub.value.serial_number, this.id_fw).then(
        () => {
          this.updateOn = true;
          this.alertService.info("Firware update is running. Please wait!", null)
        }).catch((reason: any) => {
          if (reason != null) {
            console.log(reason.message);
            this.alertService.error(reason.message.split("Exception: ")[1]);
          }
        });
    }
  }

  readFwVersion() {
    this.subscribe();
    if (this.connectionSignalR.connectionSub.value.state == signalR.HubConnectionState.Connected) {
      this.connectionSignalR.connectionSub.value.invoke(signalRInvocation.getFwIOTVer, this.selectedMachine.machineSub.value.serial_number).then(
        () => {

        }).catch((reason: any) => {
          if (reason != null) {
            console.log(reason.message);
            this.alertService.error(reason.message.split("Exception: ")[1]);
          }
        });
    }
  }

  subscribe() {
    if (this.first) {
      this.interceptDisconnection();

      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendConnectionStatus, (status: string) => {
        //  this.selectedMachine.machineSub.value.isonline = status;
        if (status != "3") {
          this.updateOn = false;
        }
      });

      this.first = false;

      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendGenericError, (resp: string) => {
        //  this.selectedMachine.machineSub.value.isonline = status;
        this.alertService.error(resp);
      });

      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendPubGetFwIOTVer, (resp: string) => {
        //  this.selectedMachine.machineSub.value.isonline = status;
        this.alertService.success("Firmware IOT version: " + resp, null);
      });

      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendAggFirmwareIot4G, (status: string) => {
        //  this.selectedMachine.machineSub.value.isonline = status;
        this.alertService.info(status, null);
      });
    }
  }
}
