import { Component,  } from '@angular/core';
import { GenericCrudComponent } from '../generic-crud/generic-crud.component';
import { AlertService } from '../services/alert.service';
import { SelectedCommandService } from '../services/selected-command.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CustomCommand } from '../models/customCommand';
import { CustomCommandSettingsDialogComponent } from '../custom-command-settings-dialog/custom-command-settings-dialog.component';
import { CustomdCommandCrudService } from '../services/custom-command-crud.service';


@Component({
  selector: 'app-custom-command-settings',
  templateUrl: './custom-command-settings.component.html',
})
export class CustomCommandSettingsComponent extends GenericCrudComponent<CustomCommand, number> {
  displayedColumns: string[] = ['editing', 'id_custom_command', 'name', 'id_node','can_command', 'can_index', 'can_subindex','can_value'];

  constructor(
    protected crudService: CustomdCommandCrudService,
    protected alertService: AlertService,
    protected selectedCommand: SelectedCommandService,
    protected router: Router,
    public dialog: MatDialog,
  ) {
    super(crudService, alertService, dialog)
    this.filterString = "cmdFilter";
  }

  edit(row: CustomCommand): void {
    this.showEditDialog(CustomCommandSettingsDialogComponent, row);
  }
  showAddDialog(): void {
    this.showEditDialog(CustomCommandSettingsDialogComponent, null);
  }
}

