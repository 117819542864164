import { Component, OnInit } from '@angular/core';
import { Firmware } from '../models/firmware';
import { GenericCrudComponent } from '../generic-crud/generic-crud.component';
import { FirmwareCrudService } from '../services/firmware-crud.service';
import { AlertService } from '../services/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { FirmwareSettingsDialogComponent } from '../firmware-settings-dialog/firmware-settings-dialog.component';
import { saveAs } from 'file-saver';
import { Model } from '../models/model';
import { ModelCrudService } from '../services/model-crud.service';
import { SelectedFirmwareService } from '../services/selected-firmware.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-firmware-settings',
  templateUrl: './firmware-settings.component.html'
})
export class FirmwareSettingsComponent extends GenericCrudComponent<Firmware, number> {
  http: any;
  listModels: Model[];
  displayedColumns: string[] = ['select', 'id_fw', 'idnode', 'name_fw', 'version_fw', 'description_fw', 'release_date_fw'];

  constructor(
    protected crudService: FirmwareCrudService,
    protected alertService: AlertService,
    protected modelService: ModelCrudService,
    public dialog: MatDialog,
    protected sel: SelectedFirmwareService,
    protected router: Router
  ) {
    super(crudService, alertService, dialog)
  }

  ngOnInit() {
    this.filterString = 'fwFilter';
    this.modelService.listObservable.subscribe(
      (data: any[]) => {
        this.listModels = data;
      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )
    super.ngOnInit();
  }

  more(row: Firmware) {
    super.more(row);
    this.sel.setFirmware(row);
    this.router.navigate([`firmware-details`]);
  }

  showAddDialog() {
    this.showEditDialog(FirmwareSettingsDialogComponent, null, 800);
  }

  edit(row: Firmware) {
    this.showEditDialog(FirmwareSettingsDialogComponent, row, 800);
  }

  download(row: Firmware) {
    this.crudService.download(row.id_fw).subscribe(data => this.downloadFile(data, row.name_fw)),//console.log(data),
      error => console.log('Error downloading the file.'),
      () => console.info('OK');
  }

  downloadFile(data: Response, name_fw: string) {
    saveAs(data, name_fw);
  }
}


