import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SelectedUserService {
  userSub = new BehaviorSubject<User>(undefined);
  userCurrent = this.userSub.asObservable()

  constructor() { }

  setUser(role: User) {
    this.userSub.next(role);
  }

  unsetUser() {
    this.userSub.next(undefined);
  }
}