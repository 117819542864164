import { Component, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { SelectedMachineService } from '../services/selected-machine.service';
import { ModelCrudService } from '../services/model-crud.service';
import { ConnectionSignalRService } from '../services/connection-signal-r.service';
import { MachineTestingComponent } from '../machine-testing/machine-testing.component';
import { Firmware } from '../models/firmware';
import { switchMap } from 'rxjs/operators';
import { from } from 'rxjs';
import { RespGetData } from '../models/respGetData';
import { signalRInvocation, signalRSubscription } from '../models/signalRTopic';
import * as signalR from '@aspnet/signalr';
import { AlertService } from '../services/alert.service';
import { FirmwareModelService } from '../services/firmware-model.service';

@Component({
  selector: 'app-machine-firmware',
  templateUrl: './machine-firmware.component.html',
  styleUrls: ['./machine-firmware.component.scss']
})

export class MachineFirmwareComponent extends MachineTestingComponent {
  @Output() back: EventEmitter<any> = new EventEmitter();

  public listFw: Firmware[] = [];
  public packLen: number = 700;
  public packNum: number = 0;
  public packTotal: number = 0;
  public valueBar = 0;
  public id_fw: number;
  public listResp: RespGetData[] = [];
  public updateOn = false;
  protected first = true;

  constructor(
    public selectedMachine: SelectedMachineService,
    public modelService: ModelCrudService,
    public connectionSignalR: ConnectionSignalRService,
    public fwService: FirmwareModelService,
    public alertService: AlertService,
    private cd: ChangeDetectorRef
  ) {
    super(selectedMachine, modelService, alertService, connectionSignalR);
  }

  ngOnInit() {

    super.ngOnInit();
    this.fwService.getFirmwareList(this.machine.id_model).
      pipe(switchMap((x: Firmware[]) => from(x))).
      subscribe(
        (data: Firmware) => {
          var fw = data;
          this.listFw.push(fw);
        },
        error => console.log('error'),
        () => console.log('completeLoad')
      )
  }

  clean() {
    this.listResp = [];
  }

  start(packNum: number, attemptNum: number, generalAttempt: number) {
    this.subscribe();
    if (this.connectionSignalR.connectionSub.value.state == signalR.HubConnectionState.Connected) {
      this.connectionSignalR.connectionSub.value.invoke(signalRInvocation.updateFirmwareBoard, this.selectedMachine.machineSub.value.serial_number, this.packLen, this.id_fw, packNum, attemptNum, generalAttempt).then(
        () => {

        }).catch((reason: any) => {
          if (reason != null) {
            console.log(reason.message);
            this.alertService.error(reason.message.split("Exception: ")[1]);
          }
        });
    }
  }

  subscribe() {
    if (this.first) {
      this.interceptDisconnection();
      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendConnectionStatus, (status: string) => {
        if (status === "0") {
          this.back.emit();
        }
      });

      this.first = false;
      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendAggFirmwareBoard, (message: string, packNum: number, packTotal: number, attempt: number, attemptNum: number) => {
        //  this.selectedMachine.machineSub.value.isonline = status;
        this.updateOn = true;
        var r = new RespGetData();
        r.date = new Date();
        r.code = ' Message received ' + message + ',packet:' + packNum + '/' + packTotal + ',attempt:' + attempt + '-' + attemptNum;
        this.packNum = packNum;
        this.packTotal = packTotal;
        this.valueBar = packNum / packTotal * 100;
        this.listResp.push(r);
        //this.cd.markForCheck();
        //this.cd.detectChanges();
      });

      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendAggFirmwareBoardEnd, (status: number) => {
        //  this.selectedMachine.machineSub.value.isonline = status;
        var r = new RespGetData();
        r.date = new Date();
        r.code = "" + status;
        this.updateOn = false;
        this.listResp.push(r);

        var msg = "";
        if (status > 0) {
          switch (status) {
            case 1:
              msg = "Firmware download successfully ended -";
              break;
            case 129:
              msg = "ERRUPD_01: Node ID not managed -";
              break;
            case 130:
              msg = "ERRUPD_02: Node ID not present -";
              break;
            case 131:
              msg = "ERRUPD_03: Errore invio comando salta in Boot -";
              break;
            case 132:
              msg = "ERRUPD_04: Errore programma non salta in Boot -";
              break;
            case 133:
              msg = "ERRUPD_05: Errore attivazione programmazione -";
              break;
            case 134:
              msg = "ERRUPD_06: Errore cancellazione Flash Prog non terminata -";
              break;
            case 135:
              msg = "ERRUPD_07: Errore cancellazione Flash Prog fallita -";
              break;
            case 136:
              msg = "ERRUPD_08: Errore lettura File S19 -";
              break;
            case 137:
              msg = "ERRUPD_09: Errore scrittura non terminata -";
              break;
            case 138:
              msg = "ERRUPD_10: Errore scrittura fallita -";
              break;
            case 139:
              msg = "ERRUPD_11: errore calcolo CRC non terminato -";
              break;
            case 140:
              msg = "ERRUPD_12: errore calcolo CRC fallito -";
              break;
            case 141:
              msg = "ERRUPD_13: errore invio comando generico -";
              break;
            case 142:
              msg = "UPDATE_OK: Checksum not present";
              break;
            case 143:
              msg = "UPDATE_OK: Checksum OK";
              break;

          }
          this.alertService.success(msg + r.date.toISOString());
        }

        else
          this.alertService.error('Firmware update ended with error :' + r.date.toISOString());
      });

      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendAggFirmwareBoardNext, (message: string, packNum: number, attemptNum: number, generalAttempt: number) => {
        this.start(packNum, attemptNum, generalAttempt);
      });
    }
  }
}
