import { FirmwareIotInstallation } from './../models/firmwareIotInstallation';
import { Pipe, PipeTransform } from '@angular/core';
import { CounterEdge } from '../models/digitalEventCounter';
import { Machine } from '../models/machine';

@Pipe({
  name: 'fwInstallationStatus'
})
export class FwInstallationStatus implements PipeTransform {

  transform(value: FirmwareIotInstallation): any {
    if(value.status == -1){
      return {tooltip:'FAILED', name:'close', color:'#d32f2f'};
    }
    else if(value.status == 0 && value.date_start_download == null){
      return {tooltip:'PENDING', name:'pending', color:'#eceff1'};
    }
    else if(value.status == 0 && value.date_start_download != null){
      return {tooltip:'PENDING', name:'pending', color:'#ff9800'};
    }
    else if(value.status == 1){
      return {tooltip:'RUNNING', name:'play_arrow', color:'#29b6f6'};
    }
    else if(value.status == 2){
      return {tooltip:'SUCCESS', name:'done', color:'#4caf50'};
    }
    else {
      return {tooltip:'', name:'', color:''};
    }
  }
}
