import { Component, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Model } from '../models/model';
import { User } from '../models/user';
import { FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { AlertService } from '../services/alert.service';
import { UserCrudService } from '../services/user-crud.service';
import { ModelCrudService } from '../services/model-crud.service';
import { GenericSettingsDialogComponent } from '../generic-settings-dialog/generic-settings-dialog.component';

@Component({
  selector: 'app-model-settings-dialog',
  templateUrl: './model-settings-dialog.component.html',
  styleUrls: ['./model-settings-dialog.component.scss']
})
export class ModelSettingsDialogComponent extends GenericSettingsDialogComponent<Model, number> {
  listUsers: User[];

  constructor(
    protected modelService: ModelCrudService,
    protected userService: UserCrudService,
    protected alertService: AlertService,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected fb: FormBuilder,
    private ref: ChangeDetectorRef
  ) {
    super(modelService, alertService, dialogRef, data, fb)
  }

  ngOnInit() {
    super.ngOnInit();
    this.title = this.isNew ? "Create new model" : "Modify model";
    this.form = this.fb.group({
      model_nameCtrl: [this.entity ? this.entity.name : '', Validators.compose([Validators.required, (c) => this.newModelValidator(c, this.entityList, this.entity)])],
      model_makerCtrl: this.entity ? this.entity.id_maker : '',
      model_jclientCtrl: ['', Validators.required],
      model_secondperiodicreportCtrl: this.entity ? this.entity.second_periodic_report : '',
    });

    this.userService.listObservable.subscribe(
      (data: any[]) => {
        this.listUsers = data.filter((x) => (x.id_usertype == 3));;
      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )
  }

  update(entity: Model) {
    entity.id_maker = this.form.get('model_makerCtrl').value;
    entity.name = this.form.get('model_nameCtrl').value;
    entity.id = entity.id_model;
    entity.jclient = this.form.get('model_jclientCtrl').value;
    entity.second_periodic_report = this.form.get('model_secondperiodicreportCtrl').value;

    this.modelService.update(this.entity)
      .subscribe(
        (data) => {
          this.alertService.info("Model has been successfully updated");
          this.dialogRef.close(true);
        },
        error => {
          console.log(error)
          this.alertService.error(error.error);
        }
      );
  }

  add() {
    var model: Model = {
      id: undefined,
      id_model: undefined,
      name: this.form.get('model_nameCtrl').value,
      id_maker: this.form.get('model_makerCtrl').value,
      jclient: this.form.get('model_jclientCtrl').value,
      second_periodic_report: this.form.get('model_secondperiodicreportCtrl').value,
      date_del: null,
      date_auto: null,
      date_upd: null
    };
    this.modelService.create(model)
      .subscribe(
        (data) => {
          this.alertService.info("Model has been successfully added");
          this.dialogRef.close(true);
        },
        error => {
          console.log(error)
          this.alertService.error(error.error);
        }
      );
  }

  onFileChange(event) {
    let reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsText(file);

      reader.onload = () => {
        this.form.patchValue({
          model_jclientCtrl: reader.result
        });
        this.ref.markForCheck();
      };
    }
  }

  delete() {
    var model_temp = this.entity;
    model_temp.date_del = new Date();
    this.update(model_temp);
  }

  restore() {
    var model_temp = this.entity;
    model_temp.date_del = null;
    this.update(model_temp);
  }

  newModelValidator(c: AbstractControl, listModel: Model[], model: Model): { [s: string]: boolean } {
    var currentIdx = -2;
    if (listModel.length > 0) {
      var index = listModel.map(function (d) { return d.name; }).indexOf(c.value)
      if (model) {
        currentIdx = listModel.map(function (d) { return d.name }).indexOf(model.name);
      }
      if (index >= 0 && index != currentIdx) {
        return { nameJustInUse: index >= 0 };
      }
    }
  }
}
