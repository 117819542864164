import { IModelObj } from "./iModelObj";
import { IModel } from './model';
import { getTreeControlMissingError } from '@angular/cdk/tree';
import { MACHINESTATEVOICE } from './mock-machineState';

export interface IMachine extends IModelObj {
  id_machine: number;
  id_model: number;
  name: string;
  description: string;
  isenabled: number;
  isonline: number;
  serial_number: string;
  id_owner: number;
  date_IoT_upd: Date;
  date_auto: Date;
  date_last_status: Date;
  battery_level: number;
  battery_warning_soil: number;
  battery_alarm_soil: number;
  battery_mail_alert: string;
  fw_type: number;
  md5_uid: any;
}

export class Machine implements IMachine {
  id: string | number;
  id_machine: number;
  id_model: number;
  name: string;
  description: string;
  isenabled: number;
  isonline: number;
  serial_number: string;
  id_owner: number;
  date_IoT_upd: Date;
  date_auto: Date;
  date_last_status: Date;
  ioRecordings: number;
  conn_version: number;
  battery_level: number;
  battery_warning_soil: number;
  battery_alarm_soil: number;
  battery_mail_alert: string;
  fw_type: number;
  md5_uid: any;

  constructor(props: IMachine) {
    Object.keys(props).forEach(prop => {
      const value = props[prop];
      this[prop] = value;
    });
    this.id = props.id_machine;
  }

  static getColor(online: number) {

    return MACHINESTATEVOICE.find((element) => {
      return element.id == online;
    }).color;
  }

  static statusIcon(online: number): string {
    return MACHINESTATEVOICE.find((element) => {
      return element.id == online;
    }).icon;
  }
}

