import { Component, ViewChild, ChangeDetectorRef } from '@angular/core';
import { SelectedMachineService } from '../services/selected-machine.service';
import { ModelCrudService } from '../services/model-crud.service';
import { ModelParamService } from '../services/model-param.service';
import { ConnectionSignalRService } from '../services/connection-signal-r.service';
import { MachineTestingComponent } from '../machine-testing/machine-testing.component';
import * as signalR from '@aspnet/signalr';
import { RespGetData } from '../models/respGetData';
import { Param } from '../models/param';
import { switchMap } from 'rxjs/operators';
import { from } from 'rxjs';
import { Field } from '../models/field';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MatExpansionPanel } from '@angular/material/expansion';
import { AlertService } from '../services/alert.service';
import { signalRSubscription, signalRInvocation } from '../models/signalRTopic';

@Component({
  selector: 'app-machine-parameters',
  templateUrl: './machine-parameters.component.html',
  styleUrls: ['./machine-parameters.component.scss']
})
export class MachineParametersComponent extends MachineTestingComponent {
  @ViewChild(MatExpansionPanel, { static: true }) paramPanel: MatExpansionPanel;

  first = true;
  fil: "";
  listFieldParam: Field[] = [];
  filteredList: Field[] = [];
  listFieldParamToSend: Field[] = [];
  listResp: RespGetData[] = [];

  constructor(
    public selectedMachine: SelectedMachineService,
    public modelService: ModelCrudService,
    public modelParamService: ModelParamService,
    public connectionSignalR: ConnectionSignalRService,
    public alertService: AlertService,
    private cd: ChangeDetectorRef
  ) {
    super(selectedMachine, modelService, alertService, connectionSignalR);
  }

  applyFilter() {
    var value = this.fil;
    this.filteredList = this.listFieldParam.filter(
      (field) => {
        return field.parameter.name.indexOf(value.toUpperCase()) > -1 || field.parameter.address.indexOf(value.toUpperCase()) > -1;
      });
  }

  getColore(field: Field) {
    return 'lime';
  }

  ngOnInit() {
    super.ngOnInit();
    this.modelParamService.getEntityList(this.selectedMachine.machineSub.value.id_model).
      pipe(switchMap((x: Param[]) => from(x))).
      subscribe(
        (data: Param) => {
          var par = data;
          var field = new Field();
          field.parameter = par;

          this.listFieldParam.push(field);
          this.filteredList.push(field);

        },
        error => console.log('error'),
        () => {

        }
      )
  }

  dblclickMove(item: Field, ...targets: string[]) {
    this[targets[0]] = [
      ...this[targets[1]].splice(this[targets[1]].findIndex((x: Field) => (x.parameter.id_param == item.parameter.id_param)), 1),
      ...this[targets[0]]
    ];
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  updateData() {
    this.subscribe();
    if (this.connectionSignalR.connectionSub.value.state == signalR.HubConnectionState.Connected) {
      var dataToSend = [];
      this.listFieldParamToSend.forEach(element => {
        dataToSend.push({
          "name": element.parameter.name,
          "value": element.value
        })
      });

      this.connectionSignalR.connectionSub.value.invoke(signalRInvocation.updateData, this.selectedMachine.machineSub.value.serial_number, JSON.stringify(dataToSend)).then(
        () => {
          console.log("updateData");

        }).catch((reason: any) => {
          if (reason != null) {
            console.log(reason.message);
            this.alertService.error(reason.message.split("Exception: ")[1]);
          }
        });
    }
  }

  clean() {
    this.listResp = [];
  }

  subscribe() {
    if (this.first) {
      this.interceptDisconnection();
      this.first = false;
      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendGetData, (resp: string) => {
        //  this.selectedMachine.machineSub.value.isonline = status;
        var r = new RespGetData();
        r.date = new Date();
        r.fields = JSON.parse(resp);
        this.listResp.push(r);
        this.paramPanel.close();
        // this.cd.markForCheck();
        // this.cd.detectChanges();
      });

      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendGenericError, (resp: string) => {
        //  this.selectedMachine.machineSub.value.isonline = status;
        this.alertService.error(resp);
      });

      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendUpdatedData, (resp: string) => {
        //  this.selectedMachine.machineSub.value.isonline = status;
        var r = new RespGetData();
        r.date = new Date();
        if (resp === "\0") {
          r.code = "0"
        } else {
          r.code = resp;
        }
        this.listResp.push(r);
        this.paramPanel.close();
        // this.cd.markForCheck();
        // this.cd.detectChanges();
      });
    }
  }

  getData() {
    this.subscribe();

    if (this.connectionSignalR.connectionSub.value.state == signalR.HubConnectionState.Connected) {
      var dataToSend = "";
      this.listFieldParamToSend.forEach(element => {
        dataToSend += element.parameter.name + ",";
      });

      this.connectionSignalR.connectionSub.value.invoke(signalRInvocation.getData, this.selectedMachine.machineSub.value.serial_number, dataToSend).then(
        () => {
          console.log("getData");
        }).catch((reason: any) => {
          if (reason != null) {
            console.log(reason.message);
            this.alertService.error(reason.message.split("Exception: ")[1]);
          }
        });
    }
  }
}
