import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';
import { GenericCrudService } from './generic-crud.service';
import { ActivitySingleRec } from '../models/activitySingleRec';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActivitySingleRecCrudService extends GenericCrudService<ActivitySingleRec, number>{

  constructor(http: HttpClient,
    appConfigService: AppConfigService) {
    super(http, appConfigService, "activitySingleRec");
    this.load();
  }

  getActSingleRec(idobj: number): Observable<ActivitySingleRec> {
    return this.http.get<ActivitySingleRec>(`${this.endpoint}/${this.apiName}/id=${idobj}`);
  }
}
