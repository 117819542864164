import { Pipe, PipeTransform } from '@angular/core';
import { InputType } from '../models/digitalInput';

@Pipe({
  name: 'digitalInputTypeTooltip'
})
export class DigitalInputTypeTooltipPipe implements PipeTransform {

  transform(value: InputType): any {
    switch (value) {
      case InputType.Disabled:
        return "Disabled";
      case InputType.Switch:
        return "Switch";
      case InputType.EventCounter:
        return "Event counter";
      case InputType.HourCounter:
        return "Hour counter";
    }
  }
}