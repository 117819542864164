import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TelemetryGroup } from '../models/telemetryGroup';

@Injectable({
  providedIn: 'root'
})
export class SelectedTelemetryGroupService {
  telemetryGroupSub = new BehaviorSubject<TelemetryGroup>(undefined);
  telemetryGroupCurrent = this.telemetryGroupSub.asObservable()

  constructor() { }

  setTelGroup(telgroup: TelemetryGroup) {
    this.telemetryGroupSub.next(telgroup);
  }

  unsetTelGroup() {
    this.telemetryGroupSub.next(undefined);
  }
}