import { Component, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { SelectedMachineService } from '../services/selected-machine.service';
import { ModelCrudService } from '../services/model-crud.service';
import { ConnectionSignalRService } from '../services/connection-signal-r.service';
import { MachineTestingComponent } from '../machine-testing/machine-testing.component';
import { FirmwareIot } from '../models/firmwareIot';
import { RespGetData } from '../models/respGetData';
import { signalRInvocation, signalRSubscription } from '../models/signalRTopic';
import * as signalR from '@aspnet/signalr';
import { AlertService } from '../services/alert.service';
import { FirmwareIotCrudService } from '../services/firmware-iot-crud.service';

@Component({
  selector: 'app-machine-firmware-iot',
  templateUrl: './machine-firmware-iot.component.html',
  styleUrls: ['./machine-firmware-iot.component.scss']
})

export class MachineFirmwareIotComponent extends MachineTestingComponent {
  @Output() back: EventEmitter<any> = new EventEmitter();

  public listFw: FirmwareIot[] = [];
  public packLen: number = 700;
  public packNum: number = 0;
  public packTotal: number = 0;
  public valueBar = 0;
  public id_fw: number;
  public listResp: RespGetData[] = [];
  public updateOn = false;
  protected first = true;

  constructor(
    public selectedMachine: SelectedMachineService,
    public modelService: ModelCrudService,
    public connectionSignalR: ConnectionSignalRService,
    public fwIoTService: FirmwareIotCrudService,
    public alertService: AlertService,
    private cd: ChangeDetectorRef
  ) {
    super(selectedMachine, modelService, alertService, connectionSignalR);
  }

  ngOnInit() {

    super.ngOnInit();
    this.fwIoTService.read().subscribe(
      (data: FirmwareIot[]) => {
        var conn = this.selectedMachine.machineSub.value.conn_version;
        var fw = data.filter(x => x.conn_version == conn);
        fw.forEach(element => {
          this.listFw.push(element);
        });
      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )
  }

  clean() {
    this.listResp = [];
  }

  start(packNum: number, attemptNum: number, generalAttempt: number) {
    this.subscribe();
    if (this.connectionSignalR.connectionSub.value.state == signalR.HubConnectionState.Connected) {
      this.connectionSignalR.connectionSub.value.invoke(signalRInvocation.updateFirmware, this.selectedMachine.machineSub.value.serial_number, this.packLen, this.id_fw, packNum, attemptNum, generalAttempt).then(
        () => {

        }).catch((reason: any) => {
          if (reason != null) {
            console.log(reason.message);
            this.alertService.error(reason.message.split("Exception: ")[1]);
          }
        });
    }
  }

  readFwVersion() {
    this.subscribe();
    if (this.connectionSignalR.connectionSub.value.state == signalR.HubConnectionState.Connected) {
      this.connectionSignalR.connectionSub.value.invoke(signalRInvocation.getFwIOTVer, this.selectedMachine.machineSub.value.serial_number).then(
        () => {

        }).catch((reason: any) => {
          if (reason != null) {
            console.log(reason.message);
            this.alertService.error(reason.message.split("Exception: ")[1]);
          }
        });
    }
  }

  subscribe() {
    if (this.first) {
      this.interceptDisconnection();

      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendConnectionStatus, (status: string) => {
        //  this.selectedMachine.machineSub.value.isonline = status;
        if (status === "0") {
          this.back.emit();
        }
      });

      this.first = false;
      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendAggFirmware, (message: string, packNum: number, packTotal: number, attempt: number, attemptNum: number) => {
        //  this.selectedMachine.machineSub.value.isonline = status;
        this.updateOn = true;
        var r = new RespGetData();
        r.date = new Date();
        r.code = ' Message received ' + message + ',packet:' + packNum + '/' + packTotal + ',attempt:' + attempt + '-' + attemptNum;
        this.packNum = packNum;
        this.packTotal = packTotal;
        this.valueBar = packNum / packTotal * 100;
        this.listResp.push(r);
        //this.cd.markForCheck();
        //this.cd.detectChanges();
      });

      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendAggFirmwareEnd, (status: number) => {
        //  this.selectedMachine.machineSub.value.isonline = status;
        var r = new RespGetData();
        r.date = new Date();
        r.code = "" + status;
        this.updateOn = false;
        this.listResp.push(r);
        //this.cd.markForCheck();
        //this.cd.detectChanges();
        if (status > 0) {
          this.alertService.success('Firmware update successfully ended :' + r.date.toISOString());
          this.back.emit();
        }
        else
          this.alertService.error('Firmware update ended with error :' + r.date.toISOString());
      });

      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendAggFirmwareNext, (message: string, packNum: number, attemptNum: number, generalAttempt: number) => {
        //  this.selectedMachine.machineSub.value.isonline = status;
        this.start(packNum, attemptNum, generalAttempt);
      });

      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendGenericError, (resp: string) => {
        //  this.selectedMachine.machineSub.value.isonline = status;
        this.alertService.error(resp);
      });

      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendPubGetFwIOTVer, (resp: string) => {
        //  this.selectedMachine.machineSub.value.isonline = status;
        this.alertService.success("Firmware IOT version: " + resp);
      });
    }
  }
}
