import { MachineConnection, MachineState } from "./machineState";

export const MACHINESTATEVOICE: MachineState[] = [
  { id: 0, icon: "cloud_off", color: "#bdbdbd", label: "OFFLINE" },
  { id: null, icon: "cloud_off", color: "#bdbdbd", label: "" },
  { id: 1, icon: "cloud", color: "#2e7d32", label: "ONLINE" },
  { id: 2, icon: "block", color: "#d32f2f", label: "SDO BLOCKED" },
  { id: 3, icon: "perm_data_settings", color: "#455a64", label: "IOT FIRMWARE UPDATE RUNNING" },
  { id: 4, icon: "settings", color: "#455a64", label: "BOARD FIRMWARE UPDATE RUNNING" },
  { id: 5, icon: "cloud", color: "#fdd835", label: "ONLINE LOG - ERROR READING" }
];

export const MACHINECONNECTIONTYPE: MachineConnection[] = [
  { id: 3, icon: "3g_mobiledata", label: "2G/3G" },
  { id: 4, icon: "4g_mobiledata", label: "4G" }
];