import { Component, ViewChild } from '@angular/core';
import { MachineTestingComponent } from '../machine-testing/machine-testing.component';
import { SelectedMachineService } from '../services/selected-machine.service';
import { ModelCrudService } from '../services/model-crud.service';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import * as signalR from "@aspnet/signalr";
import { ConnectionSignalRService } from '../services/connection-signal-r.service';
import { RespGetData } from '../models/respGetData';
import { signalRInvocation, signalRSubscription } from '../models/signalRTopic';
import { ModelCommandService } from '../services/model-command.service';
import { Command } from '../models/command';
import { CommandVal } from '../models/commandVal';
import { MatExpansionPanel } from '@angular/material/expansion';
import { AlertService } from '../services/alert.service';

@Component({
  selector: 'app-machine-commands',
  templateUrl: './machine-commands.component.html',
  styleUrls: ['./machine-commands.component.scss']
})
export class MachineCommandsComponent extends MachineTestingComponent {
  @ViewChild(MatExpansionPanel, { static: true }) paramPanel: MatExpansionPanel;

  public listCommand: Command[] = [];
  public listSelectedCommand: CommandVal[] = [];
  public listResp: RespGetData[] = [];
  public name_cmd: string;
  first = true;
  cmdOn = false;

  constructor(
    public selectedMachine: SelectedMachineService,
    public modelService: ModelCrudService,
    protected modelCommandService: ModelCommandService,
    public connectionSignalR: ConnectionSignalRService,
    public alertService: AlertService
  ) {
    super(selectedMachine, modelService, alertService, connectionSignalR);
  }

  ngOnInit() {

    super.ngOnInit();
    this.modelCommandService.getEntityList(this.selectedMachine.machineSub.value.id_model).
      pipe(switchMap((x: Command[]) => from(x))).
      subscribe(
        (data: Command) => {

          var cmd = data;
          this.listCommand.push(cmd);
        },
        error => console.log('error'),
        () => console.log('completeLoad')
      )
  }

  clean() {
    this.listResp = [];
  }

  subscribe() {
    if (this.first) {
      this.interceptDisconnection();
      this.first = false;

      this.connectionSignalR.connectionSub.value.on(signalRSubscription.sendUpdatedData, (resp: string) => {
        //  this.selectedMachine.machineSub.value.isonline = status;
        this.cmdOn = false;
        var r = new RespGetData();
        r.date = new Date();
        if (resp === "\0") {
          r.code = "0"
        } else {
          r.code = resp;
        }
        this.listResp.push(r);
      });
    }
  }

  addCmd() {
    var idx = this.listCommand.findIndex((element) => {
      return element.name == this.name_cmd;
    });
    if (idx > -1) {
      var cmdVal = new CommandVal();
      cmdVal.command = this.listCommand[idx];
      this.listSelectedCommand.push(cmdVal);
    }

  }

  remove(cmdName) {
    var idx = this.listSelectedCommand.findIndex((element) => {
      return element.command.name == cmdName;
    });
    if (idx > -1)
      this.listSelectedCommand.splice(idx, 1);
  }

  launchCommand() {
    this.subscribe();
    var dataToSend = [];
    this.listSelectedCommand.forEach(element => {
      dataToSend.push({
        "name": element.command.name,
        "value": element.val
      })
    });
    if (this.connectionSignalR.connectionSub.value.state == signalR.HubConnectionState.Connected) {
      this.connectionSignalR.connectionSub.value.invoke(signalRInvocation.writeCmd, this.selectedMachine.machineSub.value.serial_number, dataToSend)
        .then(() => {
          console.log('writeCmd');
          this.cmdOn = true;;
        }
        ).catch((reason: any) => {
          if (reason != null) {
            console.log(reason.message);
            this.alertService.error(reason.message.split("Exception: ")[1]);
          }
        });
    }
  }
}
