import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SelectedMachineService } from '../services/selected-machine.service';

@Injectable({
  providedIn: 'root'
})
export class MachineGuard implements CanActivate {
  constructor(
    private router: Router,
    private selectedMachine: SelectedMachineService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.selectedMachine.machineSub.value) {
      // authorised so return true
      return true;
    }
    this.router.navigate(['/machines'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
